import React from "react";
import Button from "@mui/material/Typography";
import Typography from "@mui/material/Typography";
import { changeDateToLocal } from ".";

const EditedTxsDetailModal = ({ data, handleCloseDialog }) => {
  const handleDownloadClick = () => {
    handleCloseDialog(false);
    window.print();
  };

  console.log(data);
  return (
    <div className="pt-10">
      <div className=" bg-white mb-10 flex flex-col gap-2">
        <div className="grid grid-cols-3 gap-x-3">
          <Typography
            className="text-primary font-bold capitalize"
            style={{ fontSize: "16px", color: "#1B2559" }}
          ></Typography>
          <Typography
            className="text-primary font-bold capitalize"
            style={{ fontSize: "16px", color: "#1B2559" }}
          >
            Current
          </Typography>
          <Typography
            className="text-primary font-bold capitalize"
            style={{ fontSize: "16px", color: "#1B2559" }}
          >
            Previous
          </Typography>
        </div>
        <UserKeyValue
          label={"Transaction ID"}
          value={data.id}
          prevValue={data.history.transactionId}
        />
        <UserKeyValue
          label={"Employee ID"}
          value={data.employeeId}
          prevValue={data.employeeId}
        />
        <UserKeyValue
          label={"Entry Timestamp"}
          value={changeDateToLocal(data.createdAt)}
          prevValue={changeDateToLocal(data.history.updatedAt)}
        />
        <UserKeyValue
          label={"Transaction Date"}
          value={changeDateToLocal(data.transactionDate, false)}
          prevValue={changeDateToLocal(data.history.createdAt, false)}
        />
        <UserKeyValue
          label={"Type"}
          value={data.type}
          prevValue={data.history.type}
        />
        <UserKeyValue
          label={"Sub Category"}
          value={data.subCategory}
          prevValue={data.history.source.name}
        />
        <UserKeyValue
          label={"Source"}
          value={data.source}
          prevValue={data.history.description}
        />
        <UserKeyValue
          label={"Mode of Payment"}
          value={data.mode}
          prevValue={data.history.mode}
        />
        <UserKeyValue
          label={"Amount"}
          value={data.amount}
          prevValue={data.history.amount}
        />
      </div>
      <div className="flex items-center mx-auto justify-center gap-5">
        <Button
          variant="standard"
          className="whitespace-nowrap  capitalize font-semibold text-sm px-5 py-2 text-white bg-[#3D3E78] cursor-pointer"
          onClick={() => handleDownloadClick()}
        >
          Download
        </Button>
      </div>
    </div>
  );
};

export default EditedTxsDetailModal;

export function UserKeyValue({ label, value, prevValue }) {
  return (
    <div className="grid grid-cols-3 gap-x-3">
      <Typography
        className="text-primary font-bold capitalize"
        style={{ fontSize: "16px", color: "#1B2559" }}
      >
        {label}
      </Typography>
      <p>{value}</p>
      <p>{prevValue}</p>
    </div>
  );
}
