export const appendQueryParams = (url, data) => {
  let resp = url + "?";
  if (data)
    Object.keys(data).forEach((key) => {
      if (Boolean(data[key])) {
        resp += `${key}=${
          typeof data[key] === "string"
            ? data[key]?.replaceAll("&", "%26")
            : data[key]
        }&`;
      }
    });
  return resp;
};
