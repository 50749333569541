import { CalendarIcon } from '@heroicons/react/20/solid';
import { InputAdornment, useMediaQuery } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import CustomTextField from 'components/CustomTextField';

const DatePicker = (props) => {
  const isLgScreen = useMediaQuery('(min-width:600px)');
  const {
    fullWidth,
    onChange,
    value,
    error,
    helperText,
    required = false,
    inputFormat = 'MM/dd/yyyy',
    maxDate,
  } = props;
  //
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {isLgScreen ? (
          <DesktopDatePicker
            inputFormat={inputFormat}
            value={value}
            onChange={onChange}
            maxDate={maxDate}
            {...props}
            renderInput={(params) => (
              <CustomTextField
                required={required}
                {...params}
                fullWidth={fullWidth}
                error={error}
                helperText={helperText}
                ref={params.inputRef}
                endAdornment={
                  <InputAdornment position="end">
                    {params.InputProps?.endAdornment}
                  </InputAdornment>
                }
              />
            )}
          />
        ) : (
          <MobileDatePicker
            inputFormat={inputFormat}
            {...props}
            maxDate={maxDate}
            renderInput={(params) => (
              <CustomTextField
                required={required}
                {...params}
                error={error}
                helperText={helperText}
                fullWidth={fullWidth}
                ref={params.inputRef}
                endAdornment={
                  <InputAdornment position="end">
                    <CalendarIcon className="h-[20px]" />
                  </InputAdornment>
                }
              />
            )}
          />
        )}
      </LocalizationProvider>
    </>
  );
};

export default DatePicker;
