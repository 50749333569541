import React, { useState, useMemo, useEffect } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { TopNav } from "components";
import CrossIcon from "../../assets/Icons/CrossIcon.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TabPanel from "./TabPanel";
import { format } from "date-fns";
import { TabContext, TabList } from "@mui/lab";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import RefreshLogo from "assets/Icons/refreshLogo.svg";
import { FaCalendar } from "react-icons/fa";
import { TbArrowsLeftRight } from "react-icons/tb";
import ShoppingBagIcon from "assets/Icons/ShopingBagIcon.svg";
import CreditCardIcon from "assets/Icons/CreditCardIcon.svg";
import FileTextIcon from "assets/Icons/CreditCardIcon.svg";
import CalendarIcon from "assets/Icons/CalendarIcon.svg";
import {
  Search as SearchIcon,
  Clear as ClearIcon,
  DeleteOutlineRounded,
  DeleteRounded,
  DeleteSweepRounded,
} from "@mui/icons-material";
import Paper from "@mui/material/Paper";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import {
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Tooltip,
  Stack,
  Box,
  Typography,
  Tab,
  TextField,
  Popover,
  Dialog,
  DialogContent,
  Menu,
  Fade,
} from "@mui/material";
import DataTableCustom from "../../components/DataTableCustom";
import AddTransactionForm from "../../components/Forms/AddTransactionForm";
import customVariable from "../../constants/customVariable.js";

import EditIcon from "assets/Icons/EditIcon.svg";
import PenIcon from "assets/Icons/PenIcon.svg";
import Pen from "assets/Icons/Pen.png";
import CommonDialog from "components/CommonDialog";
import EditTransactionForm from "components/Forms/EditTransactionForm";
import {
  getFilterTxsData,
  useDeleteTxs,
  useGetDailyStats,
  useGetDailyStatsPaginated,
  useGetFilteredTransactionsPaginatedData,
  useGetPaymentSource,
  useGetTransactionsPaginatedData,
  useGetUserDataPaginatedData,
} from "./dashboardQueries";
import DailyStatsTab from "components/dailyStataTabs/DailyStatsTab";
import { useQueryClient } from "@tanstack/react-query";
import { StyledMenu } from "pages/ManageEmployees/StylesOptionMenu";
import UserTransactionDetailModal from "components/modals/UserTransactionDetailModal";
import EditedTxsDetailModal from "./EditedTxsDetailModal";
import { getLocalStorageItem } from "hooks";
import { TrashIcon } from "@heroicons/react/20/solid";
import { useGetWaves } from "pages/ManageEmployees/manageEmployeeQueries";
import ExportDBModal from "./ExportDBModal";

export function changeDateToLocal(dateStr, withTime = true) {
  const date = new Date(dateStr);
  if (withTime) {
    return date.toLocaleString();
  } else {
    const withoutTime = date?.toLocaleString().split(", ");
    return withoutTime[0];
  }
}

const CustomInput = ({ value, onClick, isOnlyDate }) => (
  <div className="date-picker-input" onClick={onClick}>
    <span className="date-picker-icon">
      <TextField
        sx={{
          "& .MuiOutlinedInput-root": {
            height: "40px",
            borderRadius: "10px",
          },
        }}
        placeholder={!isOnlyDate ? "Select Date Range" : "Transaction Date"}
        type="text"
        className="rounded-[10px]"
        value={value}
        autoComplete="off"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FaCalendar />
            </InputAdornment>
          ),
        }}
      />
    </span>
  </div>
);

const Dashboard = () => {
  const [wave, setWave] = useState(0);
  const [tabValue, setTabValue] = useState("1");
  const [dailyStatsValue, setDailyStatsValue] = useState("Successful Logins");
  const [datas, setDatas] = useState([]);
  const [dashboardFilters, setDashboardFilters] = useState([]);
  const [showTooltip, setShowTooltip] = useState(null);
  const [downloadFileData, setDownloadFileData] = useState([]);
  const [showUserDataModel, setShowUserDataModel] = useState(null);
  const [showEditTxsDataModel, setShowEditTxsDataModel] = useState(null);
  const [statsDate, setStatsDate] = useState({
    startDate: new Date().toISOString().split("T")[0],
    endDate: new Date().toISOString().split("T")[0],
  });
  const [dailyStatsFilter, setDailyStatsFilter] = useState({
    page: 1,
    perPage: 25,
    orderBy: "desc",
    filter: "SUCCESSFULL_LOGIN",
    startDate: new Date().toISOString().split("T")[0],
    endDate: new Date().toISOString().split("T")[0],
  });
  const [transactionTableFilter, setTransactionTableFilter] = useState({
    page: 1,
    perPage: 25,
    orderBy: "desc",
  });
  const { mutate: deleteTxsFn } = useDeleteTxs();

  const [userDataTableFilter, setUserDataTableFilter] = useState({
    page: 1,
    orderBy: "desc",
    perPage: 25,
  });

  const [dateRange, setDateRange] = useState([null, null]);
  let startDateRange, endDateRange;

  if (Array.isArray(dateRange) && dateRange.length === 2) {
    [startDateRange, endDateRange] = dateRange;
  } else {
    startDateRange = null;
    endDateRange = null;
  }

  const [dateRangeTransaction, setDateRangeTransaction] = useState([
    null,
    null,
  ]);
  let startDateRangeTransaction, endDateRangeTransaction;

  if (
    Array.isArray(dateRangeTransaction) &&
    dateRangeTransaction.length === 2
  ) {
    [startDateRangeTransaction, endDateRangeTransaction] = dateRangeTransaction;
  } else {
    startDateRangeTransaction = null;
    endDateRangeTransaction = null;
  }

  // console.log("dateRange", dateRange, startDateRange, endDateRange);
  const [transactionDetail, settransactionDetail] = useState(null);
  const [deleteTxsModal, setDeleteTxsModal] = useState(null);
  const [transactionFilters, setTransactionFilters] = useState(null);
  const [userDataFilters, setUserDataFilters] = useState(null);
  // console.log("userDataFilters", userDataFilters);
  const { data: txsSource } = useGetPaymentSource(transactionFilters?.category);
  // Now you can use startDateRange and endDateRange safely
  const [selectedFilters, setSelectedFilters] = useState({
    startDate: null,
    endDate: null,
    empCode: "",
    designation: "",
    txnStatus: "",
    factory: "",
    reason: "",
    name: "",
    maxAmount: "",
    minAmount: "",
    category: "",
    subCategory: "",
    paymentMode: "",
    addedOrEdited: "",
    transActionDate: null,
    searchText: "",
  });

  const activeUsersDetail = customVariable.activeUsersDetail;
  const successfulLoginDetails = customVariable.successfulLoginDetails;

  const dailyStatusColumns = [
    {
      Header: "Employee ID",
      accessor: "employeeId",
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Factory Code",
      accessor: "factoryCode",
    },
    {
      Header: "Total Entries",
      accessor: "totalEntries",
    },
    {
      Header: "Last Session Time",
      accessor: "lastSessionTime",
      Cell: ({ cell: { value }, row }) => {
        if (value) {
          const date = new Date(value);
          return <span>{date.toLocaleString()}</span>;
        }
        return <span>*NA*</span>;
      },
    },
  ];

  const userDataColumns = [
    {
      Header: "Employee ID",
      accessor: "employeeId",
      Cell: ({ cell: { value }, row }) => (
        <div className="">
          <span
            onClick={() => setShowUserDataModel(value)}
            className="cursor-pointer"
          >
            {value}
          </span>
          {showUserDataModel?.length > 0 && showUserDataModel === value && (
            <Dialog open={true} maxWidth="658px" className="z-[10000]">
              <Paper className="h-[500px] w-[450px]  px-8 rounded-lg shadow-md relative">
                <div
                  className="absolute top-[2.5%] right-[2.5%] w-[24px] h-[24px] hover:cursor-pointer"
                  onClick={() => setShowUserDataModel(null)}
                >
                  <img src={CrossIcon} className="w-[24px] h-[24px]" />
                </div>
                <UserTransactionDetailModal
                  value={row.original.id}
                  handleCloseDialog={setShowUserDataModel}
                />
              </Paper>
            </Dialog>
          )}
        </div>
      ),
    },
    {
      Header: "Shahi Attendance",
      accessor: "attendance",
    },
    {
      Header: "Successful Logins",
      accessor: "successFullLogins",
    },
    {
      Header: "Login Failures",
      accessor: "unSuccessLogins",
    },
    {
      Header: "Total Entries",
      accessor: "totalTransactions",
    },
    {
      Header: "Initial Balance",
      accessor: "initialBalance",
    },
    {
      Header: "Inflows",
      accessor: "inflow",
    },
    {
      Header: "Outflows",
      accessor: "outflow",
    },
    {
      Header: "No Transactions",
      accessor: "noTransaction",
    },
    {
      Header: "No Entry added",
      accessor: "noEntryAdded",
    },
    {
      Header: "Points",
      accessor: "points",
    },
    {
      Header: "Last Entry",
      accessor: "lastEntry",
      Cell: ({ cell: { value }, row }) => {
        const date = new Date(value);
        return <span>{date.toLocaleString()}</span>;
      },
    },
  ];
  const transactionDetailsColumns = [
    {
      Header: "Transaction ID",
      accessor: "id",
      Cell: ({ cell: { value }, row }) => (
        <div className="w-[70%] flex justify-between pl-[20px]">
          <div>
            <span>{row.original.isAdded ? `${value}**` : value}</span>
          </div>
          {row.original?.isEdited && (
            <div
              onClick={() => setShowEditTxsDataModel(value)}
              className="cursor-pointer"
            >
              <img src={Pen} className="w-[18px] h-[18px] text-red-400" />
            </div>
          )}
          {showEditTxsDataModel === value && (
            <Dialog open={true} maxWidth="658px" className="z-[10000]">
              <Paper className=" p-5 rounded-lg shadow-md relative">
                <div
                  className="absolute top-[2.5%] right-[2.5%] w-[24px] h-[24px] hover:cursor-pointer"
                  onClick={() => setShowEditTxsDataModel(false)}
                >
                  <img src={CrossIcon} className="w-[24px] h-[24px]" />
                </div>
                <EditedTxsDetailModal
                  data={row.original}
                  handleCloseDialog={setShowEditTxsDataModel}
                />
              </Paper>
            </Dialog>
          )}
        </div>
      ),
    },
    {
      Header: "Entry Timestamp",
      accessor: "createdAt",
      Cell: ({ cell: { value }, row }) => {
        const date = new Date(value);
        return <span>{date.toLocaleString()}</span>;
      },
    },
    {
      Header: "Employee ID",
      accessor: "employeeId",
    },
    {
      Header: "Transaction Date",
      accessor: "transactionDate",
      Cell: ({ cell: { value }, row }) => {
        const txDate = new Date(value);
        const withoutTime = txDate?.toLocaleString().split(", ");
        return <span>{withoutTime[0]}</span>;
      },
    },
    {
      Header: "Sub Category",
      accessor: "subCategory",
    },
    {
      Header: "Source",
      accessor: "source",
      Cell: ({ cell: { value }, row }) => {
        return (
          <div className="relative flex items-center gap-2">
            <span className="whitespace-nowrap overflow-hidden text-ellipsis">
              {value}
            </span>
            {showTooltip?.length > 0 && showTooltip === value && (
              <div className="absolute flex items-start gap-3 border top-[125%] z-[999] left-1/2 h-fit break-all whitespace-normal p-2 border-blue-900 rounded-lg bg-white capitalize w-[300px]">
                {value}
                <span
                  className="cursor-pointer"
                  onClick={() => setShowTooltip(null)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="15px"
                    height="15px"
                  >
                    <path d="M 4.9902344 3.9902344 A 1.0001 1.0001 0 0 0 4.2929688 5.7070312 L 10.585938 12 L 4.2929688 18.292969 A 1.0001 1.0001 0 1 0 5.7070312 19.707031 L 12 13.414062 L 18.292969 19.707031 A 1.0001 1.0001 0 1 0 19.707031 18.292969 L 13.414062 12 L 19.707031 5.7070312 A 1.0001 1.0001 0 0 0 18.980469 3.9902344 A 1.0001 1.0001 0 0 0 18.292969 4.2929688 L 12 10.585938 L 5.7070312 4.2929688 A 1.0001 1.0001 0 0 0 4.9902344 3.9902344 z" />
                  </svg>
                </span>
              </div>
            )}
            {value?.length > 50 && (
              <div
                className="cursor-pointer"
                onClick={() => setShowTooltip(showTooltip ? null : value)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 48 48"
                  width="17px"
                  height="17px"
                >
                  <path d="M 24 4 C 12.972066 4 4 12.972074 4 24 C 4 35.027926 12.972066 44 24 44 C 35.027934 44 44 35.027926 44 24 C 44 12.972074 35.027934 4 24 4 z M 24 7 C 33.406615 7 41 14.593391 41 24 C 41 33.406609 33.406615 41 24 41 C 14.593385 41 7 33.406609 7 24 C 7 14.593391 14.593385 7 24 7 z M 24 14 A 2 2 0 0 0 24 18 A 2 2 0 0 0 24 14 z M 23.976562 20.978516 A 1.50015 1.50015 0 0 0 22.5 22.5 L 22.5 33.5 A 1.50015 1.50015 0 1 0 25.5 33.5 L 25.5 22.5 A 1.50015 1.50015 0 0 0 23.976562 20.978516 z" />
                </svg>
              </div>
            )}
          </div>
        );
      },
    },
    {
      Header: "Mode of Payment",
      accessor: "mode",
    },
    {
      Header: "Amount",
      accessor: "amount",
      Cell: ({ cell: { value }, row }) => {
        let showEdit = false;
        const todayDate = new Date();
        let currentDate = new Date(row.original.transactionDate);
        let sevenDaysGap = new Date(currentDate);
        sevenDaysGap.setDate(currentDate.getDate() + 7);
        if (sevenDaysGap > todayDate) {
          showEdit = true;
        }
        if (row.original.type === "NONE") {
          showEdit = false;
        }
        const user = getLocalStorageItem("USER_ROLE");

        return (
          <div className="grid grid-cols-2 items-center  min-w-[150px]">
            <p className=" text-center">{value}</p>
            <div className="flex items-center justify-start gap-2">
              {(user === "SUPER_ADMIN" || showEdit) && (
                <div
                  className="hover:cursor-pointer"
                  onClick={() => {
                    handleEditClick(row);
                  }}
                >
                  <img src={EditIcon} className="w-[20px] h-[20px]" />
                </div>
              )}
              {(user === "SUPER_ADMIN" || user === "ADMIN") &&
                row.original.isEdited === false && (
                  <div
                    onClick={() => {
                      handleDeleteTxsClick(row);
                    }}
                    className="hover:cursor-pointer "
                  >
                    <DeleteOutlineRounded fontSize="medium" />
                  </div>
                )}
            </div>
          </div>
        );
      },
    },
  ];

  const handleEditClick = (row) => {
    settransactionDetail(row.original);
    handleClickOpenEditDialog();
  };
  const handleDeleteTxsClick = (txId) => {
    setDeleteTxsModal(txId?.original?.id);
  };

  // filters datas
  const filters = [
    "Category",
    "Mode of Payment",
    "Entry Add/Edit",
    "Transaction Date",
    "Transaction Id",
  ];

  const categoryOptionsData = [
    {
      id: "1",
      label: "Inflow",
      value: "CREDIT",
    },
    {
      id: "2",
      label: "Outflow",
      value: "DEBIT",
    },
    {
      id: "3",
      label: "No Transaction",
      value: "NONE",
    },
  ];

  const paymentsMode = [
    "Cash",
    "Debit Card",
    "UPI (Google pay/Phone Pe)",
    "Mobile Wallets",
    "Credit (at the shop)",
    "Credit Card",
    "Bank Transfer",
    "Net Banking",
    "Any other",
  ];

  const addEditOptionData = [
    {
      id: "1",
      value: "ADD",
    },
    {
      id: "2",
      value: "EDIT",
    },
  ];

  const MenuProps = {
    PaperProps: {
      style: {
        // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  const [grantInactiveUser, setGrantInactiveUser] = useState({});
  const [anchorDownloadMenu, setAnchorDownloadMenu] = React.useState(null);
  const [optionAnchorEl, setOptionAnchorEl] = React.useState(null);
  const downloadMenuOpen = Boolean(anchorDownloadMenu);
  const optionMenuOpen = Boolean(optionAnchorEl);
  const handleDownloadMenu = (event) => {
    setAnchorDownloadMenu(event.currentTarget);
  };
  const handleDownloadMenuClose = () => {
    setAnchorDownloadMenu(null);
  };

  const open = Boolean(optionAnchorEl);

  const handleOptionClick = (event) => {
    setOptionAnchorEl(event.currentTarget);
  };
  const handleOptionClose = () => {
    setOptionAnchorEl(null);
  };

  const handleDailyStatusTab = (labelName) => {
    setDailyStatsValue(labelName);
  };
  useEffect(() => {
    setDownloadFileData([]);
    setDailyStatsValue("Successful Logins");
  }, [tabValue]);

  const handleChange = (event) => {
    const value = event.target.value;
    setDashboardFilters((state) =>
      state?.includes(value)
        ? state?.filter((opt) => opt !== value)
        : [...state, value]
    );
  };

  const handleStartEndDateFilter = (update) => {
    const dateObjStart = new Date(update?.[0] || null);
    const dateObjEnd = new Date(update?.[1] || null);
    // const formatStartDate = dateObjStart.toLocaleDateString().split("/");
    // const formatEndDate = dateObjEnd.toLocaleDateString().split("/");
    // const startDate = `${formatStartDate[2]}-${formatStartDate[0]}-${formatStartDate[1]}`;
    // const endDate = `${formatEndDate[2]}-${formatEndDate[0]}-${formatEndDate[1]}`;
    const startDate = format(dateObjStart, "yyyy-MM-dd");
    const endDate = format(dateObjEnd, "yyyy-MM-dd");
    setUserDataFilters((prev) => {
      return {
        ...prev,
        startDate: startDate,
        endDate: endDate,
      };
    });
    setSelectedFilters({
      ...selectedFilters,
      startDate: update?.[0],
      endDate: update?.[1],
    });
  };

  const handleSearchChange = (searchText) => {
    setSelectedFilters({
      ...selectedFilters,
      searchText: searchText,
    });
  };

  // Add transaction dialog
  const [openAddTransaction, setOpenAddTransaction] = useState(false);
  const [openExportDb, setOpenExportDb] = useState(false);
  const [openFiltersDialog, setOpenFiltersDialog] = useState(false);

  //handlers
  const handleOpenExportModal = () => {
    setOpenExportDb(true);
  };

  const handleCloseExportModal = () => {
    setOpenExportDb(false);
  };

  const openTransactionDialog = () => {
    setOptionAnchorEl(null);
    setOpenAddTransaction(true);
  };

  const handlerOpenFilterDialog = () => {
    setOpenFiltersDialog(true);
  };

  const handleClose = () => {
    setOpenAddTransaction(false);
    setOpenFiltersDialog(false);
  };

  // Edit transaction dialog
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const handleClickOpenEditDialog = () => {
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  //queries
  const { data: wavesData, isLoading } = useGetWaves();
  // console.log("wavesData", wavesData);

  const { data: dailyStatsData, isSuccess: dailyStatsSuccess } =
    useGetDailyStats({ ...statsDate, waveId: wave });

  const {
    data: dailyStatsDataPaginated,
    dataUpdatedAt: dailyStatsDataUpdateAt,
  } = useGetDailyStatsPaginated({ ...dailyStatsFilter, waveId: wave });

  const {
    data: transactionDataPaginated,
    dataUpdatedAt: transactionsDataUpdateAt,
  } = useGetTransactionsPaginatedData({
    ...transactionTableFilter,
    waveId: wave,
  });
  // user data api
  const { data: usersDataPaginated, dataUpdatedAt: usersDataUpdateAt } =
    useGetUserDataPaginatedData({ ...userDataTableFilter, waveId: wave });

  const { dailyStatsDataList, dailyStatsPaginationInfo } = useMemo(() => {
    if (dailyStatsDataUpdateAt) {
      return {
        dailyStatsDataList: dailyStatsDataPaginated?.data?.records,
        dailyStatsPaginationInfo: dailyStatsDataPaginated?.data?.meta,
      };
    } else {
      return { dailyStatsDataList: [], dailyStatsPaginationInfo: {} };
    }
  }, [dailyStatsDataUpdateAt]);

  const { transactionDataList, transactionsPaginationInfo } = useMemo(() => {
    if (transactionsDataUpdateAt) {
      return {
        transactionDataList: transactionDataPaginated?.data?.records,
        transactionsPaginationInfo: transactionDataPaginated?.data?.meta,
      };
    } else {
      return { transactionDataList: [], transactionsPaginationInfo: {} };
    }
  }, [transactionsDataUpdateAt]);

  const { usersDataList, usersPaginationInfo } = useMemo(() => {
    if (usersDataUpdateAt) {
      return {
        usersDataList: usersDataPaginated?.data?.records,
        usersPaginationInfo: usersDataPaginated?.data?.meta,
      };
    } else {
      return { usersDataList: [], usersPaginationInfo: {} };
    }
  }, [usersDataUpdateAt]);
  const queryClient = useQueryClient();
  useEffect(() => {
    if (tabValue === "1") {
      queryClient.invalidateQueries(["daily-stats"]);
      switch (dailyStatsValue) {
        case "Successful Logins":
          setDailyStatsFilter({
            ...dailyStatsFilter,
            filter: "SUCCESSFULL_LOGIN",
            page: 1,
            startDate: new Date().toISOString().split("T")[0],
            endDate: new Date().toISOString().split("T")[0],
          });
          break;
        case "Unsuccessful Logins":
          setDailyStatsFilter({
            ...dailyStatsFilter,
            filter: "UNSUCCESSFULL_LOGIN",
            page: 1,
            startDate: new Date().toISOString().split("T")[0],
            endDate: new Date().toISOString().split("T")[0],
          });
          break;
        case "Did Not Login":
          setDailyStatsFilter({
            ...dailyStatsFilter,
            filter: "DID_NOT_LOGIN",
            page: 1,
            startDate: new Date().toISOString().split("T")[0],
            endDate: new Date().toISOString().split("T")[0],
          });
          break;
        case "Transactions":
          setDailyStatsFilter({
            ...dailyStatsFilter,
            filter: "TRANSACTIONS",
            page: 1,
            startDate: new Date().toISOString().split("T")[0],
            endDate: new Date().toISOString().split("T")[0],
          });
          break;
        case "No Transaction":
          setDailyStatsFilter({
            ...dailyStatsFilter,
            filter: "NO_TRANSACTIONS",
            page: 1,
            startDate: new Date().toISOString().split("T")[0],
            endDate: new Date().toISOString().split("T")[0],
          });
          break;
        case "No Entry Added":
          setDailyStatsFilter({
            ...dailyStatsFilter,
            filter: "NO_ENTRY_ADDED",
            page: 1,
            startDate: new Date().toISOString().split("T")[0],
            endDate: new Date().toISOString().split("T")[0],
          });
          break;
        default:
          break;
      }
    }
  }, [dailyStatsValue, tabValue]);

  useEffect(() => {
    setDatas(dailyStatsDataList);
  }, [dailyStatsDataUpdateAt]);

  if (dailyStatsSuccess) {
    const {
      activeUsers,
      didNotLogin,
      noEntryAdded,
      noTransactions,
      successfulLogins,
      transactions,
      unsuccessfulLogins,
    } = dailyStatsData?.data;

    const handleCsvExcel = (value) => {
      setAnchorDownloadMenu(null);
      setOptionAnchorEl(null);
      //download CSV file
      if (value === "Download CSV") {
        let csvData = handleDailyStatsDownload();
        if (tabValue === "2") {
          csvData = handleUserDataDownload();
        }
        if (tabValue === "3") {
          csvData = handleTransactionsDownload();
        }
        const csvBlob = new Blob([csvData], {
          type: "text/csv;charset=utf-8;",
        });
        const csvUrl = URL.createObjectURL(csvBlob);
        const link = document.createElement("a");
        link.href = csvUrl;
        link.setAttribute("download", "data.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      //download excel sheet
      else if (value === "Excel Sheet") {
        let jsonToXLData = dailyStatsDataList;
        if (tabValue === "2") {
          jsonToXLData = usersDataList;
        }
        if (tabValue === "3") {
          const filterTxs = transactionDataList.map((txsObj) =>
            getFilterTxsData(txsObj)
          );
          console.log("filterTxs", filterTxs);
          jsonToXLData = filterTxs;
        }
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(jsonToXLData);
        XLSX.utils.book_append_sheet(wb, ws, "data");
        XLSX.writeFile(wb, "data.xlsx");
      }
    };

    function handleDailyStatsDownload() {
      const dataList =
        downloadFileData.length > 0 ? downloadFileData : dailyStatsDataList;

      const data = dataList.map((employee) => [
        employee?.employeeId,
        employee?.name,
        employee?.factoryCode,
        employee?.totalEntries,
        employee?.lastSessionTime,
      ]);

      const csvData = Papa.unparse([
        [
          "Employee Id",
          "Name",
          "Factory Code",
          "Total Entries",
          "Last Session Time",
        ],
        ...data,
      ]);

      return csvData;
    }

    function handleUserDataDownload() {
      const dataList =
        downloadFileData.length > 0 ? downloadFileData : usersDataList;

      const data = dataList.map((user) => [
        user?.employeeId,
        user?.attendance,
        user?.successFullLogins,
        user?.unSuccessLogins,
        user?.totalTransactions,
        user?.inflow,
        user?.outflow,
        user?.noTransaction,
        user?.noEntryAdded,
        user?.lastEntry,
      ]);
      const csvData = Papa.unparse([
        [
          "Employee ID",
          "Shahi Attendance",
          "Successful Logins",
          "Login Failures",
          "Total Entries",
          "Inflows",
          "Outflows",
          "No Transactions",
          "No Entry added",
          "Last Entry",
        ],
        ...data,
      ]);

      return csvData;
    }

    function handleTransactionsDownload() {
      const dataList =
        downloadFileData.length > 0 ? downloadFileData : transactionDataList;

      const data = dataList.map((transaction) => [
        transaction?.id,
        transaction?.employeeId,
        transaction?.createdAt,
        transaction?.transactionDate,
        transaction?.subCategory,
        transaction?.source,
        transaction?.mode,
        transaction?.amount,
      ]);

      const csvData = Papa.unparse([
        [
          "id",
          "employeeId",
          "entryTimestamp",
          "transactionDate",
          "subCategory",
          "source",
          "modeOfPayment",
          "amount",
        ],
        ...data,
      ]);

      return csvData;
    }

    return (
      <>
        <div className="w-[95%] pt-6 mx-auto self-start">
          <div className="flex items-end justify-between gap-2">
            <TabPanel
              tabvalue={tabValue}
              setTabValue={setTabValue}
              setSelectedFilters={setSelectedFilters}
              setDailyStatsFilter={setDailyStatsFilter}
              setUserDataTableFilter={setUserDataTableFilter}
              setDashboardFilters={setDashboardFilters}
              setTransactionTableFilter={setTransactionTableFilter}
              setWave={setWave}
              setDateRange={setDateRange}
              setDateRangeTransaction={setDateRangeTransaction}
            />
            <Grid
              container
              className="flex items-center self-center justify-end w-full gap-3"
            >
              <Grid item>
                <FormControl>
                  <Select
                    labelId="my-select-placeholder"
                    id="demo-simple-select"
                    onChange={(e) => {
                      setWave(e.target.value);
                    }}
                    className="flex gap-2 items-center h-[40px] rounded-[10px] bg-[#FFFFFF99] border-[1px] p-5 border-[#fff]"
                    value={wave}
                  >
                    <MenuItem value={0}>All Waves</MenuItem>
                    {wavesData?.data
                      ?.sort((a, b) => a.id - b.id)
                      .map((wave) => (
                        <MenuItem value={wave?.id} key={wave.name}>
                          {wave?.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              {tabValue === "3" && (
                <div>
                  <Button
                    onClick={handleOpenExportModal}
                    variant="outlined"
                    className="font-semibold max-lg:hidden font-[Inter] py-2 px-4 capitalize mr-2.5"
                  >
                    Export DB{" "}
                  </Button>
                  {openExportDb && (
                    <ExportDBModal
                      openExportDb={openExportDb}
                      handleCloseExportModal={handleCloseExportModal}
                      currentFilters={{
                        ...transactionTableFilter,
                        waveId: wave,
                      }}
                    />
                  )}

                  <Button
                    onClick={openTransactionDialog}
                    variant="outlined"
                    className="font-semibold max-lg:hidden font-[Inter] py-2 px-4 capitalize "
                  >
                    Add Transaction
                  </Button>
                  <Dialog
                    open={openAddTransaction}
                    onClose={handleClose}
                    maxWidth="658px"
                  >
                    <Paper className="w-[658px] rounded-lg shadow-md overflow-auto">
                      <AddTransactionForm closeDialog={handleClose} />
                    </Paper>
                  </Dialog>
                </div>
              )}

              {/* {tabValue === "1" && (
                <div>
                  {" "}
                 
                </div>
              )} */}

              <Button
                id="download-button"
                aria-controls={downloadMenuOpen ? "download-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={downloadMenuOpen ? "true" : undefined}
                className="text-white max-lg:hidden  capitalize bg-gradient-to-b tracking-wide from-[#A1A4EE] to-[#3D3E78]  font-semibold rounded-[8px] border-none px-5 py-2"
                onClick={handleDownloadMenu}
                startIcon={<FileDownloadOutlinedIcon />}
              >
                Download
              </Button>
              <Menu
                id="download-menu"
                MenuListProps={{
                  "aria-labelledby": "download-button",
                }}
                anchorEl={anchorDownloadMenu}
                open={downloadMenuOpen}
                onClose={handleDownloadMenuClose}
                TransitionComponent={Fade}
              >
                <MenuItem onClick={() => handleCsvExcel("Download CSV")}>
                  Download CSV
                </MenuItem>
                <MenuItem onClick={() => handleCsvExcel("Excel Sheet")}>
                  Excel Sheet
                </MenuItem>
              </Menu>

              <Button
                id="options-button"
                aria-controls={optionMenuOpen ? "options-menu" : undefined}
                aria-haspopup="true"
                className="lg:hidden"
                aria-expanded={optionMenuOpen ? "true" : undefined}
                variant="contained"
                disableElevation
                onClick={handleOptionClick}
                endIcon={<KeyboardArrowDownIcon />}
              >
                Options
              </Button>
              <StyledMenu
                id="options-menu"
                MenuListProps={{
                  "aria-labelledby": "options-button",
                }}
                anchorEl={optionAnchorEl}
                open={optionMenuOpen}
                TransitionComponent={Fade}
                onClose={handleOptionClose}
              >
                {tabValue === "3" && (
                  <MenuItem onClick={openTransactionDialog}>
                    Add Transaction
                  </MenuItem>
                )}
                <MenuItem onClick={() => handleCsvExcel("Download CSV")}>
                  Download CSV
                </MenuItem>
                <MenuItem onClick={() => handleCsvExcel("Excel Sheet")}>
                  Excel Sheet
                </MenuItem>
              </StyledMenu>
            </Grid>
          </div>
          <div className="bg-[#E8EBFA] w-full border-top border-[#5B5FC7] rounded-tr-lg">
            {tabValue == "1" && (
              <Stack className="w-[92%] gap-8 flex flex-row justify-between mx-auto py-[20px]">
                <div className="w-1/2 justify-between py-4 max-[1250px]:flex-row max-[1250px]:items-start bg-white flex flex-col gap-1 rounded-[8px] shadow-md">
                  <Box className="flex flex-row items-center gap-[8px] pl-[25px] max-lg:pl-2">
                    <span className="text-[#3D3E78] font-[DMSans] text-[34px] font-bold leading-[42px]">
                      {activeUsers}
                    </span>
                    <Typography
                      variant="body"
                      color="#3D3E78"
                      fontFamily="Poppins"
                      fontSize={16}
                      fontStyle="normal"
                      fontWeight={600}
                      lineHeight="150%"
                    >
                      Active Users
                    </Typography>
                  </Box>
                  <div className="w-full xl:w-[88%] lg:w-[98%] max-[1250px]:flex-col max-[1250px]:items-end max-[1250px]:w-1/2 pr-5 ml-auto flex justify-evenly max-xl:justify-end items-center gap-1">
                    <DailyStatsTab
                      clickHandler={() => {
                        handleDailyStatusTab("Did Not Login");
                      }}
                      contain={
                        dailyStatsValue === "Did Not Login" ? true : false
                      }
                      value={didNotLogin}
                      label={"Did Not Login"}
                    />
                    <DailyStatsTab
                      clickHandler={() => {
                        handleDailyStatusTab("Successful Logins");
                      }}
                      contain={
                        dailyStatsValue === "Successful Logins" ? true : false
                      }
                      value={successfulLogins}
                      label={"Successful Logins"}
                    />

                    <DailyStatsTab
                      clickHandler={() => {
                        handleDailyStatusTab("Unsuccessful Logins");
                      }}
                      contain={
                        dailyStatsValue === "Unsuccessful Logins" ? true : false
                      }
                      value={unsuccessfulLogins}
                      label={"Unsuccessful Logins"}
                    />
                  </div>
                </div>
                <div className="w-1/2 py-4 max-[1250px]:flex-row max-[1250px]:items-start bg-white flex flex-col gap-1 rounded-[8px] shadow-md">
                  <Box className="flex flex-row items-center gap-[8px] pl-[25px] max-lg:pl-2">
                    <span className="text-[#3D3E78] font-[DMSans] text-[34px] font-bold leading-[42px]">
                      {successfulLogins}
                    </span>
                    <Typography
                      variant="body"
                      color="#3D3E78"
                      fontFamily="Poppins"
                      fontSize={16}
                      fontStyle="normal"
                      fontWeight={600}
                      lineHeight="150%"
                    >
                      Successful Logins
                    </Typography>
                  </Box>
                  <div className="w-full xl:w-[88%] lg:w-[98%] max-[1250px]:flex-col max-[1250px]:items-end max-[1250px]:w-1/2 pr-5 ml-auto flex justify-evenly max-xl:justify-end items-center gap-1">
                    <DailyStatsTab
                      clickHandler={() => {
                        handleDailyStatusTab("Transactions");
                      }}
                      contain={
                        dailyStatsValue === "Transactions" ? true : false
                      }
                      value={transactions}
                      label={"Transactions"}
                    />
                    <DailyStatsTab
                      clickHandler={() => {
                        handleDailyStatusTab("No Transaction");
                      }}
                      contain={
                        dailyStatsValue === "No Transaction" ? true : false
                      }
                      value={noTransactions}
                      label={"No Transaction"}
                    />
                    <DailyStatsTab
                      clickHandler={() => {
                        handleDailyStatusTab("No Entry Added");
                      }}
                      contain={
                        dailyStatsValue === "No Entry Added" ? true : false
                      }
                      value={noEntryAdded}
                      label={"No Entry Added"}
                    />
                  </div>
                </div>
              </Stack>
            )}
            {tabValue === "2" && (
              <Grid
                container
                className="flex justify-between  p-4 items-center flex-nowrap rounded-lg  shadow-md"
              >
                <div className="flex items-center justify-between gap-2">
                  <Grid item style={{ zIndex: 9999 }}>
                    <TextField
                      placeholder={`Search`}
                      value={userDataFilters?.search || ""}
                      autoComplete="off"
                      onChange={(e) =>
                        setUserDataFilters((prev) => {
                          return {
                            ...prev,
                            search: String(e.target.value),
                          };
                        })
                      }
                      className="rounded-[10px] max-lg:w-[150px]"
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon style={{ color: "black" }} />
                          </InputAdornment>
                        ),
                        style: {
                          height: "2.5rem",
                          borderRadius: "8px",
                        },
                      }}
                      size="small"
                    />
                  </Grid>
                  <div
                    item
                    style={{ zIndex: 9999 }}
                    // className="max-lg:w-[260px]"
                  >
                    <DatePicker
                      selectsRange={true}
                      startDate={startDateRange}
                      endDate={endDateRange}
                      onChange={(update) => {
                        console.log("DatePicker", update);
                        setDateRange(update);
                        handleStartEndDateFilter(update);
                      }}
                      maxDate={new Date()}
                      customInput={<CustomInput />}
                      className="react-datepicker__wrapper "
                      dateFormat="dd/MM/yyyy"
                    />
                  </div>
                  <Grid item className="rounded-lg">
                    <Button
                      variant="outlined"
                      className="bg-[#E8EBFA] p-0"
                      sx={{ height: "35px", borderRadius: "8px" }}
                      onClick={() => {
                        setDateRange([null, null]);
                        handleSearchChange("");
                        setUserDataFilters(null);
                        setUserDataTableFilter({
                          page: 1,
                          orderBy: "desc",
                          perPage: 25,
                        });
                      }}
                    >
                      <img
                        src={RefreshLogo}
                        alt="Refresh Logo"
                        className="w-[24px]  h-[24px] p-1"
                      />
                    </Button>
                  </Grid>
                </div>
                <Grid item className="flex items-center pl-5">
                  <Button
                    onClick={() =>
                      setUserDataTableFilter((prev) => {
                        return { ...prev, ...userDataFilters };
                      })
                    }
                    variant="standard"
                    className="leading-[150%] capitalize font-semibold text-sm px-14 max-lg:px-4 py-2 text-white bg-[#3D3E78]"
                  >
                    Show Data
                  </Button>
                </Grid>
              </Grid>
            )}
            {tabValue === "3" && (
              <Grid
                container
                className="bg-[##E8EBFA] flex justify-between flex-wrap p-4 rounded-lg  shadow-md gap-2"
              >
                <div className="flex justify-start flex-wrap gap-2">
                  <TextField
                    placeholder={`Emp. Id`}
                    value={transactionFilters?.search || ""}
                    autoComplete="off"
                    onChange={(event) =>
                      setTransactionFilters((prev) => {
                        return { ...prev, search: event.target.value };
                      })
                    }
                    className="rounded-[10px]"
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon style={{ color: "black" }} />
                        </InputAdornment>
                      ),
                      style: {
                        width: "12rem",
                        height: "2.5rem",
                        borderRadius: "8px",
                      },
                    }}
                    size="small"
                  />
                  {dashboardFilters?.includes("Category") && (
                    <Grid item>
                      <FormControl>
                        <Select
                          labelId="my-select-placeholder"
                          id="demo-simple-select"
                          defaultValue={""}
                          // onChange={handleCategoryFilter}
                          onChange={(event) =>
                            setTransactionFilters((prev) => {
                              return { ...prev, category: event.target.value };
                            })
                          }
                          className="min-w-[160px] h-[40px] rounded-[10px]"
                          startAdornment={
                            <InputAdornment position="start">
                              <TbArrowsLeftRight className="text-black rounded-lg" />
                            </InputAdornment>
                          }
                          displayEmpty
                        >
                          <MenuItem disabled value="">
                            <p className="font-[14px] text-gray-500 font-[manrope] opacity-70">
                              Category
                            </p>
                          </MenuItem>
                          {categoryOptionsData.map((name) => (
                            <MenuItem key={name?.label} value={name?.value}>
                              <ListItemText primary={name?.label} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  {dashboardFilters?.includes("Transaction Id") && (
                    <TextField
                      value={transactionFilters?.transactionId || ""}
                      autoComplete="off"
                      className="rounded-[10px]"
                      variant="outlined"
                      size="small"
                      onChange={(event) =>
                        setTransactionFilters((prev) => {
                          return {
                            ...prev,
                            transactionId: event.target.value,
                          };
                        })
                      }
                      placeholder="Transaction Id"
                    />
                  )}
                  {dashboardFilters?.includes("Category") &&
                    txsSource?.data.length > 0 && (
                      <Grid item>
                        <FormControl>
                          <Select
                            labelId="my-select-placeholder"
                            id="demo-simple-select"
                            defaultValue={""}
                            // onChange={handleSubCategoryFilter}
                            onChange={(event) =>
                              setTransactionFilters((prev) => {
                                return {
                                  ...prev,
                                  subCategory: event.target.value,
                                };
                              })
                            }
                            className="min-w-[160px] h-[40px] rounded-[10px]"
                            startAdornment={
                              <InputAdornment position="start">
                                <img src={ShoppingBagIcon} />
                              </InputAdornment>
                            }
                            displayEmpty
                          >
                            <MenuItem disabled value="">
                              <p className="font-[14px] text-gray-500 font-[manrope] opacity-70">
                                Sub-Category
                              </p>
                            </MenuItem>
                            {txsSource?.data?.map((txs) => (
                              <MenuItem key={txs?.name} value={txs?.name}>
                                <ListItemText primary={txs?.name} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    )}
                  {dashboardFilters?.includes("Mode of Payment") && (
                    <Grid item>
                      <FormControl>
                        <Select
                          labelId="my-select-placeholder"
                          id="demo-simple-select"
                          defaultValue={selectedFilters?.paymentMode}
                          // onChange={handlePaymentModeFilter}
                          onChange={(event) =>
                            setTransactionFilters((prev) => {
                              return {
                                ...prev,
                                paymentMode: event.target.value,
                              };
                            })
                          }
                          helperText="Transaction Status"
                          className="min-w-[160px] h-[40px] rounded-[10px]"
                          startAdornment={
                            <InputAdornment position="start">
                              <img src={CreditCardIcon} />
                            </InputAdornment>
                          }
                          displayEmpty
                        >
                          <MenuItem disabled value="">
                            <p className="font-[14px] text-gray-500 font-[manrope] opacity-70">
                              Mode of Payment
                            </p>
                          </MenuItem>
                          {paymentsMode.map((name) => (
                            <MenuItem key={name} value={name}>
                              <ListItemText primary={name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  {dashboardFilters?.includes("Entry Add/Edit") && (
                    <Grid item>
                      <FormControl>
                        <Select
                          labelId="my-select-placeholder"
                          id="demo-simple-select"
                          onChange={(event) =>
                            setTransactionFilters((prev) => {
                              return {
                                ...prev,
                                addEdit: event.target.value,
                              };
                            })
                          }
                          className="min-w-[160px] h-[40px] rounded-[10px]"
                          startAdornment={
                            <InputAdornment position="start">
                              <img src={FileTextIcon} />
                            </InputAdornment>
                          }
                          displayEmpty
                          defaultValue=""
                        >
                          <MenuItem disabled value="">
                            <p className="font-[14px] text-gray-500 font-[manrope] opacity-70">
                              Entry Add/Edit
                            </p>
                          </MenuItem>
                          {addEditOptionData.map((name) => (
                            <MenuItem key={name?.value} value={name?.value}>
                              <ListItemText primary={name?.value} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  {dashboardFilters?.includes("Transaction Date") && (
                    <Grid item>
                      <FormControl>
                        <Grid item style={{ zIndex: 9999 }}>
                          <DatePicker
                            selectsRange={true}
                            startDate={startDateRangeTransaction}
                            endDate={endDateRangeTransaction}
                            // selected={
                            //   transactionFilters?.transactionDate
                            //     ? new Date(transactionFilters?.transactionDate)
                            //     : new Date()
                            // }
                            onChange={(date) => {
                              // // const dateObject = new Date(date);
                              // // const localeDate =
                              // //   dateObject.toLocaleDateString();
                              // // const finalDate = localeDate.split("/");
                              // const formattedDate = format(date, "yyyy-MM-dd");

                              // setTransactionFilters((prev) => {
                              //   return {
                              //     ...prev,
                              //     // transactionDate: `${finalDate[2]}-${finalDate[1]}-${finalDate[0]}`,
                              //     transactionDate: formattedDate,
                              //   };
                              // });
                              setDateRangeTransaction(date);

                              const dateObjStart = new Date(date?.[0] || null);
                              const dateObjEnd = new Date(date?.[1] || null);
                              const startDate = format(
                                dateObjStart,
                                "yyyy-MM-dd"
                              );
                              const endDate = format(dateObjEnd, "yyyy-MM-dd");
                              setTransactionFilters((prev) => {
                                return {
                                  ...prev,
                                  // transactionDate: `${finalDate[2]}-${finalDate[1]}-${finalDate[0]}`,
                                  transactionDate: startDate,
                                  transctionEndDate: endDate,
                                };
                              });
                            }}
                            maxDate={new Date()}
                            customInput={<CustomInput />}
                            className="react-datepicker__wrapper"
                            dateFormat="dd/MM/yyyy"
                            placeholderText="This has disabled keyboard navigation"
                          />
                        </Grid>
                      </FormControl>
                    </Grid>
                  )}
                  <Grid item className="rounded-lg">
                    <Button
                      variant="outlined"
                      className="bg-[#E8EBFA]"
                      sx={{ height: "2.5rem", borderRadius: "8px" }}
                      onClick={() => {
                        setSelectedFilters({
                          startDate: null,
                          endDate: null,
                          empCode: "",
                          designation: "",
                          txnStatus: "",
                          factory: "",
                          reason: "",
                          name: "",
                          maxAmount: "",
                          minAmount: "",
                          category: "",
                          subCategory: "",
                          paymentMode: "",
                          addedOrEdited: "",
                          searchText: "",
                        });
                        setTransactionFilters(null);
                        setTransactionTableFilter({
                          page: 1,
                          perPage: 25,
                          orderBy: "desc",
                        });
                        setDateRange([null, null]);
                        setDateRangeTransaction([null, null]);
                        setDashboardFilters([]);
                      }}
                    >
                      <img
                        src={RefreshLogo}
                        alt="Refresh Logo"
                        className="w-[24px] h-[24px] p-1"
                      />
                    </Button>
                  </Grid>
                </div>
                <div className="flex  items-center  justify-end subheader-left-side">
                  <Grid item className="max-lg:hidden">
                    <FormControl sx={{ m: 0.3 }}>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        value={"+Add Filter"}
                        disableCloseOnSelect
                        onChange={handleChange}
                        input={
                          <OutlinedInput className="leading-[150%] capitalize font-semibold text-[12px] h-[37px]  text-[#272727]" />
                        }
                        renderValue={(selected) => selected}
                        MenuProps={MenuProps}
                      >
                        {filters?.map((name) => (
                          <MenuItem key={name} value={name}>
                            <ListItemText primary={name} />
                            <Checkbox
                              checked={dashboardFilters?.indexOf(name) > -1}
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Button
                    onClick={handlerOpenFilterDialog}
                    variant="standard"
                    className="whitespace-nowrap lg:hidden capitalize font-semibold text-sm px-5 py-2 text-white bg-[#3D3E78]"
                  >
                    Filters
                  </Button>
                  <Dialog
                    open={openFiltersDialog}
                    onClose={() => setOpenFiltersDialog(false)}
                    maxWidth="700px"
                  >
                    <div className="w-full h-full relative">
                      <div
                        className="absolute top-[2.5%] right-[2.5%] w-[24px] h-[24px] hover:cursor-pointer"
                        onClick={() => setOpenFiltersDialog(false)}
                      >
                        <img src={CrossIcon} className="w-[24px] h-[24px]" />
                      </div>
                      <Paper className="px-10 py-12 rounded-lg bg-white shadow-none w-[82.5%] mx-auto flex flex-col  justify-evenly gap-6  flex-wrap">
                        <div className="grid grid-cols-2 items-center w-full">
                          <span>Transaction Date</span>
                          <FormControl>
                            <div className="w-full">
                              <DatePicker
                                selectsRange={true}
                                selected={
                                  transactionFilters?.transactionDate
                                    ? new Date(
                                        transactionFilters?.transactionDate
                                      )
                                    : new Date()
                                }
                                startDate={startDateRangeTransaction}
                                endDate={endDateRangeTransaction}
                                onChange={(date) => {
                                  // // const localeDate = date.toLocaleDateString();
                                  // // const finalDate = localeDate.split("/");
                                  // const formattedDate = format(
                                  //   date,
                                  //   "yyyy-MM-dd"
                                  // );

                                  // setTransactionFilters((prev) => {
                                  //   return {
                                  //     ...prev,
                                  //     // transactionDate: `${finalDate[2]}-${finalDate[1]}-${finalDate[0]}`,
                                  //     transactionDate: formattedDate,
                                  //   };
                                  // });
                                  setDateRangeTransaction(date);

                                  const dateObjStart = new Date(
                                    date?.[0] || null
                                  );
                                  const dateObjEnd = new Date(
                                    date?.[1] || null
                                  );
                                  const startDate = format(
                                    dateObjStart,
                                    "yyyy-MM-dd"
                                  );
                                  const endDate = format(
                                    dateObjEnd,
                                    "yyyy-MM-dd"
                                  );
                                  setTransactionFilters((prev) => {
                                    return {
                                      ...prev,
                                      // transactionDate: `${finalDate[2]}-${finalDate[1]}-${finalDate[0]}`,
                                      transactionDate: startDate,
                                      transctionEndDate: endDate,
                                    };
                                  });
                                }}
                                maxDate={new Date()}
                                customInput={<CustomInput isOnlyDate />}
                                className="react-datepicker__wrapper w-full"
                                dateFormat="dd/MM/yyyy"
                              />
                            </div>
                          </FormControl>
                        </div>

                        <div className="grid grid-cols-2 items-center w-full">
                          <span>Transaction Id</span>
                          <TextField
                            value={transactionFilters?.transactionId || ""}
                            autoComplete="off"
                            className="rounded-[10px]"
                            variant="outlined"
                            size="small"
                            onChange={(event) =>
                              setTransactionFilters((prev) => {
                                return {
                                  ...prev,
                                  transactionId: event.target.value,
                                };
                              })
                            }
                          />
                        </div>
                        <div className="grid grid-cols-2 items-center w-full">
                          <span>Category</span>
                          <FormControl>
                            <Select
                              labelId="my-select-placeholder"
                              id="demo-simple-select"
                              placeholder="Category"
                              defaultValue={transactionFilters?.category}
                              onChange={(event) =>
                                setTransactionFilters((prev) => {
                                  return {
                                    ...prev,
                                    category: event.target.value,
                                  };
                                })
                              }
                              helperText="Transaction Status"
                              className="min-w-[160px] h-[40px] rounded-[10px]"
                              startAdornment={
                                <InputAdornment position="start">
                                  <TbArrowsLeftRight className="rounded-lg text-black" />
                                </InputAdornment>
                              }
                              displayEmpty
                            >
                              <MenuItem disabled value="">
                                <ListItemText primary={"Category"} />
                              </MenuItem>
                              {categoryOptionsData.map((name) => (
                                <MenuItem key={name?.label} value={name?.value}>
                                  <ListItemText primary={name?.label} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>

                        <div className="grid grid-cols-2 items-center w-full">
                          <span>Sub-Category</span>
                          <FormControl>
                            <Select
                              labelId="my-select-placeholder"
                              id="demo-simple-select"
                              value={transactionFilters?.subCategory}
                              onChange={(event) =>
                                setTransactionFilters((prev) => {
                                  return {
                                    ...prev,
                                    subCategory: event.target.value,
                                  };
                                })
                              }
                              helperText="Transaction Status"
                              className="min-w-[160px] h-[40px] rounded-[10px] text-black"
                              startAdornment={
                                <InputAdornment position="start">
                                  <img src={ShoppingBagIcon} alt="bag" />
                                </InputAdornment>
                              }
                              displayEmpty
                            >
                              <MenuItem disabled value="">
                                <ListItemText primary={"Sub-Category"} />
                              </MenuItem>
                              {txsSource?.data?.map((data) => (
                                <MenuItem key={data?.name} value={data?.name}>
                                  <ListItemText primary={data?.name} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                        <div className="grid grid-cols-2 items-center w-full">
                          <span>Mode of Payment</span>
                          <FormControl>
                            <Select
                              labelId="my-select-placeholder"
                              id="demo-simple-select"
                              placeholder="Mode of Payment"
                              defaultValue={transactionFilters?.paymentMode}
                              onChange={(event) =>
                                setTransactionFilters((prev) => {
                                  return {
                                    ...prev,
                                    paymentMode: event.target.value,
                                  };
                                })
                              }
                              helperText="Transaction Status"
                              className="min-w-[160px] h-[40px] rounded-[10px]"
                              startAdornment={
                                <InputAdornment position="start">
                                  <img src={CreditCardIcon} />
                                </InputAdornment>
                              }
                              displayEmpty
                            >
                              <MenuItem disabled value="">
                                <ListItemText primary={"Payment Mode"} />
                              </MenuItem>
                              {paymentsMode.map((name) => (
                                <MenuItem key={name} value={name}>
                                  <ListItemText primary={name} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>

                        <div className="grid grid-cols-2 items-center w-full">
                          <span>Entry Add/Edit</span>
                          <FormControl>
                            <Select
                              labelId="my-select-placeholder"
                              id="demo-simple-select"
                              value={transactionFilters?.addEdit}
                              onChange={(event) =>
                                setTransactionFilters((prev) => {
                                  return {
                                    ...prev,
                                    addEdit: event.target.value,
                                  };
                                })
                              }
                              placeholder="Entry Add/Edit"
                              helperText="Transaction Status"
                              className="min-w-[160px] h-[40px] rounded-[10px]"
                              startAdornment={
                                <InputAdornment position="start">
                                  <img src={FileTextIcon} />
                                </InputAdornment>
                              }
                              displayEmpty
                            >
                              <MenuItem disabled value="">
                                <ListItemText primary={"Add/Edit"} />
                              </MenuItem>
                              {addEditOptionData.map((name) => (
                                <MenuItem key={name?.value} value={name?.value}>
                                  <ListItemText primary={name?.value} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                        <div className="flex items-center mx-auto justify-center  gap-5">
                          <Button
                            variant="standard"
                            onClick={() =>
                              setTransactionTableFilter((prev) => {
                                return { ...prev, ...transactionFilters };
                              })
                            }
                            className="whitespace-nowrap   capitalize font-semibold text-sm px-5 py-2 text-white bg-[#3D3E78]"
                          >
                            Show Data
                          </Button>
                        </div>
                      </Paper>
                    </div>
                  </Dialog>
                  <Grid item className="flex items-center pl-5">
                    <Button
                      onClick={() =>
                        setTransactionTableFilter((prev) => {
                          return { ...prev, ...transactionFilters };
                        })
                      }
                      variant="standard"
                      className="whitespace-nowrap capitalize font-semibold text-sm px-5 py-2 text-white bg-[#3D3E78]"
                    >
                      Show Data
                    </Button>
                  </Grid>
                </div>
              </Grid>
            )}
            {tabValue === "1" && (
              <DataTableCustom
                selectedTab={tabValue}
                data={datas}
                columns={dailyStatusColumns}
                pageFilter={dailyStatsFilter}
                updateFilters={setDailyStatsFilter}
                headerForDailyStats={dailyStatsValue}
                setDownloadFileData={setDownloadFileData}
                paginationInformation={dailyStatsPaginationInfo}
              />
            )}
            {tabValue === "2" && (
              <DataTableCustom
                data={usersDataList}
                selectedTab={tabValue}
                columns={userDataColumns}
                updateFilters={setUserDataTableFilter}
                pageFilter={{
                  orderBy: "desc",
                }}
                setDownloadFileData={setDownloadFileData}
                paginationInformation={usersPaginationInfo}
                // setGrantInactiveUser={setGrantInactiveUser}
                // setSearchBulkData={setSearchBulkData}
                // inActiveBulk
                // isSearch
              />
            )}
            {tabValue === "3" && (
              <DataTableCustom
                selectedTab={tabValue}
                data={transactionDataList}
                columns={transactionDetailsColumns}
                updateFilters={setTransactionTableFilter}
                setDownloadFileData={setDownloadFileData}
                pageFilter={{
                  orderBy: "desc",
                }}
                paginationInformation={transactionsPaginationInfo}
                // setGrantInactiveUser={setGrantInactiveUser}
                // setSearchBulkData={setSearchBulkData}
                // inActiveBulk
                // isChecked
                // isSearch
              />
            )}
            {
              <Dialog
                open={openEditDialog}
                onClose={handleCloseEditDialog}
                maxWidth="658px"
              >
                <Paper className="w-[658px] rounded-lg shadow-md overflow-auto">
                  <EditTransactionForm
                    closeDialog={handleCloseEditDialog}
                    transactionData={transactionDetail}
                  />
                </Paper>
              </Dialog>
            }
            {
              <Dialog
                open={deleteTxsModal}
                onClose={() => setDeleteTxsModal(null)}
              >
                <Paper className="w-[500px] rounded-lg shadow-md overflow-auto">
                  <div className="bg-blue-50 p-5 flex flex-col items-center gap-3">
                    <p className="font-medium text-lg">
                      Are you sure you want to delete this transaction?
                    </p>
                    <div className="flex gap-2 items-center justify-between w-full ">
                      <Button
                        onClick={() => setDeleteTxsModal(null)}
                        className="leading-[150%] capitalize font-semibold grow text-sm px-14 max-lg:px-4 py-2 border border-solid border-[#3D3E78]"
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="standard"
                        className="leading-[150%] capitalize font-semibold grow text-sm px-14 max-lg:px-4 py-2 text-white bg-[#3D3E78]"
                        onClick={() =>
                          deleteTxsFn(deleteTxsModal, {
                            onSuccess: () => setDeleteTxsModal(null),
                          })
                        }
                      >
                        Confirm
                      </Button>
                    </div>
                  </div>
                </Paper>
              </Dialog>
            }
          </div>
        </div>
      </>
    );
  }
  return <></>;
};

export default Dashboard;
