import { useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useController } from "react-hook-form";
import CrossIcon from "../../assets/Icons/CrossIcon.svg";
import SaveIcon from "../../assets/Icons/SaveIcon";
import {
  Box,
  Divider,
  Stack,
  Grid,
  TextField,
  Autocomplete,
  Typography,
  MenuItem,
  Button,
} from "@mui/material";
import {
  useAddTransaction,
  useGetEmployeesNotDoneTxs,
  useGetPaymentSource,
} from "pages/Dashboard/dashboardQueries";
import { getLocalStorageItem } from "hooks";

const modeOfPayment = [
  "Cash",
  "Debit Card",
  "UPI (Google pay/Phone Pe)",
  "Mobile Wallets",
  "Credit (at the shop)",
  "Credit Card",
  "Bank Transfer",
  "Net Banking",
  "Any other",
];

const AddTransactionFormSchema = yup.object().shape({
  employeeId: yup.string().required("Employee Code is required field."),
  transactionDate: yup
    .date()
    .transform((currentValue, originalValue) => {
      return originalValue.trim() === "" ? undefined : currentValue;
    })
    .required("Transaction Date is a required field.")
    .test(
      "is-valid-date",
      "Transaction Date must be a valid date.",
      function (value) {
        if (value === undefined) return true;
        return !isNaN(value.getTime());
      }
    ),
  type: yup.string().required("Category is a required field."),
  // source: yup.string().required("Sub-Category is a required field."),
  source: yup.string().when("type", (type, schema) => {
    if (type === "NONE") return schema;
    return schema.required("Sub-Category is a required field.");
  }),
  description: yup.string().when("type", (type, schema) => {
    if (type === "NONE") return schema;
    return schema.required("Source is a required field.");
  }),
  amount: yup
    .number()
    .transform((value, originalValue) =>
      originalValue.trim() === "" ? null : value
    )
    .when("type", (type, schema) => {
      if (type === "NONE") return schema.nullable(true).notRequired();
      return schema
        .typeError("Amount must be a number.")
        .min(1, "Amount must be greater than or equal to 1.")
        .required("Amount is a required field.");
    }),
  mode: yup
    .string()
    .transform((value, originalValue) =>
      originalValue.trim() === "" ? null : value
    )
    .when("type", (type, schema) => {
      if (type === "NONE") return schema.nullable(true).notRequired();
      return schema.required("Mode of payment is a required field.");
    }),
  reason: yup.string().when("type", (type, schema) => {
    if (type === "NONE") return schema.required("Reason is a required field.");
    return schema;
  }),
});
const user = getLocalStorageItem("USER_ROLE");

const AddTransactionForm = ({ closeDialog }) => {
  const [paymentSource, setPaymentSource] = useState(null);
  const [searchEmployeeId, setSearchEmployeeId] = useState("");
  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
    control,
  } = useForm({ resolver: yupResolver(AddTransactionFormSchema) });
  const { field } = useController({
    name: "employeeId",
    control,
  });
  const { mutate, error: addtxserr } = useAddTransaction();
  const onSubmit = (data) => {
    mutate(
      {
        ...data,
        source: data.source > 0 ? Number(data.source) : 0,
        transactionDate: new Date(data.transactionDate).toISOString(),
      },
      { onSuccess: () => closeDialog() }
    );
  };
  useEffect(() => {
    if (addtxserr?.data?.message) {
      setError("employeeId", {
        type: "custom",
        message: addtxserr?.data?.message,
      });
    }
  }, [addtxserr]);

  const { data } = useGetPaymentSource(paymentSource);
  const watchSelectCAtegory = watch("type");
  const watchSelectSource = watch("source");
  const { data: employeesIds } = useGetEmployeesNotDoneTxs({
    search: searchEmployeeId,
  });

  const star = " * ";
  const today = new Date();
  const todayString = today.toISOString().split("T")[0];
  const fourteenDaysAgo = new Date();
  const fortyDaysAgo = new Date();
  fourteenDaysAgo.setDate(today.getDate() - 13);
  fortyDaysAgo.setDate(today.getDate() - 40);
  const fourteenDaysAgoString = fourteenDaysAgo.toISOString().split("T")[0];
  const fortyDaysAgoString = fortyDaysAgo.toISOString().split("T")[0];

  return (
    <div className="w-full h-full bg-white shadow-md rounded-lg">
      <div className="flex justify-between px-[35px] pt-[19px] pb-[15px] ">
        <Typography className="text-center text-[22px] font-semibold tracking-tighter text-[#070707]">
          Add Transaction
        </Typography>
        <div
          className="w-[24px] h-[24px] hover:cursor-pointer"
          onClick={closeDialog}
        >
          <img src={CrossIcon} className="w-[24px] h-[24px]" />
        </div>
      </div>
      <Divider />
      <div className="">
        <form id="login-form" onSubmit={handleSubmit(onSubmit)}>
          <Stack direction="column" spacing="24px" className="w-full py-3">
            <Box className="grid grid-cols-2 px-10 items-center">
              <label
                htmlFor=""
                className="text-[#1B2559] text-[16px]  font-[700] leading-[100%]"
              >
                Employee Code<span className="text-red-500">{star}</span>:
              </label>
              {user === "SUPER_ADMIN" || user === "ADMIN" ? (
                <>
                  <TextField
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        height: "50px",

                        borderRadius: "10px",
                      },
                    }}
                    {...register("employeeId")}
                    type="number"
                    className="rounded-lg"
                    error={Boolean(errors?.employeeId?.message)}
                    helperText={errors?.employeeId?.message}
                  />
                </>
              ) : (
                <Autocomplete
                  disablePortal
                  options={
                    employeesIds?.data?.records?.map((a) => a?.employeeId) || [
                      "",
                    ]
                  }
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      height: "50px",

                      borderRadius: "10px",
                    },
                  }}
                  onChange={(event, newValue) => {
                    field.onChange(newValue);
                  }}
                  value={field.value}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        onChange={(e) => setSearchEmployeeId(e.target.value)}
                        label="Employee Id"
                      />
                    );
                  }}
                />
              )}
            </Box>
            <div className="grid grid-cols-2 px-10 items-center">
              <label
                htmlFor=""
                className="text-[#1B2559] text-[16px]  font-[700] leading-[100%]"
              >
                Transaction Date<span className="text-red-500">{star}</span>:
              </label>
              <input
                max={todayString}
                min={
                  user === "SUPER_ADMIN" || user === "ADMIN"
                    ? fortyDaysAgoString
                    : fourteenDaysAgoString
                }
                // label="Id"
                {...register("transactionDate")}
                type="date"
                className="rounded-lg px-3 h-[50px] border border-[#1B2559]"
                error={Boolean(errors?.transactionDate?.message)}
              />
            </div>
            <Box className="grid grid-cols-2 px-10 items-center">
              <label
                htmlFor=""
                className="text-[#1B2559] text-[16px]  font-[700] leading-[100%]"
              >
                Category<span className="text-red-500">{star}</span>:
              </label>
              <TextField
                id="outlined-select-currency"
                select
                sx={{
                  "& .MuiOutlinedInput-root": {
                    height: "50px",

                    borderRadius: "10px",
                  },
                }}
                className="rounded-lg"
                {...register("type")}
                error={Boolean(errors?.type?.message)}
                helperText={errors?.type?.message}
              >
                <MenuItem
                  value="CREDIT"
                  onClick={() => setPaymentSource("CREDIT")}
                >
                  INFLOW
                </MenuItem>
                <MenuItem
                  onClick={() => setPaymentSource("DEBIT")}
                  value="DEBIT"
                >
                  OUTFLOW
                </MenuItem>
                <MenuItem onClick={() => setPaymentSource("NONE")} value="NONE">
                  NO TRANSACTIONS
                </MenuItem>
              </TextField>
            </Box>
            {paymentSource !== "NONE" && (
              <>
                <Box className="grid grid-cols-2 px-10 items-center">
                  <label
                    htmlFor=""
                    className="text-[#1B2559] text-[16px]  font-[700] leading-[100%]"
                  >
                    Sub-category<span className="text-red-500">{star}</span>:
                  </label>
                  <TextField
                    id="outlined-select-currency"
                    select
                    disabled={watchSelectCAtegory ? false : true}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        height: "50px",

                        borderRadius: "10px",
                      },
                    }}
                    className="rounded-lg"
                    {...register("source")}
                    error={Boolean(errors?.source?.message)}
                    helperText={errors?.source?.message}
                  >
                    {data?.data?.map((option) => (
                      <MenuItem key={option.name} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
                <Box className="grid grid-cols-2 px-10 items-center">
                  <label
                    htmlFor=""
                    className="text-[#1B2559] text-[16px] font-[700] leading-[100%]"
                  >
                    Source<span className="text-red-500">{star}</span>:
                  </label>
                  <TextField
                    id="outlined-select-currency"
                    select
                    disabled={watchSelectSource ? false : true}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        height: "50px",

                        borderRadius: "10px",
                      },
                    }}
                    className="rounded-lg"
                    {...register("description")}
                    error={Boolean(errors?.description?.message)}
                    helperText={errors?.description?.message}
                  >
                    {data?.data
                      ?.find((data) => data.id === watchSelectSource)
                      ?.category?.map((option) => {
                        const keys = Object.keys(option);
                        return (
                          <MenuItem key={keys[0]} value={keys[0]}>
                            {keys[0]}
                          </MenuItem>
                        );
                      })}
                  </TextField>
                </Box>

                <Box className="grid grid-cols-2 px-10 items-center">
                  <label
                    htmlFor=""
                    className="text-[#1B2559] text-[16px]  font-[700] leading-[100%]"
                  >
                    Amount<span className="text-red-500">{star}</span>:
                  </label>
                  <TextField
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        height: "50px",

                        borderRadius: "10px",
                      },
                    }}
                    {...register("amount", { min: 1 })}
                    type="number"
                    className="rounded-lg"
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      onFocus: (e) => {
                        e.target.addEventListener(
                          "wheel",
                          function (e) {
                            e.preventDefault();
                          },
                          { passive: false }
                        );
                      },
                    }}
                    error={Boolean(errors?.amount?.message)}
                    helperText={errors?.amount?.message}
                  />
                </Box>
                <Box className="grid grid-cols-2 px-10 items-center">
                  <label
                    htmlFor=""
                    className="text-[#1B2559] text-[16px]  font-[700] leading-[100%]"
                  >
                    Mode of Payment<span className="text-red-500">{star}</span>:
                  </label>
                  <TextField
                    id="outlined-select-currency"
                    select
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        height: "50px",

                        borderRadius: "10px",
                      },
                    }}
                    className="rounded-lg"
                    {...register("mode")}
                    error={Boolean(
                      errors?.mode?.message && "Payment mode is required"
                    )}
                    helperText={
                      errors?.mode?.message && "Payment mode is required"
                    }
                  >
                    {modeOfPayment.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              </>
            )}
            {paymentSource === "NONE" && (
              <Box className="grid grid-cols-2 px-10 items-center">
                <label
                  htmlFor=""
                  className="text-[#1B2559] text-[16px]  font-[700] leading-[100%]"
                >
                  Reason<span className="text-red-500">{star}</span>:
                </label>
                <TextField
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      height: "50px",

                      borderRadius: "10px",
                    },
                  }}
                  className="rounded-lg"
                  {...register("reason")}
                  error={Boolean(errors?.reason?.message)}
                  helperText={errors?.reason?.message}
                />
              </Box>
            )}
          </Stack>
          <Divider />
          <Box className="flex justify-center py-[24px]">
            <Button
              variant="contained"
              className="capitalize h-[48px] w-[164px]"
              startIcon={<SaveIcon />}
              type="submit"
              onClick={handleSubmit(onSubmit)}
            >
              Save
            </Button>
          </Box>
        </form>
      </div>
    </div>
  );
};

export default AddTransactionForm;
