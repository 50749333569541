import BarGraph from "./BarGraph";
import GraphCardWrapper from "./GraphCardWrapper";
import PieChart from "./PieChart";

export default function BarAndPieChartContainer({
  graphHeadLine,
  barGraphData,
  pieGraphData,
  dxY = -30,
}) {
  return (
    <GraphCardWrapper graphHeadLine={graphHeadLine}>
      <div className="flex gap-9 justify-between items-center piechart">
        <div className="min-w-[600px]">
          <BarGraph
            dxY={dxY}
            bgcolor={barGraphData?.bgcolor}
            data={barGraphData?.data}
            xdatakey={barGraphData?.xdatakey}
            ydatakey={barGraphData?.ydatakey}
          />
        </div>
        <div className="w-[1px] self-baseline bg-slate-300"></div>
        <div className="min-w-[500px]">
          <PieChart
            colorOfPiechart={pieGraphData?.colorOfPiechart}
            data={pieGraphData?.data}
            datakey={pieGraphData?.datakey}
            namekey={pieGraphData?.namekey}
          />
        </div>
      </div>
    </GraphCardWrapper>
  );
}
