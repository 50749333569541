import { createTheme, responsiveFontSizes } from "@mui/material/styles";

/**
 * Theme
 */
const commonTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: "#333B7A",
      contrastText: "#FFF",
      light: "#15B79E",
      dark: "#0E9384",
      O1: "#5B5FC7",
      50: "#F0FDF9",
      100: "#CCFBEF",
      200: "#99F6E0",
      300: "#5FE9D0",
      400: "#2ED3B7",
      500: "#15B79E",
      600: "#0E9384",
      700: "#107569",
      800: "#125D56",
      900: "#134E48",
    },
    secondary: {
      main: "#333B7A",
      contrastText: "#FFF",
      25: "F5FAFF",
      50: "#E8F3F8",
      100: "#D1E9FF",
      200: "#A1CBE2",
      300: "#73B2D4",
      400: "#4498C5",
      500: "#157EB7",
      600: "#105F89",
      700: "#0B3F5C",
      800: "#05202E",
      900: "#194185",
    },
    gray: {
      25: "#F4F7F7",
      50: "#E6E9E9",
      100: "#CDD2D1",
      200: "#9BA5A4",
      300: "#D1D5DB",
      400: "#364C49",
      500: "#6B7280",
      600: "#031714",
      700: "#374151",
      800: "#010807",
      900: "#000303",
    },
    white: {
      logo: "#F9F9F9",
    },
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
            backgroundColor: "#5B5FC7",
            color: "#fff",
          },
        },

        outlined: {
          background: "#fff",
          color: "#1B2559",
          border: "1px solid #5B5FC7",
        },
      },
    },
    typography: {
      fontFamily: "Poppins",
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& fieldset": {
            borderColor: "#333B7A !important",
            borderRadius: "10px",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontWeight: "400",
          "& input": {
            fontWeight: "400",
          },
          "& input::placeholder": {
            fontSize: "14px",
          },
        },
      },
    },
  },
  customContainer: {
    display: "flex",
    width: "274px",
    height: "68px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    textTransform: "capitalize",
    borderRadius: "8px 8px 0 0", // Rounded top corners
    backgroundColor: "#F0F0F0",
    boxShadow:
      "0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.10)",
  },
});

// Do your common overrides here
/**
 * Typography - body1
 */
// commonTheme.typography.body1.fontSize = "0.8rem";
// commonTheme.typography.body1.color = commonTheme.palette.text.primary;

/**
 * Typography - caption
 */
commonTheme.typography.caption.color = commonTheme.palette.text.hint;
/**
 *  ** THIS SHOULD BE LAST **
 * Root Theme
 */
const rootTheme = responsiveFontSizes(commonTheme);

export default rootTheme;
