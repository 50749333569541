import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  IconButton,
  Divider,
  Button,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { CustomTextField } from "components";
import QRCode from "react-qr-code";
import { MdDeleteForever } from "react-icons/md";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { useRemoveAccess } from "./manageEmployeeQueries";
const RemoveAccessModal = (props) => {
  // Destructuring  data
  const {
    open,
    handleClose,
    grantInactiveUser,
    setGrantInactiveUser,
    changeModal,
  } = props;

  const { mutate } = useRemoveAccess();
  const handleRevokeAccess = () => {
    handleClose();

    mutate({ ids: grantInactiveUser?.map((ids) => Number(ids.id)) });
  };

  const handleRemoveEmloyee = (index) => {
    if (index > -1) {
      grantInactiveUser.splice(index, 1);
      setGrantInactiveUser([...grantInactiveUser]);
    }
  };
  const handlePdf = () => {
    window.print();
  };

  return (
    <div>
      <Dialog
        open={open}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "1050px",
            },
          },
        }}
      >
        <DialogTitle className="font-semibold" color="primary">
          <Grid container xs={12} className="flex justify-between">
            <Grid item className="font-semibold leading-[150%] text-[26px]">
              {changeModal === "1" ? "Please Confirm" : "Employee Data"}
            </Grid>
            <Grid item>
              <Grid container className="flex justify-between gap-4">
                <Button
                  variant="standard"
                  startIcon={<MdDeleteForever />}
                  onClick={handleRevokeAccess}
                  className="leading-[150%] capitalize font-semibold text-[18px] px-3 rounded-lg  py-2 text-white bg-gradient-to-b from-[#659ECD] to-[#1F305F]"
                >
                  Remove Access
                </Button>
                <Button
                  variant="standard"
                  startIcon={<FileDownloadOutlinedIcon />}
                  onClick={handlePdf}
                  className="leading-[150%]  capitalize font-semibold text-[18px] rounded-lg px-3 py-2 text-white bg-gradient-to-b from-[#659ECD] to-[#1F305F]"
                >
                  Download
                </Button>
                {changeModal === "1" ? (
                  <Grid item>
                    <Button
                      variant="outlined"
                      onClick={handleClose}
                      className="leading-[150%] font-semibold text-[18px] px-8  capitalize shadow-md rounded-lg w-[149px] h-[51px] text-black"
                    >
                      Cancel
                    </Button>
                  </Grid>
                ) : (
                  <Grid item>
                    <Button
                      variant="outlined"
                      onClick={handleClose}
                      className="leading-[150%] font-semibold text-[18px] px-8  capitalize shadow-md rounded-lg w-[149px] h-[51px] text-black"
                    >
                      Done
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider
          variant="middle"
          sx={{
            bgcolor: "#333B7A",
          }}
        />
        <DialogContent>
          {grantInactiveUser?.map((revokeUserData, index) => {
            return (
              <div key={index} className="w-full">
                <div className="flex  justify-end my-2 ">
                  <IconButton
                    aria-label="close"
                    onClick={() => handleRemoveEmloyee(index)}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
                <div className="grid grid-cols-2 gap-x-5 pt-2">
                  <div className="flex items-end  justify-between">
                    {revokeUserData?.qr && (
                      <QRCode style={{}} value={revokeUserData?.qr || ""} />
                    )}
                  </div>

                  <div className="w-full flex text-sm  flex-col justify-between">
                    <div className="grid grid-cols-2 gap-x-2">
                      <p className="font-bold text-lg max-lg:text-md">
                        Employee Id
                      </p>
                      <CustomTextField
                        variant="standard"
                        type="text"
                        readOnly
                        value={revokeUserData?.employeeId}
                      />
                    </div>
                    <div className="grid grid-cols-2 gap-x-2">
                      <p item className="font-bold text-lg max-lg:text-md">
                        Name
                      </p>
                      <CustomTextField
                        variant="standard"
                        type="text"
                        //  readOnly
                        multiline
                        maxRows={10}
                        value={revokeUserData?.user?.name}
                        readOnly
                      />
                    </div>
                    <div className="grid grid-cols-2 gap-x-2">
                      <p item className="font-bold text-lg max-lg:text-md">
                        Factory code
                      </p>
                      <CustomTextField
                        variant="standard"
                        type="text"
                        readOnly
                        value={revokeUserData?.factoryCode}
                      />
                    </div>
                    <div className="grid grid-cols-2 gap-x-2">
                      <p item className="font-bold text-lg max-lg:text-md">
                        User name
                      </p>
                      <CustomTextField
                        variant="standard"
                        type="text"
                        readOnly
                        value={revokeUserData?.employeeId}
                      />
                    </div>

                    <div className="grid grid-cols-2 gap-x-2">
                      <p item className="font-bold text-lg max-lg:text-md">
                        Password
                      </p>

                      <CustomTextField
                        variant="standard"
                        type="text"
                        readOnly
                        value={revokeUserData?.user?.password}
                      />
                    </div>
                  </div>
                </div>
                <div className="my-5">
                  <Divider
                    variant="middle"
                    sx={{
                      bgcolor: "#333B7A",
                    }}
                  />
                </div>
              </div>
            );
          })}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default RemoveAccessModal;
