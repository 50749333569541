import axios from "axios";
import {
  getLocalStorageItem,
  localStorageKeys,
  getPathnameLocalStorageItem,
} from "hooks";
import { refreshTokenHandler } from "./refreshTokenHandler";
// import { RefreshAccessToken, handleRefreshAuthtokens } from "queries/Auth";
// import { routes } from "routes/RouteConstants";

const AxiosInstance = axios.create({
  baseURL: "https://api.workerdiaries.in/",
  headers: {
    "content-type": "application/json",
    "x-api-key": "web001122",
  },
});

const handleLogoutRedirectUser = () => {
  localStorage.removeItem(localStorageKeys.AUTH_TOKEN);
  localStorage.removeItem(localStorageKeys.REFRESH_TOKEN);
  localStorage.removeItem(localStorageKeys.USER_ROLE);
  window.location.href = "/";
};

AxiosInstance.interceptors.request.use(async (config) => {
  const authToken = await getLocalStorageItem(localStorageKeys.AUTH_TOKEN);
  if (authToken) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${authToken}`,
    };
  }
  if (config.language) {
    config.headers["language"] = config.language;
  }
  if (config && config.data && config.method === "get") {
    config.url = config.url + getPathnameLocalStorageItem();
    config.data = { ...config.data };
  }
  return config;
});

let isRefreshing = false;

AxiosInstance.interceptors.response.use(
  (response) => response.data,

  async (error) => {
    const originalRequest = error.config;

    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      if (isRefreshing) {
        return Promise.reject(error);
      }
      isRefreshing = true;
      try {
        const refreshToken = await getLocalStorageItem("REFRESH_TOKEN");
        const newData = await refreshTokenHandler(refreshToken);
        if (newData) {
          localStorage.clear();
          localStorage.setItem("AUTH_TOKEN", newData.data.tokens.accessToken);
          localStorage.setItem(
            "REFRESH_TOKEN",
            newData.data.tokens.refreshToken
          );
          localStorage.setItem("USER_DETAIL", newData.data.user);
          isRefreshing = false;
          originalRequest.headers.Authorization = `Bearer ${newData.data.tokens.accessToken}`;
          return await AxiosInstance(originalRequest);
        }
      } catch (error) {
        isRefreshing = false;
        handleLogoutRedirectUser();
        return Promise.reject(error);
      }
    }

    if (
      error?.response?.status === 401 &&
      !(await getLocalStorageItem(localStorageKeys.AUTH_TOKEN))
    ) {
      handleLogoutRedirectUser();
    }

    return Promise.reject(error.response.data);
  }
);

export default AxiosInstance;
