import React, { useMemo } from "react";
import companyLogo from "./calendar.png";
import HomeLogo from "assets/Icons/HomeLogo.svg";
import LogOutLogo from "assets/Icons/LogOutLogo.svg";
import { getLocalStorageItem } from "hooks";
import { useLocation, useNavigate } from "react-router";
import { Button, Typography } from "@mui/material";
import { pageDetails, routeKey, routes } from "routes/RouteConstants";
import { useLogout } from "pages/Dashboard/dashboardQueries";
const TopNav = ({ isLogin = true }) => {
  const authToken = getLocalStorageItem("AUTH_TOKEN");
  const location = useLocation();

  const { pathname } = useLocation();

  const currentPageDetails = useMemo(() => routes[routeKey[pathname]]);

  const navigate = useNavigate();

  const handleClickAdminPortal = () => {
    navigate("/admin-portal");
  };

  const { mutate: logout } = useLogout();
  const handleClickLogOut = () => {
    logout();
  };
  return (
    <div className="header w-[95%] mx-auto">
      {Boolean(isLogin) && location.pathname !== "/" ? (
        <div className="flex items-center py-6 justify-between gap-5">
          <>
            <div className="w-[50%] flex justify-between items-center">
              <div className="flex items-center justify-start gap-2 ">
                <div className="bg-white rounded-xl p-[2px] flex items-center justify-center">
                  <img
                    src={companyLogo}
                    alt="logo"
                    className="h-[40px] w-[40px] hover:cursor-pointer"
                    onClick={() => handleClickAdminPortal()}
                  />
                </div>

                <Typography
                  fontWeight={600}
                  color="white.logo"
                  fontFamily={"Poppins"}
                  variant="h5"
                  letterSpacing="-0.64px"
                  className="text-center max-lg:hidden hover:cursor-pointer"
                  onClick={() => handleClickAdminPortal()}
                >
                  Worker Financial Diary
                </Typography>
              </div>
              <Typography
                fontWeight={500}
                color="#FFFFFF"
                variant="h5"
                className="text-center translate-x-[50%]"
              >
                {currentPageDetails?.title}
              </Typography>
            </div>
            <Button
              variant="standard"
              startIcon={<img src={LogOutLogo} alt="LogOut Logo" />}
              onClick={handleClickLogOut}
              className="capitalize font-semibold flex justify-end  rounded-lg  py-2 px-4 text-white border-white border-solid border-[1px] "
            >
              Logout
            </Button>
          </>
        </div>
      ) : (
        <div className="flex items-center py-6 justify-start gap-2">
          <div className="bg-white rounded-xl p-[2px] flex items-center justify-center">
            <img src={companyLogo} alt="logo" className="h-[40px] w-[40px]" />{" "}
          </div>
          <Typography
            fontWeight={600}
            color="white.logo"
            fontFamily={"Poppins"}
            variant="h5"
            letterSpacing="-0.64px"
            className={`text-center hover:cursor-pointer`}
            onClick={() => handleClickAdminPortal()}
          >
            Worker Financial Diary
          </Typography>
        </div>
      )}
    </div>
  );
};

export default TopNav;
