import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Label,
} from "recharts";
import { makeFirstLetterUppercase } from "utils/dateFunctions";

export default function AreaGraph({
  data,
  xdatakey,
  ydatakey,
  bgColor,
  strokeColor,
  dxY = -40,
}) {
  return (
    <ResponsiveContainer width={"100%"} height={200}>
      <AreaChart width={"100%"} height={200} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis fontSize={10} dataKey={xdatakey}>
          <Label
            fontSize={14}
            value={makeFirstLetterUppercase(xdatakey)}
            offset={10}
            position="bottom"
          />
        </XAxis>
        <YAxis fontSize={10}>
          <Label
            fontSize={14}
            value={makeFirstLetterUppercase(ydatakey)}
            angle={-90}
            dx={dxY}
            position={"middle"}
            textAnchor="middle"
          />
        </YAxis>
        <Tooltip />
        <Area
          dot
          type="linear"
          dataKey={ydatakey}
          stroke={strokeColor || "#8884d8"}
          fill={bgColor || "#8884d8"}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}
