import GiftIcon from "assets/Images/statistics/gifts.png";
import BarAndPieChartContainer from "components/StatisticsCutomComponents/BarAndPieChartContainer";
import apiStatsKey from "constants/apiKeys";
import useControlParams from "hooks/useControlParams";
import { useGetStats } from "pages/Dashboard/dashboardQueries";
import { getChangeKey } from "./InflowTransactions";
import paramsKey from "constants/paramsKey";

export default function GiftsOrTransfersSection() {
  const { availableParams, getParamValue } = useControlParams();
  const { data: TransferData } = useGetStats(
    availableParams(),
    apiStatsKey.transfer
  );

  const changedKey = getChangeKey(getParamValue(paramsKey.filterKey));

  return (
    <BarAndPieChartContainer
      graphHeadLine={{ icon: GiftIcon, heading: "Gifts/Transfers" }}
      barGraphData={{
        bgcolor: "#4C96A6",
        data:
          changedKey === "hour"
            ? TransferData?.data?.bars?.[0]?.transactions
            : TransferData?.data?.bars,
        xdatakey: changedKey,
        ydatakey: "amount",
      }}
      pieGraphData={{
        colorOfPiechart: "#4C96A6",
        data: TransferData?.data?.pie || [],
        datakey: "percentages",
        namekey: "name",
      }}
    />
  );
}
