import { useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import CrossIcon from "../../assets/Icons/CrossIcon.svg";
import SaveIcon from "../../assets/Icons/SaveIcon";
import {
  categoryOptionsData,
  subCategoryOptionsData,
  paymentsMode,
} from "./optionData.js";
import {
  Box,
  Divider,
  Stack,
  Grid,
  TextField,
  Typography,
  MenuItem,
  Button,
} from "@mui/material";
import {
  useEditTransaction,
  useGetPaymentSource,
} from "pages/Dashboard/dashboardQueries";
import { getLocalStorageItem } from "hooks";

const EditTransactionFormSchema = yup.object().shape({
  employeeId: yup.string().required("Employee ID is required field."),
  transactionDate: yup
    .date()
    .transform((currentValue, originalValue) => {
      return originalValue.trim() === "" ? undefined : currentValue;
    })
    .required("Transaction Date is a required field.")
    .test(
      "is-valid-date",
      "Transaction Date must be a valid date.",
      function (value) {
        if (value === undefined) return true;
        return !isNaN(value.getTime());
      }
    ),
  type: yup.string().required("Category is a required field."),
  source: yup.string().when("type", (type, schema) => {
    if (type === "NONE") return schema;
    return schema.required("Sub-Category is a required field.");
  }),
  description: yup.string().when("type", (type, schema) => {
    if (type === "NONE") return schema;
    return schema.required("Source is a required field.");
  }),
  amount: yup
    .number()
    .transform((value, originalValue) =>
      originalValue.trim() === "" ? null : value
    )
    .when("type", (type, schema) => {
      if (type === "NONE") return schema.nullable(true).notRequired();
      return schema
        .typeError("Amount must be a number.")
        .min(1, "Amount must be greater than or equal to 1.")
        .required("Amount is a required field.");
    }),
  mode: yup
    .string()
    .transform((value, originalValue) =>
      originalValue.trim() === "" ? null : value
    )
    .when("type", (type, schema) => {
      if (type === "NONE") return schema.nullable(true).notRequired();
      return schema.required("Mode of payment is a required field.");
    }),
  reason: yup.string().when("type", (type, schema) => {
    if (type === "NONE") return schema;
    return schema;
  }),
});

const modeOfPayment = [
  "Cash",
  "Debit Card",
  "UPI (Google pay/Phone Pe)",
  "Mobile Wallets",
  "Credit (at the shop)",
  "Credit Card",
  "Bank Transfer",
  "Net Banking",
  "Any other",
];

const EditTransactionForm = ({ closeDialog, transactionData }) => {
  const [paymentSource, setPaymentSource] = useState(transactionData.type);

  const user = getLocalStorageItem("USER_ROLE");
  const { data: paymentSourceData } = useGetPaymentSource(
    paymentSource || transactionData.type
  );
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(EditTransactionFormSchema) });
  const watchSelectSource = watch("source");

  const { mutate } = useEditTransaction();
  const defaultDate = new Date(transactionData.transactionDate);
  const formattedDefaultDateStr = `${defaultDate.getFullYear()}-${(
    defaultDate.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${defaultDate.getDate().toString().padStart(2, "0")}`;

  const onSubmit = (data) => {
    let sourceId = 0;
    paymentSourceData.data.forEach((source) => {
      if (source.name === data.source) {
        sourceId = source.id;
      }
    });
    console.log(data, sourceId);
    mutate(
      {
        ...data,
        source: sourceId,
        transactionDate: new Date(data.transactionDate).toISOString(),
      },
      { onSuccess: () => closeDialog() }
    );
  };

  const star = " * ";
  const today = new Date();
  const todayString = today.toISOString().split("T")[0];
  const fourteenDaysAgo = new Date();
  const fortyDaysAgo = new Date();
  fourteenDaysAgo.setDate(today.getDate() - 13);
  fortyDaysAgo.setDate(today.getDate() - 40);
  const fourteenDaysAgoString = fourteenDaysAgo.toISOString().split("T")[0];
  const fortyDaysAgoString = fortyDaysAgo.toISOString().split("T")[0];

  if (!paymentSourceData) return <></>;
  return (
    <>
      <div className="w-full h-full bg-white rounded-lg">
        <div className="flex justify-between px-[35px] pt-[19px] pb-[15px] ">
          <Typography className="text-center text-[22px] font-semibold tracking-tighter text-[#070707]">
            Edit Transaction
          </Typography>
          <div
            className="w-[24px] h-[24px] hover:cursor-pointer"
            onClick={closeDialog}
          >
            <img src={CrossIcon} className="w-[24px] h-[24px]" />
          </div>
        </div>
        <Divider />
        <div className="">
          <form id="login-form" onSubmit={handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="24px" className="w-full py-3">
              <Box className="grid grid-cols-2 px-10 items-center">
                <label
                  htmlFor=""
                  className="text-[#1B2559] text-[16px]  font-[700] leading-[100%]"
                >
                  Transaction ID :
                </label>
                <TextField
                  value={transactionData?.id}
                  InputProps={{
                    readOnly: true,
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      height: "50px",
                      borderRadius: "10px",
                    },
                  }}
                  {...register("txId")}
                  type="text"
                  className="rounded-lg"
                />
              </Box>
              <Box className="grid grid-cols-2 px-10 items-center">
                <label
                  htmlFor=""
                  className="text-[#1B2559] text-[16px]  font-[700] leading-[100%]"
                >
                  Employee ID :
                </label>
                <TextField
                  value={transactionData?.employeeId}
                  InputProps={{
                    readOnly: true,
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      height: "50px",
                      borderRadius: "10px",
                    },
                  }}
                  {...register("employeeId")}
                  type="text"
                  className="rounded-lg"
                />
              </Box>
              <div className="grid grid-cols-2 px-10 items-center">
                <label
                  htmlFor=""
                  className="text-[#1B2559] text-[16px]  font-[700] leading-[100%]"
                >
                  Transaction Date<span className="text-red-500">{star}</span>:
                </label>
                <input
                  max={todayString}
                  min={
                    user === "SUPER_ADMIN" || user === "ADMIN"
                      ? fortyDaysAgoString
                      : fourteenDaysAgoString
                  }
                  {...register("transactionDate")}
                  defaultValue={formattedDefaultDateStr}
                  type="date"
                  className="rounded-lg px-3  h-[50px] border border-[#1B2559]"
                  error={Boolean(errors?.transactionDate?.message)}
                />
              </div>
              <Box className="grid grid-cols-2 px-10 items-center">
                <label
                  htmlFor=""
                  className="text-[#1B2559] text-[16px]  font-[700] leading-[100%]"
                >
                  Category<span className="text-red-500">{star}</span>:
                </label>
                <TextField
                  id="outlined-select-currency"
                  select
                  defaultValue={paymentSource}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      height: "50px",

                      borderRadius: "10px",
                    },
                  }}
                  className="rounded-lg"
                  {...register("type")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={Boolean(errors?.type?.message)}
                  helperText={errors?.type?.message}
                >
                  <MenuItem
                    value="CREDIT"
                    onClick={() => setPaymentSource("CREDIT")}
                  >
                    INFLOW
                  </MenuItem>
                  <MenuItem
                    onClick={() => setPaymentSource("DEBIT")}
                    value="DEBIT"
                  >
                    OUTFLOW
                  </MenuItem>
                  <MenuItem
                    onClick={() => setPaymentSource("NONE")}
                    value="NONE"
                  >
                    NO TRANSACTION
                  </MenuItem>
                </TextField>
              </Box>
              {paymentSource !== "NONE" && (
                <>
                  <Box className="grid grid-cols-2 px-10 items-center">
                    <label
                      htmlFor=""
                      className="text-[#1B2559] text-[16px]  font-[700] leading-[100%]"
                    >
                      Sub-category<span className="text-red-500">{star}</span>:
                    </label>
                    <TextField
                      id="source"
                      select
                      disabled={paymentSource === "NONE" ? true : false}
                      defaultValue={
                        paymentSourceData?.data?.find(
                          (txs) => txs?.name === transactionData?.subCategory
                        )?.name || null
                      }
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          height: "50px",
                          borderRadius: "10px",
                        },
                      }}
                      {...register("source")}
                      className="rounded-lg"
                      error={Boolean(errors?.source?.message)}
                      helperText={errors?.source?.message}
                    >
                      {paymentSourceData?.data?.map((option) => (
                        <MenuItem key={option.name} value={option.name}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>
                  <Box className="grid grid-cols-2 px-10 items-center">
                    <label
                      htmlFor=""
                      className="text-[#1B2559] text-[16px]  font-[700] leading-[100%]"
                    >
                      Source<span className="text-red-500">{star}</span>:
                    </label>
                    <TextField
                      id="outlined-select-currency"
                      select
                      defaultValue={transactionData?.source}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          height: "50px",
                          borderRadius: "10px",
                        },
                      }}
                      className="rounded-lg"
                      {...register("description")}
                      error={Boolean(errors?.description?.message)}
                      helperText={errors?.description?.message}
                      disabled={paymentSource === "NONE" ? true : false}
                    >
                      {paymentSourceData?.data
                        ?.find(
                          (data) =>
                            data.name ===
                            (watchSelectSource || transactionData.subCategory)
                        )
                        ?.category?.map((option) => {
                          const keys = Object.keys(option);
                          return (
                            <MenuItem key={keys[0]} value={keys[0]}>
                              {keys[0]}
                            </MenuItem>
                          );
                        }) || null}
                    </TextField>
                  </Box>

                  <Box className="grid grid-cols-2 px-10 items-center">
                    <label
                      htmlFor=""
                      className="text-[#1B2559] text-[16px]  font-[700] leading-[100%]"
                    >
                      Amount<span className="text-red-500">{star}</span>:
                    </label>
                    <TextField
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          height: "50px",
                          borderRadius: "10px",
                        },
                      }}
                      {...register("amount")}
                      defaultValue={transactionData?.amount}
                      type="number"
                      className="rounded-lg"
                      inputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                        onFocus: (e) => {
                          e.target.addEventListener(
                            "wheel",
                            function (e) {
                              e.preventDefault();
                            },
                            { passive: false }
                          );
                        },
                      }}
                      error={Boolean(errors?.amount?.message)}
                      helperText={errors?.amount?.message}
                    />
                  </Box>
                  <Box className="grid grid-cols-2 px-10 items-center">
                    <label
                      htmlFor=""
                      className="text-[#1B2559] text-[16px]  font-[700] leading-[100%]"
                    >
                      Mode of Payment
                      <span className="text-red-500">{star}</span>:
                    </label>
                    <TextField
                      id="outlined-select-currency"
                      select
                      defaultValue={transactionData.mode}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          height: "50px",
                          borderRadius: "10px",
                        },
                      }}
                      {...register("mode")}
                      className="rounded-lg"
                      error={Boolean(
                        errors?.mode?.message && "Payment mode is required"
                      )}
                      helperText={
                        errors?.mode?.message && "Payment mode is required"
                      }
                    >
                      {modeOfPayment.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>
                </>
              )}
              {paymentSource === "NONE" && transactionData?.reason && (
                <Box className="grid grid-cols-2 px-10 items-center">
                  <label
                    htmlFor=""
                    className="text-[#1B2559] text-[16px]  font-[700] leading-[100%]"
                  >
                    Reason<span className="text-red-500">{star}</span>:
                  </label>
                  <TextField
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        height: "50px",

                        borderRadius: "10px",
                      },
                    }}
                    defaultValue={transactionData?.reason}
                    className="rounded-lg"
                    {...register("reason")}
                    error={Boolean(errors?.reason?.message)}
                    helperText={errors?.reason?.message}
                  />
                </Box>
              )}
            </Stack>
            <Divider />
            <Box className="flex justify-center py-[24px]">
              <Button
                variant="contained"
                className="capitalize h-[48px] w-[164px]"
                startIcon={<SaveIcon />}
                type="submit"
                onClick={handleSubmit(onSubmit)}
              >
                <p>Save</p>
              </Button>
            </Box>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditTransactionForm;
