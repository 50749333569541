import BarAndPieChartContainer from "components/StatisticsCutomComponents/BarAndPieChartContainer";
import FoodIcon from "assets/Images/statistics/food.png";
import useControlParams from "hooks/useControlParams";
import { useGetStats } from "pages/Dashboard/dashboardQueries";
import apiStatsKey from "constants/apiKeys";
import { getChangeKey } from "./InflowTransactions";
import paramsKey from "constants/paramsKey";

export default function FoodExpendituresSection() {
  const { availableParams, getParamValue } = useControlParams();
  const { data: FoodData } = useGetStats(
    availableParams(),
    apiStatsKey.foodExpenditures
  );

  const changedKey = getChangeKey(getParamValue(paramsKey.filterKey));
  return (
    <BarAndPieChartContainer
      graphHeadLine={{ icon: FoodIcon, heading: "Food Expenditures" }}
      barGraphData={{
        bgcolor: "#7E65C3",
        data:
          changedKey === "hour" // getting different response structur from BE on daily filters
            ? FoodData?.data?.bars?.[0]?.transactions
            : FoodData?.data?.bars,
        xdatakey: changedKey,
        ydatakey: "amount",
      }}
      pieGraphData={{
        colorOfPiechart: "#7E65C3",
        data: FoodData?.data?.pie || [],
        datakey: "percentages",
        namekey: "name",
      }}
      dxY={-40}
    />
  );
}
