export default function CustomDownloadButton({ children, onClick }) {
  return (
    <div
      tabIndex={0}
      onClick={onClick}
      className="bg-gradient-to-b from-[#A1A4EE] to-[#3D3E78] rounded-lg py-2 px-4 cursor-pointer"
    >
      {children}
    </div>
  );
}
