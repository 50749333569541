import AreaGraph from "./AreaGraph";
import GraphCardWrapper from "./GraphCardWrapper";

export default function GraphContainer({
  graphHeadLine,
  firstgraph,
  secondGraph,
  dropdownOptions,
  heading,
  handleSelect,
  valueofDropdown,
}) {
  return (
    <GraphCardWrapper
      graphHeadLine={graphHeadLine}
      dropdownOptions={dropdownOptions}
      heading={heading}
      handleSelect={handleSelect}
      valueofDropdown={valueofDropdown}
    >
      <div className="flex gap-9 max-[900px]:flex-col">
        <div className="flex-1 p-10">
          <AreaGraph
            dxY={-70}
            data={firstgraph?.data}
            bgColor={firstgraph?.bgColor || "#3EA80540"}
            xdatakey={firstgraph?.xdatakey}
            ydatakey={firstgraph?.ydatakey}
          />
        </div>
        <div className="flex-1 p-10">
          <AreaGraph
            data={secondGraph?.data}
            bgColor={secondGraph?.bgColor || "#3EA80540"}
            xdatakey={secondGraph?.xdatakey}
            ydatakey={secondGraph?.ydatakey}
          />
        </div>
      </div>
    </GraphCardWrapper>
  );
}
