import CardWrapper from "./CardWrapper";
import CustomDropdown from "./CustomDropdown";

export default function GraphCardWrapper({
  children,
  graphHeadLine,
  heading,
  handleSelect,
  dropdownOptions,
  valueofDropdown,
}) {
  return (
    <CardWrapper>
      <div className="p-[40px] py-[20px]">
        <div className="flex items-center justify-between mb-7">
          <div className="flex items-center gap-2">
            <img
              src={graphHeadLine?.icon}
              alt="icon"
              className="w-[28px] h-[28px]"
            />
            <h3>{graphHeadLine?.heading}</h3>
          </div>
          {heading && (
            <CustomDropdown
              handleSelect={handleSelect}
              heading={heading}
              options={dropdownOptions}
              value={valueofDropdown}
            />
          )}
        </div>
        {children}
      </div>
    </CardWrapper>
  );
}
