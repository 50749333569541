import { format } from "date-fns";

export const weekMiliSecond = 604800000;

export const dailyMiliSecond = 24 * 60 * 60 * 1000;

export const monthMiliSecond = getDaysInCurrentMonth() * dailyMiliSecond;

export function convertMiliToDate(milisecond) {
  return new Date(milisecond);
}

export function getDateObject(dateString) {
  const arr = dateString?.split("-");
  const year = parseInt(arr[0]);
  const month = parseInt(arr[1]) - 1;
  const date = parseInt(arr[2]);
  return new Date(year, month, date);
}

export function dateFormator(dateString, formatString) {
  return format(getDateObject(dateString), formatString);
}

export function getMonth(dateString) {
  return getDateObject(dateString).getMonth() + 1;
}

export function getMiliSeconds(dateString) {
  return getDateObject(dateString).getTime();
}

export function getMonthMilliSecond() {}

export function getStartDate(value) {
  let currentDay;
  if (value === "Weekly") {
    currentDay = new Date().getDay();
  } else if (value === "Monthly") {
    currentDay = new Date().getDate();
  } else {
    currentDay = 1;
  }
  return format(
    new Date(Date.now() - (currentDay - 1) * dailyMiliSecond),
    "yyyy-MM-dd"
  );
}

export function getDaysInCurrentMonth() {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth();

  // Create a date object for the first day of the next month
  const nextMonth = new Date(year, month + 1, 1);

  // Subtract one day from the first day of the next month
  nextMonth.setDate(nextMonth.getDate() - 1);

  // Return the day of the last day of the current month
  return nextMonth.getDate();
}

export function getLastDate(value) {
  let currentDay;
  let totalDays;
  if (value === "Weekly") {
    currentDay = new Date().getDay();
    totalDays = 6;
  } else if (value === "Monthly") {
    currentDay = new Date().getDate();
    totalDays = getDaysInCurrentMonth() - 1;
  } else {
    currentDay = 1;
    totalDays = 0;
  }
  return format(
    new Date(
      Date.now() -
        (currentDay - 1) * dailyMiliSecond +
        totalDays * dailyMiliSecond
    ),
    "yyyy-MM-dd"
  );
}

export function getDaysInMonth(month, year) {
  // Month is 1-based (1 for January, 2 for February, etc.)
  // JavaScript Date object expects 0-based month (0 for January, 1 for February, etc.)
  return new Date(year, month, 0).getDate();
}

export function makeFirstLetterUppercase(str) {
  return str[0].toUpperCase() + str?.slice(1);
}
