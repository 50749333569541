import { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  IconButton,
  Divider,
  Button,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { CustomTextField } from "components";
import QRCode from "react-qr-code";
import { BsCheck2Circle } from "react-icons/bs";
//import { GrantAccessUserQuery } from './ManageEmployeesQueries';
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DefaultImage from "assets/Images/defaultImage.webp";
import { useProvideAccess } from "./manageEmployeeQueries";

const ProvideAccessModal = (props) => {
  const {
    open,
    handleClose,
    grantInactiveUser: selectedUsers,
    setInactiveGrantUser,
    selectedWaveEnuIds,
  } = props;
  // states
  console.log(selectedUsers);
  const [grantEmployee, setGrantEmployee] = useState([]);
  const [commonEmployeeData, setCommonEmployeeData] = useState([]);
  const [showPhotoAndQr, setShowPhotoAndQr] = useState(false);
  const [grantInactiveUser, setGrantInactiveUser] = useState([]);
  // // queries
  // const { mutate: grantAccessUser } = GrantAccessUserQuery();
  const handleDoneClick = () => {
    setShowPhotoAndQr(false);
    setGrantEmployee([]);
    handleClose();
  };
  const { mutate } = useProvideAccess();
  const handleGrantAccess = () => {
    setShowPhotoAndQr(true);
    setGrantEmployee(commonEmployeeData);
    mutate({
      ids: grantInactiveUser?.map((ids) => Number(ids.id)),
      enumeratorId: selectedWaveEnuIds.enumeratorId,
      waveId: selectedWaveEnuIds.waveId,
    });
  };
  const handlePdf = () => {
    window.print();
  };

  const handleRemoveMe = (index) => {
    if (index > -1) {
      grantInactiveUser.splice(index, 1);
      setInactiveGrantUser([...grantInactiveUser]);
    }
  };
  useEffect(() => {
    if (selectedUsers.length > 0) {
      setGrantInactiveUser(
        selectedUsers.filter((user) => user.accessibilityStatus === "NO_LOGIN")
      );
    }
  }, [selectedUsers]);

  useEffect(() => {
    if (grantEmployee?.length <= 0) {
      const tempArr = grantInactiveUser?.map((el) => el.original);
      setCommonEmployeeData(tempArr);
    } else {
      setCommonEmployeeData(grantEmployee);
    }
  }, [grantInactiveUser, grantEmployee]);

  useEffect(() => {
    if (open) {
      setGrantEmployee([]);
    }
  }, [open]);
  return (
    <div>
      <Dialog
        open={open}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: showPhotoAndQr ? "1050px" : "800px", // Set your width here
            },
          },
        }}
      >
        <DialogTitle className="font-semibold" color="primary">
          <Grid container xs={12} className="flex justify-between">
            <Grid item className="font-semibold leading-[150%] text-[26px]">
              Employee Data
            </Grid>
            <div className="flex justify-between gap-4">
              {!grantEmployee?.length <= 0 ? (
                <Button
                  variant="standard"
                  onClick={handlePdf}
                  startIcon={<FileDownloadOutlinedIcon />}
                  className="leading-[150%]  capitalize font-semibold text-[18px] rounded-lg px-3 py-2 text-white bg-gradient-to-b from-[#659ECD] to-[#1F305F]"
                >
                  Download
                </Button>
              ) : (
                <Button
                  variant="standard"
                  startIcon={<BsCheck2Circle />}
                  disabled={grantInactiveUser.length < 1 ? true : false}
                  onClick={handleGrantAccess}
                  className="leading-[150%] capitalize font-semibold text-[18px] px-3 rounded-lg  py-2 text-white bg-gradient-to-b from-[#659ECD] to-[#1F305F]"
                >
                  Provide Access
                </Button>
              )}
              {!grantEmployee?.length <= 0 ? (
                <Button
                  variant="outlined"
                  onClick={() => {
                    setShowPhotoAndQr(false);
                    handleClose();
                  }}
                  className="leading-[150%] font-semibold text-[18px] px-8  capitalize shadow-md rounded-lg w-[149px] h-[51px] text-black"
                >
                  Done
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  onClick={() => {
                    setShowPhotoAndQr(false);
                    handleClose();
                  }}
                  className="leading-[150%] font-semibold text-[18px] px-8  capitalize shadow-md rounded-lg w-[149px] h-[51px] text-black"
                >
                  Cancel
                </Button>
              )}
            </div>
          </Grid>
        </DialogTitle>
        <Divider
          variant="middle"
          sx={{
            bgcolor: "#333B7A",
          }}
        />
        <DialogContent>
          {grantInactiveUser.length > 0 ? (
            grantInactiveUser.map((grantUserData, index) => {
              return (
                <div key={index} className="w-full">
                  {grantEmployee?.length <= 0 && (
                    <div key={index} className="flex  justify-end my-2 ">
                      <IconButton
                        aria-label="close"
                        onClick={() => handleRemoveMe(index)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                  )}

                  <div
                    className={`grid ${
                      showPhotoAndQr && grantUserData?.qr
                        ? "grid-cols-2"
                        : "grid-cols-1"
                    } gap-x-5 pt-2`}
                  >
                    <div className="flex items-center  justify-center">
                      {
                        //grantUserData?.qrToken && (
                        showPhotoAndQr && grantUserData?.qr && (
                          <QRCode
                            className="aspect-square"
                            value={grantUserData?.qr || ""}
                          />
                        )
                      }
                    </div>

                    <div className="w-full flex text-sm  flex-col justify-between">
                      <div className="grid grid-cols-2 gap-x-2">
                        <p className="font-bold text-lg max-lg:text-md">
                          Employee Id
                        </p>
                        <CustomTextField
                          variant="standard"
                          type="text"
                          readOnly
                          value={grantUserData?.employeeId}
                        />
                      </div>

                      <div className="grid grid-cols-2 gap-x-2">
                        <p className="font-bold text-lg max-lg:text-md">Name</p>
                        <CustomTextField
                          variant="standard"
                          type="text"
                          readOnly
                          multiline
                          maxRows={10}
                          value={grantUserData?.user?.name}
                        />
                      </div>

                      <div className="grid grid-cols-2 gap-x-2">
                        <p className="font-bold text-lg max-lg:text-md">
                          Factory code
                        </p>
                        <CustomTextField
                          variant="standard"
                          type="text"
                          readOnly
                          value={grantUserData?.factoryCode}
                        />
                      </div>
                      <div className="grid grid-cols-2 gap-x-2">
                        <p className="font-bold text-lg max-lg:text-md">
                          User name
                        </p>
                        <CustomTextField
                          variant="standard"
                          type="text"
                          readOnly
                          value={grantUserData?.employeeId}
                        />
                      </div>
                      <div className="grid grid-cols-2 gap-x-2">
                        <p className="font-bold text-lg max-lg:text-md">
                          Password
                        </p>

                        <CustomTextField
                          readOnly
                          variant="standard"
                          type="text"
                          value={grantUserData?.user?.password}
                        />
                      </div>
                    </div>
                  </div>
                  <div key={index} className="mt-10">
                    <Divider
                      variant="middle"
                      sx={{
                        bgcolor: "#333B7A",
                      }}
                    />
                  </div>
                </div>
              );
            })
          ) : (
            <div className="w-full p-5">
              <h1 className="text-xl font-medium">
                Please Select Inactive Users
              </h1>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ProvideAccessModal;
