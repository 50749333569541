import useControlParams from "hooks/useControlParams";
import CustomDropdown from "./CustomDropdown";
import Tabs from "./Tabs";
import CustomDownloadButton from "./CustomDownloadButton";
import { DownloadIcon } from "./Icons";
import CustomMonthDayWeekSelector from "./CustomMonthDayWeekSelector";
import paramsKey from "constants/paramsKey";
import { format } from "date-fns";
import { getLastDate, getStartDate } from "utils/dateFunctions";

export default function StatisticsNavbar() {
  const { getParamValue, navigateWithParams, navigateWithMultipleParams } =
    useControlParams();

  return (
    <div className="flex justify-between flex-wrap gap-2">
      <div className="flex items-stretch gap-2">
        <CustomMonthDayWeekSelector />
        <Tabs
          activeOption={getParamValue(paramsKey.filterKey) || ""}
          options={["All", "Weekly", "Monthly", "Daily"]}
          onClick={(value) => {
            const startDate = getStartDate(value);
            const lastDate = getLastDate(value);
            const obj = {};
            obj[paramsKey.filterKey] = value;
            if (value === "Monthly") {
              obj[paramsKey.currentMonth] = new Date().getMonth();
            } else {
              obj[paramsKey.currentMonth] = null;
            }

            if (value === "All") {
              obj[paramsKey.startDateKey] = "2023-10-01";
              obj[paramsKey.endDateKey] = format(new Date(), "yyyy-MM-dd");
            } else {
              obj[paramsKey.startDateKey] = startDate;
              obj[paramsKey.endDateKey] = lastDate;
            }

            navigateWithMultipleParams(obj);
          }}
        />
        <CustomDropdown
          heading={"Gender"}
          value={getParamValue(paramsKey.genderKey) || ""}
          options={["Male", "Female"]}
          handleSelect={(value) => {
            navigateWithParams(paramsKey.genderKey, value);
          }}
        />
        <CustomDropdown
          heading={"Factory"}
          value={getParamValue(paramsKey.factoryKey) || ""}
          options={["37M", "49M", "M21", "M03","K11","K12"]}
          handleSelect={(value) => {
            navigateWithParams(paramsKey.factoryKey, value);
          }}
        />
      </div>
      <div className="flex items-center gap-2">
        <CustomDropdown
          width={"80px"}
          backgroundPositionX={"50px"}
          heading={"Waves"}
          value={getParamValue(paramsKey.waveKey) || ""}
          options={["1", "2", "3", "4"]}
          handleSelect={(value) => {
            navigateWithParams(paramsKey.waveKey, value);
          }}
        />
        <CustomDownloadButton
          onClick={() => {
            window.print();
          }}
        >
          <div className="flex items-center gap-2">
            <DownloadIcon />
            <span className="text-white text-xl">Download</span>
          </div>
        </CustomDownloadButton>
      </div>
    </div>
  );
}
