import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useToast } from "hooks";
import { appendQueryParams } from "service/appendQueryParams";
import AxiosInstance from "service/axiosInstance";

const getAllUsers = (filters) => {
  const method = "GET";
  let url = appendQueryParams(`api/v1/manage-worker/all-workers`, {
    ...filters,
  });
  return AxiosInstance({
    method,
    url,
    filters,
  });
};

export const useGetAllUsers = (filters) => {
  return useQuery({
    queryKey: ["all-users-manage-employees", filters],
    queryFn: () => getAllUsers(filters),
    enabled: !!filters,
    keepPreviousData: true,
  });
};

const getCurrentUsers = (filters) => {
  const method = "GET";
  let url = appendQueryParams(`api/v1/manage-worker/current-workers`, {
    ...filters,
  });
  return AxiosInstance({
    method,
    url,
    filters,
  });
};

export const useGetCurrentUsers = (filters) => {
  return useQuery({
    queryKey: ["current-users-manage-employees", filters],
    queryFn: () => getCurrentUsers(filters),
    enabled: !!filters,
    keepPreviousData: true,
  });
};
const getStudyUsers = (filters) => {
  const method = "GET";
  let url = appendQueryParams(`api/v1/manage-worker/study-workers`, {
    ...filters,
  });
  return AxiosInstance({
    method,
    url,
    filters,
  });
};

export const useGetStudyUsers = (filters) => {
  return useQuery({
    queryKey: ["study-users-manage-employees", filters],
    queryFn: () => getStudyUsers(filters),
    enabled: !!filters,
    keepPreviousData: true,
  });
};
const getEnumerator = (filters) => {
  const method = "GET";
  let url = appendQueryParams(`api/v1/manage-enumerator/paginate`, {
    ...filters,
  });
  return AxiosInstance({
    method,
    url,
    filters,
  });
};

export const useGetEnumerator = (filters) => {
  return useQuery({
    queryKey: ["enumerator-list", filters],
    queryFn: () => getEnumerator(filters),
    enabled: !!filters,
    keepPreviousData: true,
  });
};

const getWaves = () => {
  const method = "GET";
  let url = `api/v1/wave/true`;
  return AxiosInstance({
    method,
    url,
  });
};

export const useGetWaves = () => {
  return useQuery({
    queryKey: ["waves-list"],
    queryFn: () => getWaves(),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};

const updateWaves = (data) => {
  const { id, state } = data;
  const method = "GET";
  let url = `api/v1/wave/${id}/change-status?status=${state}`;
  return AxiosInstance({
    method,
    url,
  });
};

export const useUpdateWaves = () => {
  const queryClient = useQueryClient();
  const responseToast = useToast();
  return useMutation(updateWaves, {
    onSuccess: (response) => {
      queryClient.invalidateQueries({ queryKey: ["waves-list"] });
      queryClient.invalidateQueries({
        queryKey: ["current-users-manage-employees"],
      });
      queryClient.invalidateQueries({
        queryKey: ["study-users-manage-employees"],
      });
      responseToast.toastSuccess(response.message || "Updated Successfully");
    },
  });
};

const getEnumeratorsIds = () => {
  const method = "GET";
  let url = `api/v1/manage-enumerator/list`;
  return AxiosInstance({
    method,
    url,
  });
};

export const useGetEnumeratorsIds = () => {
  return useQuery({
    queryKey: ["Enumerators-ids"],
    queryFn: () => getEnumeratorsIds(),
    refetchOnMount: true,
  });
};

const provideAccess = (data) => {
  const method = "POST";
  let url = `api/v1/manage-worker/move-to-study`;
  return AxiosInstance({
    method,
    url,
    data,
  });
};

export const useProvideAccess = () => {
  const queryClient = useQueryClient();
  const responseToast = useToast();
  return useMutation(provideAccess, {
    onSuccess: (response) => {
      queryClient.invalidateQueries(["study-users-manage-employees"]);
      queryClient.invalidateQueries(["all-users-manage-employees"]);
      queryClient.invalidateQueries(["current-users-manage-employees"]);
      queryClient.invalidateQueries(["enumerator-list"]);
      responseToast.toastSuccess(
        response.message || "Grant Access Successfully"
      );
    },
  });
};

const removeAccess = (data) => {
  const method = "POST";
  let url = `api/v1/manage-worker/remove-access`;
  return AxiosInstance({
    method,
    url,
    data,
  });
};

export const useRemoveAccess = () => {
  const queryClient = useQueryClient();
  const responseToast = useToast();
  return useMutation(removeAccess, {
    onSuccess: (response) => {
      queryClient.invalidateQueries(["study-users-manage-employees"]);
      queryClient.invalidateQueries(["all-users-manage-employees"]);
      queryClient.invalidateQueries(["enumerator-list"]);
      queryClient.invalidateQueries(["current-users-manage-employees"]);
      responseToast.toastSuccess(
        response.message || "Removed Access Successfully"
      );
    },
  });
};

const addEnumerator = (data) => {
  const method = "POST";
  let url = `api/v1/manage-enumerator`;
  return AxiosInstance({
    method,
    url,
    data,
  });
};

export const useAddEnumerator = () => {
  const queryClient = useQueryClient();
  const responseToast = useToast();
  return useMutation(addEnumerator, {
    onSuccess: (response) => {
      queryClient.invalidateQueries(["enumerator-list"]);
      responseToast.toastSuccess(response.message || "Added Successfully");
    },
  });
};
const updateEnumerator = (enuData) => {
  const { id, ...data } = enuData;
  const method = "PATCH";
  let url = `api/v1/manage-enumerator/${String(id)}`;
  return AxiosInstance({
    method,
    url,
    data,
  });
};

export const useUpdateEnumerator = () => {
  const queryClient = useQueryClient();
  const responseToast = useToast();
  return useMutation(updateEnumerator, {
    onSuccess: (response) => {
      queryClient.invalidateQueries(["enumerator-list"]);
      queryClient.invalidateQueries(["Enumerators-details"]);
      responseToast.toastSuccess(response.message || "Updated Successfully");
    },
  });
};

const getEnumeratorDetails = (id) => {
  const method = "GET";
  let url = `api/v1/manage-enumerator/${id}`;
  return AxiosInstance({
    method,
    url,
  });
};

export const useGetEnumeratorDetails = (id) => {
  return useQuery({
    queryKey: ["Enumerators-details", id],
    queryFn: () => getEnumeratorDetails(id),
    refetchOnWindowFocus: false,
  });
};

const getBulkSearchData = (data) => {
  const method = "POST";
  let url = `api/v1/manage-worker/workers/bulk-search`;
  return AxiosInstance({
    method,
    url,
    data,
  });
};

export const useGetBulkSearchData = (data) => {
  return useMutation(getBulkSearchData);
};

const updateSupervisorState = (data) => {
  const { id, state } = data;
  const method = "PATCH";
  let url = `api/v1/manage-enumerator/${id}/status?status=${state}`;
  return AxiosInstance({
    method,
    url,
  });
};

export const useUpdateSupervisorState = () => {
  const queryClient = useQueryClient();
  const responseToast = useToast();
  return useMutation(updateSupervisorState, {
    onSuccess: (response) => {
      queryClient.invalidateQueries(["Enumerators-details"]);
      queryClient.invalidateQueries(["Enumerators-ids"]);
      queryClient.invalidateQueries(["enumerator-list"]);
      responseToast.toastSuccess(
        response.data.accessibilityStatus === "CAN_LOGIN"
          ? "Supervisor Activated"
          : "Supervisor Inactivated"
      );
    },
  });
};
