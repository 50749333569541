import { useState } from "react";
import "./style.css";
import { useUpdateSupervisorState } from "../manageEmployeeQueries";

export default function SupervisorStateToggler({ active, id }) {
  const [show, setShow] = useState(active);
  const { mutate } = useUpdateSupervisorState();

  function changeHandler() {
    mutate({ state: show === "CAN_LOGIN" ? "NO_LOGIN" : "CAN_LOGIN", id: id });
    setShow(show === "CAN_LOGIN" ? "NO_LOGIN" : "CAN_LOGIN");
  }

  return (
    <label
      className={`${
        show === "CAN_LOGIN" ? "bg-green-500" : "bg-[#d7d7d7]"
      } rounded-2xl relative transition-colors w-[45px] h-5 cursor-pointer`}
    >
      <input
        className="hidden"
        type="checkbox"
        checked={show === "CAN_LOGIN" ? true : false}
        onChange={changeHandler}
      />
      <div
        className={`w-[14px] h-[14px] transition-transform rounded-full absolute top-1/2 left-0 -translate-y-1/2 bg-[#fff] ${
          show === "CAN_LOGIN" ? "translate-x-[200%]" : " translate-x-[10%]"
        }`}
      ></div>
    </label>
  );
}
