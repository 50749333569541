import { useEffect, useState } from "react";
import { snakeCaseToTitleCase } from "utils";
import { usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { useSticky } from "react-table-sticky";
import { FaSortAmountDown, FaSortAmountUp } from "react-icons/fa";
import PenIcon from "../../assets/Icons/PenIcon.svg";
import CrossIcon from "../../assets/Icons/CrossIcon.svg";
import DownloadIcon from "../../assets/Icons/DownloadIcon.svg";
import {
  Button,
  CircularProgress,
  MenuItem,
  Select,
  Typography,
  Stack,
  Box,
  ButtonGroup,
  FormControl,
  InputLabel,
  Grid,
  Dialog,
  Paper,
  Checkbox,
} from "@mui/material";
import TableSearch from "./TableSearch";
import IndeterminateCheckbox from "components/IndeterminateCheckbox";
import { useDebounce } from "hooks";
import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import CustomPagination from "./CustomPagination";
import BulkSearchModal from "./BulkSearchModal";
import { height } from "@mui/system";
import UserTransactionsDetail from "components/UserTransactionsDetail/UserTransactionsDetail";
import customVariable from "constants/customVariable";
import UserTransactionsDetailModal from "components/modals/UserTransactionsDetailModal";
import AddTransactionForm from "components/Forms/AddTransactionForm";
import UserTransactionDetailModal from "components/modals/UserTransactionDetailModal";
const perPageItems = [
  {
    perPageData: 25,
    label: "25 Rows/page",
  },
  {
    perPageData: 50,
    label: "50 Rows/page",
  },
  {
    perPageData: 100,
    label: "100 Rows/page",
  },
  {
    perPageData: 500,
    label: "500 Rows/page",
  },
  {
    perPageData: 1000,
    label: "1000 Rows/page",
  },
];

export default function DataTableCustom(props) {
  const {
    data,
    columns,
    isSearch,
    inActiveBulk,
    Toolbar,
    isChecked,
    isFetching = false,
    paginationInformation,
    updateFilters,
    setGrantInactiveUser,
    placeholder,
    setSearchBulkData,
    title,
    isPagination = true,
    headerForDailyStats,
    selectedTab,
    hasOutflow,
    pageFilter,
    setDownloadFileData,
    setUserType,
    userType,
    wave,
    setWave,
    isDialogBox,
    getDataFromTableInManageEmp = () => {},
    wavesData,
  } = props;
  //states
  const [pageFilters, setPageFilters] = useState({
    ...pageFilter,
    // page: 1,
    // perPage: 25,
    // search: "",
    // orderAt: columns[0].accessor || columns[0].Header,
    // orderBy: "DESC",
    // handlePagination: true,
  });

  const [searchKeyword, setSearchKeyword] = useState(null);
  const [searchBulkModal, setSearchBulkModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState("current");
  const [showOverflow, setShowOverfow] = useState(false);
  const [showEmployeeDetails, setShowEmployeeDetails] = useState(false);
  const [openTransactionsDetailDialog, setOpenTransactionsDetailDialog] =
    useState(false);
  //handlers
  const handleSearchUpdate = (text) => {
    setSearchKeyword(text?.trim());
    if (pageFilters?.page !== 1) handlePageFilters("page", 1);
  };
  const handleSearchBulkModalOpen = () => {
    setSearchBulkModal(true);
  };
  const handleSearchBulkModalClose = () => {
    setSearchBulkModal(false);
  };

  const handleSelectUser = (event) => {
    setSelectedUser(event.target.value);
  };

  // transactions details dialog
  const handleOpenTransactionDetailsDialog = (row) => {
    setOpenTransactionsDetailDialog(true);
  };

  const handleCloseTransactionDetailsDialog = () => {
    setOpenTransactionsDetailDialog(false);
  };
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: pageFilters?.orderAt || "id",
            desc: pageFilters?.orderBy === "desc",
          },
        ],
      },
      // manualSortBy: true,
      // autoResetPage: false,
      // autoResetSortBy: false,
      // disableSortRemove: true,
    },
    useSortBy,
    usePagination,
    useSticky,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((cols) =>
        isChecked && userType !== "study"
          ? [
              // Let's make a column for selection
              {
                id: "selection",
                // The header can use the table's getToggleAllRowsSelectedProps method
                // to render a checkbox
                Header: ({ getToggleAllRowsSelectedProps, rows }) => {
                  const anyUnderStudy = rows.find(
                    (row) => row.original.status === "UNDER_STUDY"
                  );
                  return (
                    <div className="h-full w-fit flex justify-center items-center">
                      {anyUnderStudy && userType === "all" ? (
                        <div className="pl-[5.5px]">
                          <input
                            type="checkbox"
                            disabled={true}
                            className="cursor-not-allowed bg-none w-[1.75rem] h-[1.75rem]"
                          />
                        </div>
                      ) : (
                        <IndeterminateCheckbox
                          {...getToggleAllRowsSelectedProps()}
                        />
                      )}
                    </div>
                  );
                },
                Cell: ({ row }) => {
                  const active =
                    userType === "all"
                      ? row?.original?.accessibilityStatus === "NO_LOGIN"
                        ? false
                        : true
                      : false;
                  return (
                    <div className="flex w-fit  items-center">
                      {!active ? (
                        <IndeterminateCheckbox
                          {...row.getToggleRowSelectedProps()}
                        />
                      ) : (
                        <div className="pl-[5.5px] w-fit ">
                          <input
                            type="checkbox"
                            disabled={true}
                            className="cursor-not-allowed bg-none w-[1.75rem] h-[1.75rem]"
                          />
                        </div>
                      )}
                    </div>
                  );
                },
                width: "50px",
              },
              ...cols,
            ]
          : [...cols]
      );
    }
  );

  const {
    state: { selectedRowIds },
  } = tableInstance;

  useEffect(() => {
    if (
      selectedTab === "1" ||
      selectedTab === "2" ||
      selectedTab === "3" ||
      selectedTab === "users" ||
      selectedTab === "enumerators"
    ) {
      setDownloadFileData(
        tableInstance.selectedFlatRows.map((d) => d.original)
      );
    }
  }, [selectedRowIds]);

  // useEffect(() => {
  //   const sortByDetails = {
  //     orderAt: sortBy[0]?.id,
  //     orderBy: sortBy[0]?.desc ? "desc" : "asc",
  //   };
  //   // setSortingBy(sortByDetails);
  //   // if (pageFilters) {
  //   //   setPageFilters((prev) => {
  //   //     return {
  //   //       ...prev,
  //   //       ...sortByDetails,
  //   //     };
  //   //   });
  //   // }
  //   // updateFilters((prev) => {
  //   //   return { ...prev, ...sortByDetails };
  //   // });
  // }, [sortBy]);

  useEffect(() => {
    setPageFilters(pageFilter);
  }, [headerForDailyStats]);

  /* Debouncing the search */
  const debouncedSearchKeyword = useDebounce(searchKeyword, 500);

  const handlePageFilters = (field, value) => {
    setPageFilters &&
      setPageFilters((currentPageFilters) => ({
        ...currentPageFilters,
        [field]: value,
      }));
    updateFilters((currentPageFilters) => ({
      ...currentPageFilters,
      [field]: value,
    }));
  };

  useEffect(() => {
    handlePageFilters("search", "");
    // since we want to reset search everytime user opens the page
  }, []);

  useEffect(() => {
    // if (searchKeyword || pageFilters.search !== '') {
    handlePageFilters("search", searchKeyword);
  }, [searchKeyword]);

  const userData = tableInstance.rows.filter(
    (activeData) => activeData?.isSelected === true
  );

  const handleRowClick = (row) => {
    setShowEmployeeDetails(true);
  };

  useEffect(() => {
    setGrantInactiveUser?.(userData);
    getDataFromTableInManageEmp(userData);
  }, [userData?.length]);

  useEffect(() => {
    pageFilters?.page !== 1 && handlePageFilters("page", 1);
  }, [pageFilters?.perPage]);

  const isAdmin = "true";

  return (
    <>
      <BulkSearchModal
        open={searchBulkModal}
        handleClose={handleSearchBulkModalClose}
        setSearchBulkData={setSearchBulkData}
      />
      <div
        className={`flex flex-col  rounded-md  overflow-x-hidden shadow-md bg-[#333b7a]
        ${headerForDailyStats === "Users" && "h-[365px] overflow-hidden"}
        `}
      >
        <div
          className={`grid grid-rows-1 ${
            selectedTab === "users"
              ? "grid-cols-[max-content_1fr_max-content] max-xl:grid-cols-2 max-xl:grid-rows-2 max-xl:gap-y-4"
              : "grid-cols-[1fr_max-content] max-xl:grid-rows-1"
          } justify-items-end   p-3  `}
        >
          {isPagination && (selectedTab === "users" || selectedTab === "1") && (
            <div className="capitalize row-start-1 w-full flex items-center justify-start">
              <div className=" text-[#E8EBFA] self-center row-start-1   text-xl text-start font-semibold">
                {headerForDailyStats &&
                  snakeCaseToTitleCase(headerForDailyStats)}
              </div>
              {selectedTab === "users" && (
                <div
                  className={`flex items-center gap-4 row-start-1   justify-between max-lg:items-start`}
                >
                  <Select
                    className="lg:hidden "
                    value={selectedUser}
                    onChange={handleSelectUser}
                    sx={{
                      "& .MuiSelect-select": {
                        paddingBlock: ".5rem",
                        background: "#5B5FC7",
                        border: "1px solid #5B5FC7",
                        fontSize: "1rem", // set font size for the label
                        color: "#FFFFFF", // set color for the label
                        lineHeight: "22px",
                        fontWeight: 400,
                        borderRadius: "12px",
                      },

                      "& .MuiSvgIcon-root": {
                        color: "#FFFFFF", // set the color of the icon
                        borderRadius: "12px",
                      },
                    }}
                  >
                    <MenuItem
                      value={"current"}
                      onClick={() => setUserType("current")}
                    >
                      Current Users
                    </MenuItem>
                    <MenuItem
                      value={"study"}
                      onClick={() => setUserType("study")}
                    >
                      Study Users
                    </MenuItem>
                    <MenuItem value={"all"} onClick={() => setUserType("all")}>
                      All Users
                    </MenuItem>
                  </Select>
                  <ButtonGroup
                    variant="text"
                    aria-label="text button group"
                    className="bg-[#E8EBFA] capitalize max-lg:hidden flex items-center justify-center rounded-lg overflow-hidden"
                  >
                    <Button
                      className={`capitalize  font-semibold p-[10px] ${
                        userType == "current" &&
                        "bg-[#fff] hover:bg-[#fff] hover:text-[#3D3E78]"
                      }`}
                      onClick={() => setUserType("current")}
                    >
                      Current Users
                    </Button>
                    <Button
                      className={`capitalize  font-semibold p-[10px] ${
                        userType == "study" &&
                        "bg-[#fff] hover:bg-[#fff] hover:text-[#3D3E78]"
                      }`}
                      onClick={() => setUserType("study")}
                    >
                      Study Users
                    </Button>
                    <Button
                      className={`capitalize  font-semibold p-[10px] ${
                        userType == "all" &&
                        "bg-[#fff] hover:bg-[#fff] hover:text-[#3D3E78]"
                      }`}
                      ddd
                      onClick={() => setUserType("all")}
                    >
                      All Users
                    </Button>
                  </ButtonGroup>
                  {userType === "all" && (
                    <Button
                      variant="standard"
                      onClick={handleSearchBulkModalOpen}
                      className="capitalize  whitespace-nowrap font-semibold py-auto my-auto text-center text-[16px] rounded-lg  text-white bg-[#5B5FC7]"
                    >
                      Bulk search
                    </Button>
                  )}
                  {userType == "study" && (
                    <Grid item>
                      <FormControl>
                        <Select
                          labelId="my-select-placeholder"
                          id="demo-simple-select"
                          onChange={(e) => {
                            setWave(e.target.value);
                          }}
                          className="flex gap-2 items-center h-[40px] rounded-[10px] bg-[#FFFFFF99] border-[1px] p-5 border-[#fff]"
                          value={wave}
                        >
                          <MenuItem value={0}>All Waves</MenuItem>
                          {wavesData
                            ?.sort((a, b) => a.id - b.id)
                            .map((wave) => (
                              <MenuItem value={wave?.id} key={wave.name}>
                                {wave?.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                </div>
              )}
            </div>
          )}

          {Toolbar && <Toolbar />}
          {isPagination && !isDialogBox && (
            <>
              <div
                className={` ${
                  selectedTab === "users"
                    ? "max-xl:row-start-2 row-start-1 max-xl:col-span-2"
                    : `${
                        selectedTab === "1"
                          ? "col-span-2 max-lg:row-start-2"
                          : "max-md:row-start-2"
                      } row-start-1  `
                }  w-full flex justify-end items-center whitespace-nowrap`}
              >
                <Typography
                  variant="body1"
                  fontWeight={500}
                  lineHeight={"22px"}
                  color="#fff"
                >
                  {/* Total {paginationInformation?.totalCount} items */}
                  Total {paginationInformation?.total} items
                </Typography>
                <CustomPagination
                  count={paginationInformation?.lastPage}
                  showFirstButton
                  showLastButton
                  page={pageFilters?.page || 1}
                  onChange={(e, value) => handlePageFilters("page", value)}
                />
              </div>
              <Select
                className="row-start-1 w-fit place-items-end"
                value={pageFilters?.perPage || 25}
                onChange={(e) => {
                  handlePageFilters("perPage", e.target.value);
                }}
                IconComponent={KeyboardArrowDownOutlined}
                renderValue={(selected) => {
                  // Find the selected option from the perPageItems array
                  const selectedOption = perPageItems.find(
                    (item) => item.perPageData === selected
                  );

                  // Return the label of the selected option
                  return selectedOption?.label || "";
                }}
                sx={{
                  "& .MuiSelect-select": {
                    paddingBlock: ".5rem",
                    background: "#5B5FC7",
                    border: "1px solid #5B5FC7",
                    fontSize: "1rem", // set font size for the label
                    color: "#FFFFFF", // set color for the label
                    lineHeight: "22px",
                    fontWeight: 400,
                    borderRadius: "12px",
                  },

                  "& .MuiSvgIcon-root": {
                    color: "#FFFFFF", // set the color of the icon
                  },
                }}
              >
                {perPageItems?.map((itemCount) => (
                  <MenuItem
                    value={itemCount?.perPageData}
                    key={`table-items-per-page-${itemCount}`}
                  >
                    {itemCount?.perPageData}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
        </div>

        <div className="tableBoxShdow  bg-[#ECF6FF]">
          {/* {selectedTab == "2" && showEmployeeDetails && (
            <div className="px-6 py-4 bg-[#E8EBFA] flex justify-center">
              <UserTransactionsDetailModal
                onClickHandler={() => setShowEmployeeDetails(false)}
              />
            </div>
          )} */}
          <div
            className=" hidden text-lg bg-[#ECF6FF]"
            style={{ borderBottom: "1px solid #ecece" }}
          >
            <p className="text-lg font-semibold"> {title}</p>
          </div>
          <div className="max-h-[50vh] max-md:max-h-[60vh] grow bg-[#E8EBFA] overflow-y-scroll ">
            <table
              {...tableInstance.getTableProps()}
              className="table w-full border-collapse"
              style={{ borderRight: "1px solid #333B7A" }}
            >
              <thead
                className="sticky top-0 z-40 align-middle text-semibold"
                style={{
                  zIndex: 999,
                  borderBottom: "1px solid #333B7A",
                }}
              >
                {
                  // Loop over the header rows
                  tableInstance.headerGroups.map(
                    (headerGroup, headerGroupIndex) => {
                      return (
                        // Apply the header row props
                        <tr
                          key={`table-header-group-${headerGroupIndex}`}
                          {...headerGroup.getHeaderGroupProps()}
                          className="align-middle text-[#333B7A] font-semibold border-b border-black tracking-normal px-2 "
                          style={{
                            borderTop:
                              selectedTab == "2" ? "1px solid black" : "",
                            position: "sticky",
                            top: 0, // Adjust this if your header should not stick to the very top of the container
                            backgroundColor: "#fff", // Ensures the text doesn't show through when scrolling
                            zIndex: 999999, //
                          }}
                        >
                          {
                            // Loop over the headers in each row
                            headerGroup.headers.map((column, headerIndex) => (
                              // Apply the header cell props

                              <th
                                className={`font-bold  bg-[#E8EBFA]  
                                ${headerIndex === 0 ? "  pt-2" : "pl-0"} py-2
                                `}
                                key={`table-header-${headerIndex}`}
                                style={{
                                  borderRight: "1px solid #333B7A",
                                }}
                              >
                                <div
                                  {...column.getHeaderProps(
                                    column.getSortByToggleProps()
                                  )}
                                  className="flex items-center px-6 w-fit  space-x-2"
                                >
                                  <span
                                    // className={`text-sm font-medium text-hb_blue-800 ${
                                    //   column.isSorted ? "sorted" : null
                                    // } align-middle`}
                                    className={`text-sm whitespace-nowrap font-bold `}
                                  >
                                    {
                                      // Render the header
                                      column.render("Header")
                                    }
                                  </span>
                                  {column.isSorted ? (
                                    column.isSortedDesc ? (
                                      <span className="flex sorted-svg-icon">
                                        <FaSortAmountDown className="text-hb_orange-dark" />
                                      </span>
                                    ) : (
                                      <span className="sorted-svg-icon">
                                        <FaSortAmountUp className="text-hb_orange-dark" />
                                      </span>
                                    )
                                  ) : (
                                    ""
                                  )}
                                  {/* <span className={`align-middle`}>
                                    <span className={`align-middle`}>
                                      {column.isSorted ? (
                                        column.isSortedDesc ? (
                                          <span className="flex sorted-svg-icon">
                                            <FaSortAmountDown className="text-hb_orange-dark" />
                                          </span>
                                        ) : (
                                          <span className="sorted-svg-icon">
                                            <FaSortAmountUp className="text-hb_orange-dark" />
                                          </span>
                                        )
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  </span> */}
                                </div>
                                {/* <div
                                // style={
                                //   headerIndex === 0
                                //     ? { width: "50px" }
                                //     : {
                                //         width: column.width || "100px",
                                //       }
                                // }
                                >
                                  {column.Filter
                                    ? column.render("Filter")
                                    : null}
                                </div> */}
                              </th>
                            ))
                          }
                        </tr>
                      );
                    }
                  )
                }
              </thead>
              <tbody
                className="w-full h-full"
                {...tableInstance.getTableBodyProps()}
              >
                {
                  // Loop over the table rows
                  tableInstance.rows.map((row, rowIndex) => {
                    // Prepare the row for display
                    tableInstance.prepareRow(row);
                    let bgColor = "#E8EBFA";
                    if (selectedTab === "2") {
                      if (row.original.currentBalance < 0) {
                        bgColor = "#FF000033";
                      } else {
                      }
                    } else if (selectedTab === "3") {
                      switch (row.original?.type) {
                        case "DEBIT":
                          bgColor = "#FF000033";
                          break;
                        case "CREDIT":
                          bgColor = "#06972F33";
                          break;
                        case "NONE":
                          bgColor = "#0000001A";
                          break;
                        default:
                          break;
                      }
                    }

                    if (showOverflow === false && row.original.isOverflow) {
                      setShowOverfow(true);
                    }
                    return (
                      // Apply the row props
                      <tr
                        {...row.getRowProps()}
                        style={{
                          backgroundColor: bgColor,
                          border: "1px solid #fff",
                          //padding: isChecked ? "100px 0" : ""
                        }}
                      >
                        {
                          // Loop over the rows cells
                          row.cells.map((cell, idx) => {
                            // Apply the cell props
                            return (
                              <td
                                {...cell.getCellProps()}
                                style={{
                                  borderRight: "1px solid #333B7A",
                                  // width:
                                  //   idx == 0 && isChecked ? "50px" : "auto",
                                }}
                                className={`${bgColor} text-[14px] whitespace-nowrap font-medium px-6  ${
                                  cell.column.isSorted ? "sorted" : "not-sorted"
                                }  border-t-2    ${
                                  idx === 0 ? "align-middle pt-2" : ""
                                }
                                     ${
                                       selectedTab == "2" && idx === 0
                                         ? "cursor-pointer"
                                         : ""
                                     }
                                     ${
                                       row?.isSelected === true
                                         ? "bg-white"
                                         : ""
                                     } 
                                     py-[8px] `}
                                onClick={() => {
                                  if (selectedTab == "2" && idx === 0) {
                                    handleOpenTransactionDetailsDialog(row);
                                  }
                                }}
                              >
                                {
                                  // Render the cell contents
                                  cell.render("Cell")
                                }
                              </td>
                            );
                          })
                        }
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
          </div>
          {!data.length > 0 && (
            <div className="w-full h-11 bg-[#E8EBFA] font-medium text-center py-10 text-xl">
              No Data
            </div>
          )}
          {selectedTab !== "1" && !isDialogBox && (
            <div className="h-[55px] bg-[#3D3E78] flex items-center px-[2%]">
              {selectedTab === "2" && (
                <>
                  <div className="w-[17px] h-[17px] bg-[#FF6262] rounded-[50%]"></div>
                  <Typography className="text-[#fff]">
                    Cash Outflow is more than Cash Inflow
                  </Typography>
                </>
              )}
              {selectedTab === "3" && (
                <Stack direction="row" spacing="24px">
                  <Box className="flex items-center gap-[4px]">
                    <div className="w-[17px] h-[17px] bg-[#FF6262] rounded-[50%]"></div>
                    <Typography className="text-[#fff]">
                      Cash Outflow
                    </Typography>
                  </Box>
                  <Box className="flex items-center gap-[4px]">
                    <div className="w-[17px] h-[17px] bg-[#45B163] rounded-[50%]"></div>
                    <Typography className="text-[#fff]">Cash Inflow</Typography>
                  </Box>
                  <Box className="flex items-center gap-[4px]">
                    <div className="w-[17px] h-[17px] bg-[#D4DDE5] rounded-[50%]"></div>
                    <Typography className="text-[#fff]">
                      No transaction
                    </Typography>
                  </Box>
                  <Box className="flex items-center gap-[4px]">
                    <img src={PenIcon} className="w-[18px] h-[18px]" />
                    <Typography className="text-[#fff]">
                      Edited by Enumerator
                    </Typography>
                  </Box>
                </Stack>
              )}
            </div>
          )}
          {/* {!isSearch && (
            <div className="flex items-center justify-between px-4 py-3 my-3 tableBoxShdow">
              <p className="font-semibold text-md">
                {pageFilters.page &&
                  paginationInformation?.pageCount &&
                  `Page ${pageFilters.page} of ${paginationInformation?.pageCount}`}
              </p>

              <div className="flex gap-2">
                <Button
                  variant="contained"
                  disabled={pageFilters?.page ? pageFilters?.page === 1 : true}
                  sx={{
                    background: '#FFFFFF',

                    border: '1px solid #E5E7EB',
                    boxShadow: '0px 1px 2px rgba(16, 40, 36, 0.05)',
                    color: '#000',
                    borderRadius: '8px',
                    minWidth: '100px',
                    '&:hover': {
                      background: '#FFFFFF',
                    },
                  }}
                  onClick={() =>
                    handlePageFilters('page', pageFilters?.page - 1)
                  }
                >
                  Previous
                </Button>
                <Button
                  variant="contained"
                  disabled={
                    pageFilters?.page
                      ? pageFilters?.page ===
                          paginationInformation?.pageCount ||
                        paginationInformation?.pageCount === 0
                      : true
                  }
                  sx={{
                    background: '#FFFFFF',
                    minWidth: '100px',
                    border: '1px solid #E5E7EB',
                    boxShadow: '0px 1px 2px rgba(16, 40, 36, 0.05)',
                    color: '#000',
                    borderRadius: '8px',

                    '&:hover': {
                      background: '#FFFFFF',
                    },
                  }}
                  onClick={() =>
                    handlePageFilters('page', pageFilters?.page + 1)
                  }
                >
                  Next
                </Button>
              </div>
            </div>
          )} */}
        </div>
      </div>
      {/* <Dialog
        open={openTransactionsDetailDialog}
        onClose={handleCloseTransactionDetailsDialog}
        maxWidth="658px"
        className="z-[10000]"
      >
        <Paper className="h-[425px] w-[400px]  px-8 rounded-lg shadow-md relative">
          <div
            className="absolute top-[2.5%] right-[2.5%] w-[24px] h-[24px] hover:cursor-pointer"
            onClick={handleCloseTransactionDetailsDialog}
          >
            <img src={CrossIcon} className="w-[24px] h-[24px]" />
          </div>
          <UserTransactionDetailModal
            handleCloseDialog={handleCloseTransactionDetailsDialog}
          />
        </Paper>
      </Dialog> */}
    </>
  );
}
