import React from "react";
import Button from "@mui/material/Typography";
import Typography from "@mui/material/Typography";
import { useGetWorkerDetailById } from "pages/Dashboard/dashboardQueries";

const UserTransactionDetailModal = ({ label, value, handleCloseDialog }) => {
  const { data, error } = useGetWorkerDetailById(value);

  const handleDownloadClick = () => {
    window.print();
    handleCloseDialog(false);
  };

  if (data)
    return (
      <div className="pt-10">
        <div className=" bg-white mb-10" id="modalContent">
          <UserKeyValue label={"Name"} value={data?.data?.user?.name} />
          <UserKeyValue
            label={"designation"}
            value={data?.data?.user?.worker?.designation}
          />
          <UserKeyValue
            label={"Department"}
            value={data?.data?.user?.worker?.department}
          />
          <UserKeyValue
            label={"initial Balance"}
            value={data?.data?.initialBalance}
          />
          <UserKeyValue
            label={"current Balance"}
            value={data?.data?.currentBalance}
          />
          <UserKeyValue label={"Cash Inflows"} value={data?.data?.inflows} />
          <UserKeyValue label={"Cash Outflows"} value={data?.data?.outflows} />
          <UserKeyValue label={"Income"} value={data?.data?.income} />
          <UserKeyValue label={"Expenses"} value={data?.data?.expenses} />
          <UserKeyValue label={"Loans"} value={data?.data?.loans} />
          <UserKeyValue label={"Insurance"} value={data?.data?.insurance} />
          <UserKeyValue
            label={"Savings & Investments"}
            value={data?.data?.savingsAndInvestments}
          />
        </div>
        <div className="flex items-center mx-auto justify-center gap-5">
          <Button
            variant="standard"
            className="whitespace-nowrap  capitalize font-semibold text-sm px-5 py-2 text-white bg-[#3D3E78] cursor-pointer"
            onClick={() => handleDownloadClick()}
          >
            Download
          </Button>
        </div>
      </div>
    );
  if (error)
    return (
      <div className="flex justify-center items-center h-full w-full">
        No Worker Found!!!
      </div>
    );
  return (
    <div className="flex justify-center items-center h-full w-full">
      Loading...
    </div>
  );
};

export default UserTransactionDetailModal;

export function UserKeyValue({ label, value }) {
  return (
    <div className="grid grid-cols-2 gap-x-3">
      <Typography
        className="text-primary font-bold capitalize"
        style={{ fontSize: "16px", color: "#1B2559" }}
      >
        {label}
      </Typography>
      <p>{value}</p>
    </div>
  );
}
