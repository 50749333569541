const apiStatsKey = {
  overall: "overall",
  inflow: "inflow",
  outflow: "outflow",
  income: "income",
  savings: "savings",
  borrowings: "borrowings",
  transfer: "transfer",
  foodExpenditures: "food-expenditures",
  nonFoodExpenditures: "non-food-expenditures",
  paymentMode: "payment-mode",
  loans: "loans",
  loanBorrowingsRepayments: "loan-borrowings-repayments",
};

export default apiStatsKey;
