import React from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList } from "@mui/lab";

const TabPanel = (props) => {
  const { tabvalue, setTabValue, setSelectedFilters } = props;

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    setSelectedFilters({
      empCode: "",
      factory: "",
    });
  };

  return (
    <Box>
      <TabContext value={tabvalue}>
        <Box>
          <TabList
            className="flex items-end"
            aria-label="lab API tabs example"
            onChange={handleChange}
            sx={{
              "& .MuiTabs-indicator": {
                display: "none",
              },
            }}
          >
            <Tab
              label={
                <div
                  className={`rounded-t-lg  w-full  text-sm  font-bold ${
                    tabvalue === "users"
                      ? "bg-[#E8EBFA] text-[#3D3E78] opacity-100"
                      : "text-[#3D3E78] bg-[#F0F0F0] opacity-70"
                  }  capitalize whitespace-nowrap px-3 py-4
                  flex items-center justify-center`}
                >
                  <p
                    className={`text-md ${
                      tabvalue === "users" ? "font-semibold" : "font-normal"
                    }`}
                  >
                    Users
                  </p>
                </div>
              }
              value="users"
              className="self-end p-0 mr-2 rounded-t-lg"
            />
            <Tab
              label={
                <div
                  className={`rounded-t-lg text-sm  font-bold ${
                    tabvalue === "enumerators"
                      ? "bg-[#E8EBFA] text-[#3D3E78] opacity-100"
                      : "text-[#3D3E78] bg-[#F0F0F0] opacity-70"
                  } gap-2  whitespace-nowrap py-4   px-3
                  flex items-center justify-center capitalize`}
                  onChange={handleChange}
                >
                  <p
                    className={`text-md capitalize ${
                      tabvalue === "enumerators"
                        ? "font-semibold"
                        : "font-normal"
                    }`}
                  >
                    GBL Supervisor
                  </p>
                </div>
              }
              value="enumerators"
              className="self-end p-0 mr-2 rounded-t-lg"
            />
          </TabList>
        </Box>
      </TabContext>
    </Box>
  );
};

export default TabPanel;
