import { Suspense, useMemo } from "react";
import { Route, Routes, Outlet, Navigate, useLocation } from "react-router-dom";
import { getLocalStorageItem, localStorageKeys } from "hooks";
import RouteConstants from "routes/RouteConstants";
import { Layout, SeoHelmet } from "components";
import { routes } from "routes/RouteConstants";
import { useIsFetching, useIsMutating } from "@tanstack/react-query";
import { Backdrop, CircularProgress } from "@mui/material";

// import AppContainer from 'Pages/AppContainer';

const RoutesPage = () => {
  const runningAPICount = useIsFetching();
  const mutatingAPICount = useIsMutating();
  const PublicRoutes = useMemo(
    () => (
      <>
        {Object.values(routes)
          .filter(
            (routeDetails) => routeDetails.isPublic && routeDetails.element
          )
          .map((routeDetails) => (
            <Route
              key={`public-route-${routeDetails.pathname}`}
              path={routeDetails.pathname}
              element={
                <Suspense fallback={null}>{routeDetails.element}</Suspense>
              }
            ></Route>
          ))}
      </>
    ),
    []
  );
  const PrivateRoutes = useMemo(
    () => (
      <>
        {Object.values(routes)
          .filter(
            (routeDetails) => !routeDetails.isPublic && routeDetails.element
          )
          .map((routeDetails) => (
            <Route
              key={`private-route-${routeDetails.pathname}`}
              path={routeDetails.pathname}
              element={
                <Suspense fallback={null}>{routeDetails.element}</Suspense>
              }
            />
          ))}
        {/* </Suspense> */}
      </>
    ),
    []
  );
  return (
    <>
      <div className="h-screen">
        <SeoHelmet />
        <Routes>
          {PublicRoutes}

          <Route element={<ProtectedRoutes />}>{PrivateRoutes}</Route>
        </Routes>
        <Backdrop
          open={Boolean(runningAPICount || mutatingAPICount)}
          sx={{
            zIndex: (theme) => theme.zIndex.modal + 100,
            backgroundColor: "rgba(0,0,0,0.3)",
            height: "100vh",
          }}
        >
          <CircularProgress color="primary" />
        </Backdrop>
      </div>
    </>
  );
};

const ProtectedRoutes = () => {
  const location = useLocation();

  const isLoggedIn = getLocalStorageItem("AUTH_TOKEN");

  return isLoggedIn ? (
    <Layout>
      <Outlet />
    </Layout>
  ) : (
    <Navigate to={RouteConstants.LOGIN} replace state={{ from: location }} />
  );
};

export default RoutesPage;
