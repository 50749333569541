import BarAndPieChartContainer from "components/StatisticsCutomComponents/BarAndPieChartContainer";
import BorrowingIcon from "assets/Images/statistics/borrowing.png";
import { useGetStats } from "pages/Dashboard/dashboardQueries";
import useControlParams from "hooks/useControlParams";
import paramsKey from "constants/paramsKey";
import { getChangeKey } from "./InflowTransactions";
import apiStatsKey from "constants/apiKeys";

export default function BorrowingsSection() {
  const { availableParams, getParamValue } = useControlParams();
  const { data: BorrowingsData } = useGetStats(
    availableParams(),
    apiStatsKey.borrowings
  );
  const changedKey = getChangeKey(getParamValue(paramsKey.filterKey));

  return (
    <BarAndPieChartContainer
      graphHeadLine={{ icon: BorrowingIcon, heading: "Borrowings" }}
      barGraphData={{
        bgcolor: "#4C96A6",
        data:
          changedKey === "hour"
            ? BorrowingsData?.data?.bars?.[0]?.transactions
            : BorrowingsData?.data?.bars,
        xdatakey: changedKey,
        ydatakey: "amount",
      }}
      pieGraphData={{
        colorOfPiechart: "#4C96A6",
        data: BorrowingsData?.data?.pie || [],
        datakey: "percentages",
        namekey: "name",
      }}
    />
  );
}
