import Tickmark from "assets/Images/statistics/tickmark.png";
import Crossmark from "assets/Images/statistics/crossmark.png";
import Logout from "assets/Images/statistics/logout.png";
import Tranc from "assets/Images/statistics/tranc.png";
import Inflow from "assets/Images/statistics/inflow.png";
import Outflow from "assets/Images/statistics/outflow.png";
import StatisticsInfoCard from "components/StatisticsCutomComponents/StatisticsInfoCard";
import { useGetStats } from "pages/Dashboard/dashboardQueries";
import useControlParams from "hooks/useControlParams";
import apiStatsKey from "constants/apiKeys";

function cardOneDataParser(data) {
  return [
    {
      icon: Tickmark,
      number: data?.successFull,
      decription: "Number of successful logins",
    },
    {
      icon: Crossmark,
      number: data?.unSuccessFull,
      decription: "Failed login attempts",
    },
  ];
}

function cardTwoDataParser(data) {
  return [
    {
      icon: Inflow,
      number: data?.inflows,
      decription: "Number of inflow transactions added",
    },
    {
      icon: Outflow,
      number: data?.outflow,
      decription: "Number of outflow transactions added",
    },
    {
      icon: Crossmark,
      number: data?.noTransaction,
      decription: "Number of no transactions",
    },
  ];
}

export default function StatisticsInfoSection() {
  const { availableParams } = useControlParams();

  const { data: DailyStates } = useGetStats(
    availableParams(),
    apiStatsKey.overall
  );

  return (
    <div className="flex items-stretch gap-5 max-[1000px]:flex-col">
      <div className="flex-[0.4]">
        <StatisticsInfoCard
          cardData={cardOneDataParser(DailyStates?.data)}
          icon={Logout}
        />
      </div>
      <div className="flex-[0.6]">
        <StatisticsInfoCard
          cardData={cardTwoDataParser(DailyStates?.data)}
          icon={Tranc}
        />
      </div>
    </div>
  );
}
