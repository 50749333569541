import BarAndPieChartContainer from "components/StatisticsCutomComponents/BarAndPieChartContainer";
import FoodIcon from "assets/Images/statistics/nofood.png";
import useControlParams from "hooks/useControlParams";
import { useGetStats } from "pages/Dashboard/dashboardQueries";
import apiStatsKey from "constants/apiKeys";
import { getChangeKey } from "./InflowTransactions";
import paramsKey from "constants/paramsKey";

export default function LoanOrBorrowingRepaymentsSection() {
  const { availableParams, getParamValue } = useControlParams();
  const { data: LoanBorrowingData } = useGetStats(
    availableParams(),
    apiStatsKey.loanBorrowingsRepayments
  );

  const changedKey = getChangeKey(getParamValue(paramsKey.filterKey));
  return (
    <BarAndPieChartContainer
      graphHeadLine={{ icon: FoodIcon, heading: "Loan/Borrowing Repayments" }}
      barGraphData={{
        bgcolor: "#7E65C3",
        data:
          changedKey === "hour" // getting different response structur from BE on daily filters
            ? LoanBorrowingData?.data?.bars?.[0]?.transactions
            : LoanBorrowingData?.data?.bars,
        xdatakey: changedKey,
        ydatakey: "amount",
      }}
      pieGraphData={{
        colorOfPiechart: "#B40FE7",
        data: LoanBorrowingData?.data?.pie || [],
        datakey: "percentages",
        namekey: "name",
      }}
    />
  );
}
