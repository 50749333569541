import { useNavigate } from "react-router";
import AxiosInstance from "service/axiosInstance";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { appendQueryParams } from "service/appendQueryParams";
import { useToast } from "hooks";
import { changeDateToLocal } from ".";

function getStats(params, urlKey) {
  const url = appendQueryParams(`/api/v1/statistics/${urlKey}`, params);
  return AxiosInstance({
    method: "GET",
    url,
  });
}

export function useGetStats(params, urlKey) {
  return useQuery({
    queryKey: [urlKey, params],
    queryFn: () => getStats(params, urlKey),
    enabled: !!params,
  });
}

const getDailyStats = (data) => {
  const method = "GET";
  let url = appendQueryParams(`api/v1/admin/daily-stats`, data);
  return AxiosInstance({
    method,
    url,
    data,
  });
};

export const useGetDailyStats = (data) => {
  return useQuery({
    queryKey: ["daily-stats", data],
    queryFn: () => getDailyStats(data),
    enabled: !!data,
  });
};
const getDailyStatsPaginated = (filters) => {
  const method = "GET";
  let url = appendQueryParams(`api/v1/admin/daily-stats/paginate`, {
    ...filters,
  });
  return AxiosInstance({
    method,
    url,
    filters,
  });
};

export const useGetDailyStatsPaginated = (filters) => {
  return useQuery({
    queryKey: ["daily-stats-paginated", filters],
    queryFn: () => getDailyStatsPaginated(filters),
    enabled: !!filters,
    retry: 0,
    cacheTime: 0,
  });
};

const getTransactionsPaginatedData = (filters) => {
  const method = "GET";
  let url = appendQueryParams(`api/v1/admin/transactions`, {
    ...filters,
  });
  return AxiosInstance({
    method,
    url,
    filters,
  });
};

export const useGetTransactionsPaginatedData = (filters) => {
  return useQuery({
    queryKey: ["transactions-paginated-data", filters],
    queryFn: () => getTransactionsPaginatedData(filters),
    enabled: !!filters,
    retry: 1,
  });
};

const getUserDataPaginatedData = (filters) => {
  const method = "GET";
  let url = appendQueryParams(`api/v1/admin/worker-data/paginate`, {
    ...filters,
  });
  return AxiosInstance({
    method,
    url,
    filters,
  });
};

export const useGetUserDataPaginatedData = (filters) => {
  return useQuery({
    queryKey: ["user-paginated-data", filters],
    queryFn: () => getUserDataPaginatedData(filters),
    enabled: !!filters,
    retry: 1,
  });
};

const logout = () => {
  const method = "DELETE";
  let url = "api/v1/auth/logout";
  return AxiosInstance({
    method,
    url,
  });
};

export const useLogout = () => {
  const navigate = useNavigate();
  return useMutation(logout, {
    onSuccess: () => {
      localStorage.clear();
      navigate("/login");
    },
  });
};

const addTransaction = (data) => {
  const method = "POST";
  let url = `api/v1/transaction`;
  return AxiosInstance({
    method,
    url,
    data,
  });
};

export const useAddTransaction = () => {
  const queryClient = useQueryClient();
  const responseToast = useToast();
  return useMutation(addTransaction, {
    onSuccess: (response) => {
      queryClient.invalidateQueries(["transactions-paginated-data"]);
      responseToast.toastSuccess(response.message || "Added Successfully");
    },
  });
};

const editTransaction = (txsData) => {
  const { txId, ...data } = txsData;
  const method = "PATCH";
  let url = `api/v1/transaction/${txId}`;
  return AxiosInstance({
    method,
    url,
    data,
  });
};

export const useEditTransaction = () => {
  const queryClient = useQueryClient();
  const responseToast = useToast();
  return useMutation(editTransaction, {
    onSuccess: (response) => {
      queryClient.invalidateQueries(["transactions-paginated-data"]);
      responseToast.toastSuccess(response.message || "Updated Successfully");
    },
  });
};

function getPaymentSource(type) {
  const method = "GET";
  let url = `api/v1/payment-source?type=${type}`;
  return AxiosInstance({
    method,
    url,
  });
}
export const useGetPaymentSource = (type) => {
  return useQuery({
    queryKey: ["payment-source", type],
    queryFn: () => getPaymentSource(type),
    enabled: !!type,
    retry: 1,
  });
};

const getEmployeesNotDoneTxs = (data) => {
  const method = "GET";
  let url = appendQueryParams(`api/v1/worker/has-not-done-transactions`, data);
  return AxiosInstance({
    method,
    url,
    data,
  });
};

export const useGetEmployeesNotDoneTxs = (id) => {
  return useQuery({
    queryKey: ["employee-not-done-txs", id],
    queryFn: () => getEmployeesNotDoneTxs(id),
  });
};

const getWorkerDetailById = (id) => {
  const method = "GET";
  let url = `/api/v1/admin/${id}/worker-data?id=${id}`;
  return AxiosInstance({
    method,
    url,
  });
};

export const useGetWorkerDetailById = (id) => {
  return useQuery({
    queryKey: ["worker_data_by_id", id],
    queryFn: () => getWorkerDetailById(id),
    enabled: !!id,
    retry: 0,
  });
};

const deleteTxs = (id) => {
  const method = "DELETE";
  let url = `/api/v1/transaction/${id}`;
  return AxiosInstance({
    method,
    url,
  });
};

export const useDeleteTxs = () => {
  const queryClient = useQueryClient();
  const responseToast = useToast();
  return useMutation({
    mutationFn: (id) => deleteTxs(id),
    onSuccess: (response) => {
      queryClient.invalidateQueries(["transactions-paginated-data"]);
      queryClient.invalidateQueries(["user-paginated-data"]);
      responseToast.toastSuccess(response.message || "Deleted Successfully");
    },
  });
};

//function to modify excel sheet
export function getFilterTxsData(obj) {
  if (obj.history) {
    const filterResult = {
      TRANSACTION_ID: obj?.id,
      EMPLOYEE_ID: obj?.employeeId,
      TYPE:
        (obj?.type === "NONE" && "NO TRANSACTION") ||
        (obj?.type === "DEBIT" && "OUTFLOW") ||
        (obj?.type === "CREDIT" && "INFLOW"),
      SUB_CATEGORY: obj?.subCategory,
      SOURCE: obj?.source,
      PAYMENT_MODE: obj?.mode,
      AMOUNT: obj?.amount,
      ENTRY_TIMESTAMP: changeDateToLocal(obj?.createdAt),
      TRANSACTION_DATE: changeDateToLocal(obj?.transactionDate, false),
      history_transaction_id: obj?.id,
      history_employee_d: obj?.employeeId,
      history_type:
        (obj?.history.type === "NONE" && "NO TRANSACTION") ||
        (obj?.history.type === "DEBIT" && "OUTFLOW") ||
        (obj?.history.type === "CREDIT" && "INFLOW"),
      history_subcategory: obj?.history.source.name,
      history_source: obj?.history.description,
      history_mode_of_payment: obj?.history.mode,
      history_amount: obj?.history.amount,
      history_entry_timestamp: changeDateToLocal(obj?.history.updatedAt),
      history_transaction_date: changeDateToLocal(
        obj?.history.transactionDate,
        false
      ),
    };
    return filterResult;
  }

  return {
    TRANSACTION_ID: obj?.id,
    EMPLOYEE_ID: obj?.employeeId,
    TYPE:
      (obj?.type === "NONE" && "NO TRANSACTION") ||
      (obj?.type === "DEBIT" && "OUTFLOW") ||
      (obj?.type === "CREDIT" && "INFLOW"),
    SUB_CATEGORY: obj?.subCategory,
    SOURCE: obj?.source,
    PAYMENT_MODE: obj?.mode,
    AMOUNT: obj?.amount,
    ENTRY_TIMESTAMP: changeDateToLocal(obj?.createdAt),
    TRANSACTION_DATE: changeDateToLocal(obj?.transactionDate, false),
  };
}

const getTransactionsExportData = (filters) => {
  const { yesTrigger, language, ...restfilters } = filters;
  const method = "GET";
  let url = appendQueryParams(`api/v1/admin/transactions`, {
    ...restfilters,
  });
  return AxiosInstance({
    method,
    url,
    language: language,
    filters: {
      ...restfilters,
    },
  });
};

export const useGetTransactionsExportData = (filters) => {
  return useQuery({
    cacheTime: 0,
    queryKey: ["transactions-paginated-data-export", filters],
    queryFn: () => getTransactionsExportData(filters),
    enabled: Boolean(filters?.yesTrigger),
    retry: 1,
  });
};

const getTransactionsExportDataUrl = () => {
  const method = "GET";
  let url = `api/v1/admin/transaction/exportUrl`;
  return AxiosInstance({
    method,
    url,
  });
};

export const useGetTransactionsExportDataUrl = () => {
  return useQuery({
    cacheTime: 0,
    queryKey: ["transactions-paginated-data-export-url"],
    queryFn: () => getTransactionsExportDataUrl(),
    enabled: true,
    retry: 1,
  });
};
