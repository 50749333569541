import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { makeFirstLetterUppercase } from "utils/dateFunctions";
export default function BarGraph({
  data,
  xdatakey,
  ydatakey,
  bgcolor,
  dxY = -30,
}) {
  return (
    <ResponsiveContainer width={"100%"} height={200}>
      <BarChart width={400} height={200} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis fontSize={10} dataKey={xdatakey}>
          <Label
            fontSize={14}
            value={makeFirstLetterUppercase(xdatakey)}
            offset={0}
            position="bottom"
          />
        </XAxis>
        <YAxis fontSize={10}>
          <Label
            fontSize={14}
            value={makeFirstLetterUppercase(ydatakey)}
            angle={-90}
            dx={dxY}
            position={"middle"}
            textAnchor="middle"
          />
        </YAxis>
        <Tooltip />
        <Bar dataKey={ydatakey} fill={bgcolor || "#8884d8"} />
      </BarChart>
    </ResponsiveContainer>
  );
}
