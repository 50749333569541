export default function Tabs({ options = [], activeOption, onClick }) {
  return (
    <div className="flex gap-1 border border-solid border-black/30 py-1 px-1 rounded overflow-hidden bg-[#E8Ebfa]">
      {options?.map((item, index) => (
        <>
          <div
            tabIndex={0}
            onClick={() => {
              onClick(item);
            }}
            className={`${
              activeOption === item ? "bg-white" : "bg-[#E8EBFA]"
            } px-2 flex items-center justify-center rounded cursor-pointer`}
          >
            {item}
          </div>
          {index !== options?.length - 1 && (
            <div className="w-[1px] bg-gray-300"></div>
          )}
        </>
      ))}
    </div>
  );
}
