import useTintGernator from "hooks/useTintGernator";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";

export default function PieGraph({ data, colorOfPiechart, datakey, namekey }) {
  const shades = useTintGernator(colorOfPiechart);
  return (
    <div className="flex">
      <PieChart
        colors={shades}
        series={[
          {
            data: data?.map((item, index) => ({
              id: index,
              value: item[datakey],
              label: item[namekey],
            })),
            highlightScope: { faded: "global", highlighted: "item" },
            faded: { innerRadius: 30, additionalRadius: -30, color: "gray" },
            arcLabel: (item) => item.value,
            arcLabelMinAngle: 25,
          },
        ]}
        slotProps={{
          legend: {
            hidden: true,
          },
        }}
        sx={{
          [`& .${pieArcLabelClasses.root}`]: {
            fill: "black",
            fontWeight: "bold",
          },
          [`& .MuiChartsLegend-column`]: {
            transform: "translateX(8%)",
          },
        }}
        width={400}
        height={300}
      />
      <PieLegend shades={shades} data={data} namekey={namekey} />
    </div>
  );
}

function returnShade(shades, index) {
  if (index < shades?.length) {
    return shades[index];
  } else {
    return shades[Math.round(index % shades?.length)];
  }
}

function PieLegend({ shades, data, namekey }) {
  return (
    <div className="h-[250px] flex flex-col p-2 gap-4 w-[200px] overflow-y-auto">
      {data?.map((item, index) => (
        <div className="flex items-start gap-2">
          <div
            className="w-[20px] h-[20px]"
            style={{ background: returnShade(shades, index) }}
          ></div>
          <p className="text-sm">{item[namekey]}</p>
        </div>
      ))}
    </div>
  );
}
