import { Pagination } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomPagination = styled(Pagination)(({ theme }) => ({
  "& .MuiPaginationItem-icon": {
    color: "white", // change the color of the arrow icon
  },
  "& .MuiPaginationItem-sizeMedium": {
    color: "white", // change the color of the arrow icon
  },
  "& .MuiPagination-ul": {
    display: "flex", // make the pagination items flex
    justifyContent: "center", // center the items horizontally
    whiteSpace: "no-wrap",
  },
  "& .MuiButtonBase-root": {
    color: "white",
  },
  "& .MuiPaginationItem-root": {
    borderRadius: "20%", // make the pagination items circular
    margin: "0 1px", // add some space between the items
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.1)", // add a hover effect
    },
  },
  "& .Mui-selected": {
    // change the background color of the selected item
    color: "white", // change the text color of the selected item
    border: "1px solid #659ECD",
  },
  "& .MuiPaginationItem-textPrimary.Mui-selected": {
    color: "white", // add this line to increase the specificity
  },
}));

export default CustomPagination;
