import StatisticsNavbar from "components/StatisticsCutomComponents/StatisticsNavbar";
import BorrowingsSection from "./BorrowingsSection";
import FoodExpendituresSection from "./FoodExpendituresSection";
import GiftsOrTransfersSection from "./GiftsOrTransfersSection";
import InflowTransactions from "./InflowTransactions";
import LoanOrBorrowingRepaymentsSection from "./LoanOrBorrowingRepaymentsSection";
import ModeOfPaymentsSection from "./ModeOfPaymentsSection";
import NonFoodExpendituresSection from "./NonFoodExpendituresSection";
import OutFlowTransactions from "./OutFlowTransactions";
import StatisticsInfoSection from "./StatisticsInfoSection";
import BarToggleWrapper from "components/StatisticsCutomComponents/BarToggleWrapper";
import IncomeSection from "./IncomeSection";
import SavingsSection from "./SavingsSection";

export default function StudyStatistics() {
  return (
    <div className="p-5 flex flex-col gap-6">
      <StatisticsNavbar />
      <StatisticsInfoSection />
      <InflowTransactions />
      <BarToggleWrapper title={"Inflow other charts"}>
        <IncomeSection />
        <BorrowingsSection />
        <GiftsOrTransfersSection />
      </BarToggleWrapper>
      <OutFlowTransactions />
      <BarToggleWrapper title={"Outflow other charts"}>
        <SavingsSection />
        <FoodExpendituresSection />
        <NonFoodExpendituresSection />
        <LoanOrBorrowingRepaymentsSection />
      </BarToggleWrapper>
      <ModeOfPaymentsSection />
    </div>
  );
}
