import React from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList } from "@mui/lab";

const TabPanel = (props) => {
  const {
    tabvalue,
    setTabValue,
    setDashboardFilters,
    setSelectedFilters,
    setDailyStatsFilter,
    setUserDataTableFilter,
    setTransactionTableFilter,
    setWave,
    setDateRange,
    setDateRangeTransaction,
  } = props;

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setDashboardFilters([]);
    setTransactionTableFilter({
      page: 1,
      perPage: 25,
      search: "",
      order: "desc",
      handlePagination: true,
    });
    setDailyStatsFilter({
      page: 1,
      perPage: 25,
      orderBy: "desc",
      filter: "SUCCESSFULL_LOGIN",
    });
    setUserDataTableFilter({
      page: 1,
      perPage: 25,
      search: "",
      order: "desc",
      handlePagination: true,
    });
    setSelectedFilters({
      startDate: "",
      endDate: "",
      empCode: "",
      designation: "",
      sessions: "",
      txnStatus: "",
      factory: "",
      reason: "",
      name: "",
      Amount: "",
    });
    setWave(0);
    setDateRange([null, null]);
    setDateRangeTransaction([null, null]);
  };

  return (
    <>
      <Box>
        <TabContext value={tabvalue}>
          <Box>
            <TabList
              className="flex items-end"
              aria-label="lab API tabs example"
              onChange={handleTabChange}
              sx={{
                "& .MuiTabs-indicator": {
                  display: "none",
                },
              }}
            >
              <Tab
                label={
                  <div
                    className={`rounded-t-lg text-sm  font-bold ${
                      tabvalue === "1"
                        ? "bg-[#E8EBFA] text-[#3D3E78] opacity-100"
                        : "text-[#3D3E78] bg-[#F0F0F0] opacity-70"
                    } capitalize  whitespace-nowrap px-3 py-4 max-md:px-2  
 flex items-center justify-center`}
                  >
                    <p
                      className={`text-md max-lg:text-sm  ${
                        tabvalue === "1" ? "font-semibold" : "font-normal"
                      }`}
                    >
                      Daily Stats
                    </p>
                  </div>
                }
                value="1"
                className="self-end p-0 mr-2 rounded-t-lg"
              />
              <Tab
                label={
                  <div
                    className={`rounded-t-lg text-sm  font-bold ${
                      tabvalue === "2"
                        ? "bg-[#E8EBFA] text-[#3D3E78] opacity-100"
                        : "text-[#3D3E78] bg-[#F0F0F0] opacity-70"
                    } capitalize gap-2  whitespace-nowrap px-3 py-4 max-md:px-2 
 flex items-center justify-center`}
                    onChange={handleTabChange}
                  >
                    <p
                      className={`text-md  max-lg:text-sm ${
                        tabvalue === "2" ? "font-semibold" : "font-normal"
                      }`}
                    >
                      User Data
                    </p>
                  </div>
                }
                value="2"
                className="self-end p-0 mr-2 rounded-t-lg"
              />
              <Tab
                label={
                  <div
                    className={`rounded-t-lg text-sm font-bold ${
                      tabvalue === "3"
                        ? "bg-[#E8EBFA] text-[#3D3E78] opacity-100"
                        : "text-[#3D3E78] bg-[#F0F0F0] opacity-70"
                    } capitalize gap-2  whitespace-nowrap py-4   px-3 max-md:px-2  
 flex items-center justify-center`}
                    onChange={handleTabChange}
                  >
                    <p
                      className={`text-md max-lg:text-sm  ${
                        tabvalue === "3" ? "font-semibold" : "font-normal"
                      }`}
                    >
                      Transaction details
                    </p>
                  </div>
                }
                value="3"
                className="self-end p-0 mr-2 rounded-t-lg"
              />
            </TabList>
          </Box>
        </TabContext>
      </Box>
    </>
  );
};

export default TabPanel;
