import { useEffect, useState, useMemo } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useWatch } from "react-hook-form";
import CrossIcon from "../../assets/Icons/CrossIcon.svg";
import SaveIcon from "../../assets/Icons/SaveIcon";
import DataTableCustom from "../DataTableCustom";

import {
  Box,
  Divider,
  Stack,
  TextField,
  Typography,
  MenuItem,
  Button,
  InputAdornment,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useAddEnumerator } from "pages/ManageEmployees/manageEmployeeQueries";

const AddEnumeratorFormSchema = yup.object().shape({
  name: yup.string().required("Full Name is a required field"),
  factoryCode: yup.string().required("Factory Code is a required field"),
  employeeId: yup
    .string()
    .required("Enumerator ID is a required field")
    .min(2, "Enumerator Id must at least 6 characters")
    .max(10, "Password must not exceed 10 character"),
  password: yup
    .string()
    .required("Password is a required field")
    .min(8, "Password must be at least 8 characters")
    .max(20, "Password must not exceed 20 characters"),
  phoneNumber: yup.string().required("Phone Number is required Field"),
});

const AddEnumeratorForm = ({ closeDialog }) => {
  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    watch,
    trigger,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(AddEnumeratorFormSchema) });
  const [fullName, setFullName] = useState("");
  const [grantInactiveUser, setGrantInactiveUser] = useState({});
  const [apiErrorMessage, setErrorMessage] = useState({});

  const values = [
    {
      value: "M21",
      label: "M21",
    },
    {
      value: "M03",
      label: "M03",
    },
    {
      value: "49M",
      label: "49M",
    },
    {
      value: "37M",
      label: "37M",
    },
  ];

  const name = useWatch({
    control,
    name: "name",
  });

  useEffect(() => {
    // Trigger validation for the "name" field when its value changes
    if (name?.length <= 1) {
      trigger("name");
    }
  }, [name]);

  const star = " * ";

  const { mutate } = useAddEnumerator();

  const onSubmit = (data) => {
    data = { ...data, phoneNumber: "+91" + data.phoneNumber };
    mutate(data, {
      onSuccess: () => {
        setErrorMessage({});
        closeDialog();
      },
      onError: (error) => {
        const apiErrosMessages = {};
        error?.data?.error?.map((errorData) => {
          apiErrosMessages[errorData?.field] = errorData?.message;
        });
        setErrorMessage(apiErrosMessages);
      },
    });
  };

  useEffect(() => {});
  return (
    <>
      <div className="w-full bg-white rounded-lg">
        <Box className="flex justify-between px-[35px] pt-[19px] pb-[15px]">
          <Typography className="text-center text-[22px] font-semibold tracking-tighter text-[#070707]">
            GBL Supervisor Data
          </Typography>
          <div
            className="w-[24px] h-[24px] hover:cursor-pointer"
            onClick={closeDialog}
          >
            <img src={CrossIcon} className="w-[24px] h-[24px]" />
          </div>
        </Box>
        <Divider />
        <div className="overflow-x-hidden">
          <form id="login-form" onSubmit={handleSubmit(onSubmit)}>
            <Stack
              direction="column"
              spacing="24px"
              className="px-[80px] pt-[20px] pb-[30px]"
            >
              <Box className="flex justify-between items-center">
                <label
                  htmlFor=""
                  className="text-[#1B2559] text-[16px]  font-[700] leading-[100%]"
                >
                  Full Name <span className="text-red-500">{star}</span>:
                </label>
                <TextField
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      height: "50px",
                      width: "300px",
                      borderRadius: "10px",
                    },
                  }}
                  {...register("name")}
                  type="text"
                  className="rounded-lg"
                  inputProps={{
                    inputMode: "text",
                    pattern: "^[A-Za-z -]+$",
                  }}
                  error={Boolean(errors?.name?.message)}
                  helperText={errors?.name?.message}
                />
              </Box>
              <Box className="flex justify-between items-center">
                <label
                  htmlFor=""
                  className="text-[#1B2559] text-[16px]  font-[700] leading-[100%]"
                >
                  Factory Code<span className="text-red-500">{star}</span>:
                </label>
                <TextField
                  id="outlined-select-currency"
                  select
                  // defaultValue={values[0].value}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      height: "50px",
                      width: "300px",
                      borderRadius: "10px",
                    },
                  }}
                  className="rounded-lg"
                  {...register("factoryCode")}
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={Boolean(errors?.factoryCode?.message)}
                  helperText={errors?.factoryCode?.message}
                >
                  {values.map((option) => (
                    <MenuItem key={option?.value} value={option?.value}>
                      {option?.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              <Box className="flex justify-between items-center">
                <label
                  htmlFor=""
                  className="text-[#1B2559] text-[16px]  font-[700] leading-[100%]"
                >
                  GBL Supervisor ID <span className="text-red-500">{star}</span>
                  :
                </label>
                <TextField
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      height: "50px",
                      width: "300px",
                      borderRadius: "10px",
                    },
                  }}
                  {...register("employeeId")}
                  type="text"
                  className="rounded-lg"
                  error={
                    Boolean(apiErrorMessage["employeeId"]) ||
                    Boolean(errors?.employeeId?.message)
                  }
                  helperText={
                    apiErrorMessage["employeeId"] || errors?.employeeId?.message
                  }
                />
              </Box>
              <Box className="flex justify-between items-center">
                <label
                  htmlFor=""
                  className="text-[#1B2559] text-[16px]  font-[700] leading-[100%]"
                >
                  Password <span className="text-red-500">{star}</span>:
                </label>
                <TextField
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      height: "50px",
                      width: "300px",
                      borderRadius: "10px",
                    },
                  }}
                  {...register("password")}
                  type="text"
                  className="rounded-lg"
                  inputProps={{
                    inputMode: "text",
                  }}
                  error={Boolean(errors?.password?.message)}
                  helperText={errors?.password?.message}
                />
              </Box>

              <Box className="flex justify-between items-center">
                <label
                  htmlFor=""
                  className="text-[#1B2559] text-[16px]  font-[700] leading-[100%]"
                >
                  Phone number<span className="text-red-500">{star}</span>:
                </label>
                <TextField
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      height: "50px",
                      width: "300px",
                      borderRadius: "10px",
                    },
                  }}
                  {...register("phoneNumber")}
                  type="text"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+91</InputAdornment>
                    ),
                  }}
                  className="rounded-lg"
                  error={
                    Boolean(apiErrorMessage["phoneNumber"]) ||
                    Boolean(errors?.phoneNumber?.message)
                  }
                  helperText={
                    apiErrorMessage["phoneNumber"] ||
                    errors?.phoneNumber?.message
                  }
                />
              </Box>
            </Stack>
            {/* <Box className="pl-[80px] pr-[15px]">
              <DataTableCustom
                data={data}
                columns={columns}
                // inActiveBulk
                updateFilters={setGrantInactiveUser}
                //paginationInformation={{}}
                // setGrantInactiveUser={setGrantInactiveUser}
                // setSearchBulkData={setSearchBulkData}
                //  isSearch
                headerForDailyStats="Users"
                isDialogBox={true}
                isChecked
              />
            </Box> */}
          </form>
        </div>
        <Divider />
        <Box className="flex justify-center py-[32px]">
          <Button
            variant="contained"
            className="capitalize h-[48px] w-[164px]"
            startIcon={<SaveIcon />}
            type="submit"
            onClick={handleSubmit(onSubmit)}
          >
            <p>Save</p>
          </Button>
        </Box>
      </div>
    </>
  );
};

export default AddEnumeratorForm;

// {
//   "name": "kapil",
//   "employeeId": "12345678",
//   "password": "Workerdiary@2023",
//   "gender": "male",
//   "department": "rerum nisi quia",
//   "designation": "dolore dolores fugit",
//   "factoryCode": "F21",
//   "phoneNumber": "+919876543210",
//   "workerIds": [
//     10,
//     11,
//     12
//   ],
//   "waveId": 1
// }
