import Inflow from "assets/Images/statistics/inflow.png";
import GraphContainer from "components/StatisticsCutomComponents/GraphContainer";
import apiStatsKey from "constants/apiKeys";
import paramsKey from "constants/paramsKey";
import useControlParams from "hooks/useControlParams";
import { useGetStats } from "pages/Dashboard/dashboardQueries";

export function getChangeKey(filter) {
  if (filter === "All") {
    return "month";
  } else if (filter === "Weekly") {
    return "date";
  } else if (filter === "Monthly") {
    return "start";
  } else if (filter === "Daily") {
    return "hour";
  }
}

export default function InflowTransactions() {
  const { getParamValue, navigateWithParams, availableParams, paramParser } =
    useControlParams();
  const { data: InflowData } = useGetStats(
    paramParser({
      ...availableParams(),
      category: getParamValue(paramsKey.inflow),
    }),
    apiStatsKey.inflow
  );
  const changedKey = getChangeKey(getParamValue(paramsKey.filterKey));

  return (
    <GraphContainer
      graphHeadLine={{ icon: Inflow, heading: "Inflow transactions" }}
      firstgraph={{
        data:
          changedKey === "hour"
            ? InflowData?.data?.[0]?.transactions // getting different response structur from BE on daily filters
            : InflowData?.data,
        xdatakey: changedKey,
        ydatakey: "amount",
      }}
      secondGraph={{
        data:
          changedKey === "hour"
            ? InflowData?.data?.[0]?.transactions // getting different response structur from BE on daily filters
            : InflowData?.data,
        xdatakey: changedKey,
        ydatakey: "numberOfTransactions",
      }}
      dropdownOptions={[
        "Income from Shahi",
        "Income from part-time work",
        "Income from other sources",
        "Transfers/Received borrowed money",
        "Gift/Credit at Shop",
        "Loan from individuals",
        "Loan from institutions",
        "Borrowing from family and friends",
        "Borrowing from others",
      ]}
      heading={"Category"}
      valueofDropdown={getParamValue(paramsKey.inflow) || ""}
      handleSelect={(value) => {
        navigateWithParams(paramsKey.inflow, value);
      }}
    />
  );
}
