import React from "react";
import { Typography } from "@mui/material";

import ManagementLogo from "assets/Icons/ManagementLogo.svg";
import DataLogo from "assets/Icons/DataLogo.svg";
import FileTextLogo from "assets/Icons/FileTextLogo.svg";
import { useNavigate } from "react-router";
import { getLocalStorageItem } from "hooks";
import { format } from "date-fns";

const AdminPortal = () => {
  const navigate = useNavigate();

  const handleClickDashBoard = () => {
    navigate("/dashboard");
  };

  const handleClickManagementLogo = () => {
    navigate("/manage-employees");
  };

  const handleClickStatistics = () => {
    navigate(
      `/study-statistics?startDate=2023-10-01&endDate=${format(
        new Date(),
        "yyyy-MM-dd"
      )}&filter=All`
    );
  };
  const user = getLocalStorageItem("USER_ROLE");

  return (
    <div className="flex max-lg:flex-col max-lg:pt-[10%] max-lg:gap-2  gap-10 justify-evenly  m-auto h-full max-w-[1200px]">
      <div item className="flex flex-col justify-center items-center">
        <div
          className="bg-[#ECF6FF] rounded-lg p-5  cursor-pointer shadow-md"
          onClick={handleClickDashBoard}
        >
          <img src={DataLogo} alt="Management Logo" />
        </div>

        <Typography className="main-tab-text" variant="h4">
          Data Dashboard
        </Typography>
      </div>
      {user === "SUPER_ADMIN" && (
        <div item className="flex flex-col items-center justify-center">
          <div
            className="bg-[#ECF6FF] rounded-lg p-5  cursor-pointer shadow-md"
            onClick={handleClickManagementLogo}
          >
            <img src={ManagementLogo} alt="Management Logo" />
          </div>

          <Typography className="main-tab-text" variant="h4">
            Manage Employees
          </Typography>
        </div>
      )}
      <div item className="flex flex-col items-center justify-center">
        <div
          className="bg-[#ECF6FF] rounded-lg p-5  cursor-pointer shadow-md"
          onClick={handleClickStatistics}
        >
          <img src={FileTextLogo} alt="Management Logo" />
        </div>

        <Typography className="main-tab-text" variant="h4">
          Study Statistics
        </Typography>
      </div>
    </div>
  );
};

export default AdminPortal;
