import { ArrowDropDown } from "@mui/icons-material";
import { useState } from "react";

export default function BarToggleWrapper({ title, children }) {
  const [toggle, setToggle] = useState(false);

  return (
    <>
      <div
        tabIndex={0}
        onClick={() => setToggle(!toggle)}
        className="flex self-start items-center justify-center py-3 px-2 gap-5 rounded cursor-pointer bg-[#E8Ebfa] border border-solid border-[#00000030]"
      >
        <p>
          {toggle ? "Hide" : "Show"} {title}
        </p>
        <ArrowDropDown />
      </div>
      {toggle && children}
    </>
  );
}
