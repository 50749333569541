import {
  Dialog,
  Divider,
  Paper,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  Card,
  CardContent,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CrossIcon from "../../assets/Icons/CrossIcon.svg";
import {
  useGetTransactionsExportData,
  useGetTransactionsExportDataUrl,
  useGetTransactionsPaginatedData,
} from "./dashboardQueries";
import { format } from "date-fns";

const ExportDBModal = ({
  openExportDb,
  handleCloseExportModal,
  currentFilters,
}) => {
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [yesTrigger, setYesTrigger] = useState(false);
  const {
    data: transactionDataPaginated,
    dataUpdatedAt: transactionsDataUpdateAt,
    isSuccess,
  } = useGetTransactionsExportData({
    ...currentFilters,
    language: selectedLanguage,
    isExport: true,
    yesTrigger: yesTrigger,
  });
  const { data: ExportUrls } = useGetTransactionsExportDataUrl();
  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };
  const handleTriggerExportAPI = () => {
    setYesTrigger(true);
    // handleCloseExportModal();
  };
  useEffect(() => {
    if (isSuccess) {
      handleCloseExportModal();
    }
  }, [isSuccess]);
  return (
    <Dialog
      open={openExportDb}
      onClose={handleCloseExportModal}
      maxWidth="658px"
    >
      <Paper className="w-[658px] rounded-lg shadow-md overflow-auto">
        <div className="w-full h-full bg-white shadow-md rounded-lg">
          <div className="flex justify-between px-[30px] py-[20px]">
            <Typography className="text-center text-[22px] font-semibold tracking-tighter text-[#070707]">
              Export All Transactions
            </Typography>
            <div
              className="w-[24px] h-[24px] hover:cursor-pointer"
              onClick={handleCloseExportModal}
            >
              <img
                src={CrossIcon}
                className="w-[24px] h-[24px]"
                alt="Close Icon"
              />
            </div>
          </div>
          <Divider />
          <div className="px-[30px] py-[20px] ">
            <div className="flex items-center justify-center">
              <p className="text-[#1B2559] text-[16px] font-[700] leading-[100%] mr-[20px]">
                Select language
              </p>
              <RadioGroup
                aria-label="language"
                name="language"
                value={selectedLanguage}
                onChange={handleLanguageChange}
                className="flex flex-row gap-[10px] items-center"
              >
                <FormControlLabel
                  value="en"
                  control={<Radio />}
                  label="English"
                />
                <FormControlLabel
                  value="kn"
                  control={<Radio />}
                  label="Kannada"
                />
              </RadioGroup>
            </div>

            {(ExportUrls?.data || [])?.map((dtx, idx) => {
              const formattedDate = format(
                new Date(dtx?.createdAt),
                "dd/MM/yyyy"
              );
              const formattedTime = format(new Date(dtx?.createdAt), "h:mm a");
              console.log("ExportUrls", dtx?.createdAt, formattedTime);

              return (
                <Card key={idx} className="mt-4">
                  <CardContent className="flex items-center">
                    <Typography
                      variant="body1"
                      className="flex-grow select-none"
                    >
                      {dtx?.name} - {formattedDate} {formattedTime}
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => window.open(dtx?.url, "_self")}
                    >
                      Download
                    </Button>
                  </CardContent>
                </Card>
              );
            })}
            <div className="flex justify-center mt-4">
              <Button
                variant="contained"
                color="primary"
                className="px-4"
                onClick={handleTriggerExportAPI}
              >
                Export
              </Button>
            </div>
          </div>
        </div>{" "}
      </Paper>
    </Dialog>
  );
};

export default ExportDBModal;
