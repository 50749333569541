import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Divider,
  Button,
  Typography,
} from "@mui/material";
import { useGetBulkSearchData } from "pages/ManageEmployees/manageEmployeeQueries";
import { useForm } from "react-hook-form";
import { BsCheck2Circle } from "react-icons/bs";

const BulkSearchModal = (props) => {
  const { open, handleClose, setSearchBulkData } = props;

  // queries
  const { mutate: bulkSearchData } = useGetBulkSearchData();

  // useForm
  const { register, handleSubmit, reset } = useForm();

  //submit-form-handler
  const submitBulkSearchForm = (formData) => {
    const formattedData = formData?.employeeCodes.replace(/\n/g, ",");
    // const formattedData = formData?.employeeCodes
    //   .split(",")
    //   .map((item) => item.trim())
    //   .join(",");
    const payload = {
      employeeIds: formattedData,
      status: "INACTIVE",
    };
    bulkSearchData(payload, {
      onSuccess: (res) => setSearchBulkData(res?.data),
    });
    handleClose();
    reset();
  };
  //

  return (
    <div>
      <Dialog open={open} fullWidth maxWidth="md">
        <DialogTitle className="font-semibold" color="primary">
          <Grid
            container
            xs={12}
            className="flex max-[850px]:flex-col justify-between items-center"
          >
            <Grid
              item
              className="font-semibold max-[850px]:items-centerleading-[150%] text-[26px] text-[#333B7A]"
            >
              Bulk Search
            </Grid>
            <Grid item>
              <Grid container className="flex justify-between gap-4">
                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={handleClose}
                    className="leading-[150%] font-semibold text-[18px] capitalize shadow-md rounded-lg w-[277px] h-[51px]  text-black"
                  >
                    Cancel
                  </Button>
                </Grid>

                <Grid item>
                  <Button
                    type="submit"
                    form="search-form"
                    variant="standard"
                    startIcon={<BsCheck2Circle />}
                    // onClick={handleGrantAccess}
                    className="leading-[150%] w-[277px] h-[51px] capitalize font-semibold text-[18px] rounded-lg  py-2 text-white bg-[#3D3E78]"
                  >
                    Confirm
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider
          variant="middle"
          sx={{
            bgcolor: "#333B7A",
          }}
        />
        <DialogContent>
          <form onSubmit={handleSubmit(submitBulkSearchForm)} id="search-form">
            <Grid
              conatiner
              sx={12}
              className="flex max-[850px]:flex-col lg:flex-row md:justify-between"
            >
              <Grid item className="text-center">
                <Typography
                  fontSize={"20px"}
                  fontWeight={700}
                  lineHeight={"30px"}
                >
                  Employee Ids
                </Typography>
              </Grid>
              <Grid item>
                <textarea
                  style={{
                    resize: "none",

                    width: "650px",
                  }}
                  id="textarea-example"
                  // value={text}
                  {...register("employeeCodes")}
                  // onChange={handleTextChange}
                  rows={12} // Set the number of rows to display by default
                  cols={80} // Set the number of columns to display by default
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default BulkSearchModal;
