import FoodIcon from "assets/Images/statistics/nofood.png";
import apiStatsKey from "constants/apiKeys";
import useControlParams from "hooks/useControlParams";
import { useGetStats } from "pages/Dashboard/dashboardQueries";
import GraphCardWrapper from "components/StatisticsCutomComponents/GraphCardWrapper";
import PieChart from "components/StatisticsCutomComponents/PieChart";

export default function ModeOfPaymentsSection() {
  const { availableParams } = useControlParams();
  const { data: ModeOfPaymentsData } = useGetStats(
    availableParams(),
    apiStatsKey.paymentMode
  );
  return (
    <GraphCardWrapper
      graphHeadLine={{ icon: FoodIcon, heading: "Mode of payments" }}
    >
      <div className="flex">
        <div className="flex-1">
          <PieChart
            colorOfPiechart="#B40FE7"
            data={ModeOfPaymentsData?.data?.pie || []}
            datakey={"percentages"}
            namekey={"name"}
          />
        </div>
        <div className="flex-1"></div>
      </div>
    </GraphCardWrapper>
  );
}
