import LoadingButton from '@mui/lab/LoadingButton';

const Button = ({
  loading,
  loadingPosition,
  variant,
  children,
  disableElevation,
  ...rest
}) => {
  return (
    <LoadingButton
      loading={loading}
      loadingPosition={loadingPosition}
      variant={variant}
      disableElevation={disableElevation}
      {...rest}
    >
      {children}
    </LoadingButton>
  );
};

export default Button;

Button.defaultProps = {
  loading: false,
  loadingPosition: 'end',
  variant: 'contained',
  disableElevation: true,
};
