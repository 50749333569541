export function DownloadIcon() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.2344 15V19C21.2344 19.5304 21.0237 20.0391 20.6486 20.4142C20.2735 20.7893 19.7648 21 19.2344 21H5.23438C4.70394 21 4.19523 20.7893 3.82016 20.4142C3.44509 20.0391 3.23438 19.5304 3.23438 19V15"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.23438 10L12.2344 15L17.2344 10"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.2344 15V3"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
