const paramsKey = {
  filterKey: "filter",
  factoryKey: "factory",
  waveKey: "waveId",
  genderKey: "gender",
  startDateKey: "startDate",
  endDateKey: "endDate",
  inflow: "inflow_transactions",
  outflow: "outflow_transactions",
  income: "income",
  saving: "saving",
  currentMonth: "current_month",
};

export default paramsKey;
