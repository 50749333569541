import TopNav from "components/TopNav";

const Layout = ({ children }) => {
  return (
    <div className="h-[100vh] relative max-h-[100vh] flex flex-col">
      <div className="header w-full sticky top-0 z-50">
        <TopNav />
      </div>
      <div className="bg-white h-[85vh] max-h-[85vh] w-full ">{children}</div>
    </div>
  );
};

export default Layout;
