import CardWrapper from "./CardWrapper";

export default function StatisticsInfoCard({ cardData }) {
  return (
    <CardWrapper>
      <div className="flex gap-4 p-5">
        {cardData?.length > 0 &&
          cardData?.map((item, index) => (
            <div key={index} className="flex-1 flex gap-4">
              {Object.keys(item).length !== 0 && (
                <>
                  <div>
                    <img src={item?.icon} alt="icon" className="w-6" />
                  </div>
                  <div>
                    <h3 className="text-sm">{item?.number}</h3>
                    <p className="text-xs">{item?.decription}</p>
                  </div>
                </>
              )}
            </div>
          ))}
      </div>
    </CardWrapper>
  );
}
