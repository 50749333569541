import SavingSvg from "assets/Images/statistics/saving.png";
import BarGraph from "components/StatisticsCutomComponents/BarGraph";
import GraphCardWrapper from "components/StatisticsCutomComponents/GraphCardWrapper";
import apiStatsKey from "constants/apiKeys";
import paramsKey from "constants/paramsKey";
import useControlParams from "hooks/useControlParams";
import { useGetStats } from "pages/Dashboard/dashboardQueries";
import { getChangeKey } from "./InflowTransactions";

export default function SavingsSection() {
  const { getParamValue, navigateWithParams, availableParams, paramParser } =
    useControlParams();

  const { data: SavingsData } = useGetStats(
    paramParser({
      ...availableParams(),
      category: getParamValue(paramsKey.saving),
    }),
    apiStatsKey.savings
  );

  const changedKey = getChangeKey(getParamValue(paramsKey.filterKey));

  return (
    <GraphCardWrapper
      graphHeadLine={{ icon: SavingSvg, heading: "Saving" }}
      dropdownOptions={["Savings/Investments"]}
      heading={"Category"}
      valueofDropdown={getParamValue(paramsKey.saving) || ""}
      handleSelect={(value) => {
        navigateWithParams(paramsKey.saving, value);
      }}
    >
      <BarGraph
        xdatakey={changedKey}
        data={
          changedKey === "hour" // getting different response structur from BE on daily filters
            ? SavingsData?.data?.bars?.[0]?.transactions
            : SavingsData?.data?.bars
        }
        ydatakey={"numberOfTransactions"}
        bgcolor={"#4C96A6"}
      />
    </GraphCardWrapper>
  );
}
