import AxiosInstance from "./axiosInstance";

export const refreshTokenHandler = (token) => {
  const method = "PUT";
  let url = `api/v1/auth/refresh?token=${token}`;

  return AxiosInstance({
    method,
    url,
  });
};
