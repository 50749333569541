import paramsKey from "constants/paramsKey";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { getMonth } from "utils/dateFunctions";

function paramsParser(searchParams) {
  const allParams = {};
  searchParams?.[0]?.forEach((value, key) => {
    allParams[key] = value;
  });
  return allParams;
}

export default function useControlParams() {
  const searchParams = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();

  function navigateWithParams(key, value) {
    navigate(
      location?.pathname +
        "?" +
        new URLSearchParams({
          ...paramsParser(searchParams),
          [key]: value,
        })
    );
  }

  function navigateWithMultipleParams(valueObj) {
    const paramObj = paramsParser(searchParams);
    const finalObj = { ...paramObj, ...valueObj };
    navigate(location?.pathname + "?" + new URLSearchParams(finalObj));
  }

  function getParamValue(key) {
    return searchParams?.[0]?.get(key);
  }

  function paramParser(params) {
    const keys = Object.keys(params);
    const finalParams = {};
    keys.forEach((key) => {
      if (params[key] !== "null") {
        finalParams[key] = params[key];
      }
    });
    return finalParams;
  }

  function availableParams() {
    return paramParser({
      start: getParamValue(paramsKey.startDateKey),
      end: getParamValue(paramsKey.endDateKey),
      waveId: getParamValue(paramsKey.waveKey),
      year: new Date().getFullYear(),
      occurrence: getParamValue(paramsKey.filterKey)?.toUpperCase(),
      gender: getParamValue(paramsKey.genderKey),
      month: getMonth(getParamValue(paramsKey.startDateKey)),
      factory: getParamValue(paramsKey.factoryKey),
    });
  }

  return {
    navigateWithParams,
    getParamValue,
    navigateWithMultipleParams,
    availableParams,
    paramParser,
  };
}
