import { useState, forwardRef } from 'react';
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  IconButton,
  TextField,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const CustomTextField = forwardRef((props, ref) => {
  //  states
  const [showPassword, setShowPassword] = useState(false);
  //Handlers
  const handleToggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const {
    label,
    variant,
    type,
    error,
    helperText,
    className,
    inputRef,
    required,
    fullWidth,
    size,
    name,
    labelColor,
    disabled,
    startAdornment,
    endAdornment,
    capitalize,
    ...restOfTextFieldProps
  } = props;
  return (
    <FormControl
      className={`${className} ${fullWidth ? 'w-full' : ''} mui-textfield`}
    >
      <TextField
        type={type}
        className="text-[#333B7A]"
        capitalize={capitalize}
        fullWidth={fullWidth}
        {...restOfTextFieldProps}
        inputRef={inputRef}
        name={name}
        size={size}
        disabled={disabled}
        label={label}
        variant={variant}
        color={labelColor}
        error={Boolean(error)}
        InputProps={{
          startAdornment: startAdornment ? startAdornment : null,
          endAdornment: (
            <>
              {type === 'password' ? (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleToggleShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    size="large"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ) : endAdornment ? (
                endAdornment
              ) : null}
            </>
          ),
        }}
      />
      <FormHelperText error={Boolean(error)} margin="dense" variant={variant}>
        {helperText}
      </FormHelperText>
    </FormControl>
  );
});
CustomTextField.defaultProps = {
  label: '',
  variant: 'outlined',
  ref: null,
  type: 'text',
  error: false,
  helperText: '',
  className: null,
  inputRef: null,
  required: false,
  fullWidth: true,
  size: 'medium',
  disabled: false,
};

export default CustomTextField;
