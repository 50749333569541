import IncomeSvg from "assets/Images/statistics/income.png";
import GraphCardWrapper from "components/StatisticsCutomComponents/GraphCardWrapper";
import paramsKey from "constants/paramsKey";
import useControlParams from "hooks/useControlParams";
import { getChangeKey } from "./InflowTransactions";
import { useGetStats } from "pages/Dashboard/dashboardQueries";
import apiStatsKey from "constants/apiKeys";
import BarGraph from "components/StatisticsCutomComponents/BarGraph";

export default function IncomeSection() {
  const { getParamValue, navigateWithParams, availableParams, paramParser } =
    useControlParams();

  const changedKey = getChangeKey(getParamValue(paramsKey.filterKey));

  const { data: IncomeData } = useGetStats(
    paramParser({
      ...availableParams(),
      category: getParamValue(paramsKey.income),
    }),
    apiStatsKey.income
  );

  return (
    <GraphCardWrapper
      graphHeadLine={{ icon: IncomeSvg, heading: "Income" }}
      dropdownOptions={[
        "Income from Shahi",
        "Income from part-time work",
        "Income from other sources",
        "Income as Gift",
      ]}
      heading={"Category"}
      valueofDropdown={getParamValue(paramsKey.income) || ""}
      handleSelect={(value) => {
        navigateWithParams(paramsKey.income, value);
      }}
    >
      <BarGraph
        dxY={-40}
        xdatakey={changedKey}
        data={
          changedKey === "hour" // getting different response structur from BE on daily filters
            ? IncomeData?.data?.bars?.[0]?.transactions
            : IncomeData?.data?.bars
        }
        ydatakey={"amount"}
        bgcolor={"#A64C84"}
      />
    </GraphCardWrapper>
  );
}
