import GraphContainer from "components/StatisticsCutomComponents/GraphContainer";
import Outflow from "assets/Images/statistics/outflow.png";
import useControlParams from "hooks/useControlParams";
import paramsKey from "constants/paramsKey";
import { useGetStats } from "pages/Dashboard/dashboardQueries";
import { getChangeKey } from "./InflowTransactions";
import apiStatsKey from "constants/apiKeys";

export default function OutFlowTransactions() {
  const { getParamValue, navigateWithParams, availableParams, paramParser } =
    useControlParams();
  const { data: OutflowData } = useGetStats(
    paramParser({
      ...availableParams(),
      category: getParamValue(paramsKey.outflow),
    }),
    apiStatsKey.outflow
  );

  const changedKey = getChangeKey(getParamValue(paramsKey.filterKey));

  return (
    <GraphContainer
      graphHeadLine={{ icon: Outflow, heading: "Outflow transactions" }}
      firstgraph={{
        data:
          changedKey === "hour"
            ? OutflowData?.data?.[0]?.transactions // getting different response structur from BE on daily filters
            : OutflowData?.data,
        xdatakey: changedKey,
        ydatakey: "amount",
        bgColor: "#FFE0E0",
      }}
      secondGraph={{
        data:
          changedKey === "hour"
            ? OutflowData?.data?.[0]?.transactions // getting different response structur from BE on daily filters
            : OutflowData?.data,
        xdatakey: changedKey,
        ydatakey: "numberOfTransactions",
        bgColor: "#FFE0E0",
      }}
      dropdownOptions={[
        "Food expenses",
        "Non-food expenses",
        "Insurance",
        "Savings/Investments",
        "Credit",
        "Loan/Borrowing repayments",
      ]}
      heading={"Category"}
      valueofDropdown={getParamValue(paramsKey.outflow) || ""}
      handleSelect={(value) => {
        navigateWithParams(paramsKey.outflow, value);
      }}
    />
  );
}
