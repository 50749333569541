import React, { useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";
import customVariable from "constants/customVariable";
import { useQueryClient } from "@tanstack/react-query";
import { useGetEnumeratorsIds } from "pages/ManageEmployees/manageEmployeeQueries";

import { Grid, Select, MenuItem } from "@mui/material";

const SelectWavesModal = ({
  value,
  setDataForEmployeeDetail,
  handleCloseDialog,
  setSelectedWaveEnuIds,
  empIds,
}) => {
  const queryClient = useQueryClient();
  const [waveValue, setWaveValue] = useState("Wave 1");
  const [selectedEnumerator, setSelectedEnumerator] = useState("");

  const handleChange = (event) => {
    setWaveValue(event.target.value);
    setSelectedWaveEnuIds((prev) => {
      return { ...prev, waveId: Number(event.target.value) };
    });
  };

  const handleCancelClick = () => {
    handleCloseDialog();
  };

  const waves = queryClient.getQueryData(["waves-list"]);
  const { data, isLoading } = useGetEnumeratorsIds();
  if (isLoading) return <>Loading...</>;

  const handleDoneClick = () => {
    setDataForEmployeeDetail(waveValue);
  };

  return (
    <div className="w-full h-full">
      <Grid item className="pb-[1rem]">
        <FormControl>
          <FormLabel
            id="demo-radio-buttons-group-label"
            className="font-semibold text-[18px]"
          >
            Please select the enumerator
          </FormLabel>
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl>
          <Select
            labelId="my-select-placeholder"
            id="demo-simple-select"
            value={selectedEnumerator}
            onChange={(e) => {
              setSelectedWaveEnuIds((prev) => {
                return { ...prev, enumeratorId: Number(e.target.value) };
              });
              setSelectedEnumerator(e.target.value);
            }}
            className="min-w-[160px] h-[40px] rounded-[10px] bg-[#FFFFFF99] border-[1px] border-[#fff]"
            displayEmpty
            defaultValue=""
            sx={{ paddingTop: "23px", paddingBottom: "23px" }}
            MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
          >
            <MenuItem disabled value="">
              Select Enumerator
            </MenuItem>
            {data.data.map((data) => (
              <MenuItem key={data.id} value={data.id}>
                <span className="capitalize">{data.name}</span>-
                <span>({data.id})</span>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <FormControl className="w-full mt-[1rem]">
        <FormLabel
          id="demo-radio-buttons-group-label"
          className="font-semibold text-[18px]"
        >
          Please select the wave
        </FormLabel>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue={waveValue}
          name="radio-buttons-group"
          spacing="200px"
          className="gap-[3px] mt-[5px]"
          value={waveValue}
          onChange={handleChange}
        >
          {waves.data
            ?.sort((a, b) => a.id - b.id)
            .map(({ id, name }) => (
              <FormControlLabel
                key={id}
                value={id}
                control={<Radio />}
                label={name}
                style={{ fontSize: "18px", fontWeight: "500px" }}
              />
            ))}
        </RadioGroup>
      </FormControl>
      <div className="flex justify-between py-2">
        <Button
          variant="outlined"
          className=""
          disabled={!(waveValue && selectedEnumerator)}
          onClick={() => {
            handleDoneClick(waveValue);
          }}
        >
          Done
        </Button>
        <Button variant="outlined" onClick={() => handleCancelClick()}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default SelectWavesModal;
