// import type { AutocompleteProps as IAutocompleteProps } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import React, { forwardRef } from 'react';

import CustomTextField from 'components/CustomTextField';

// export type ISelectfield = IAutocompleteProps & {
//   options: any[];
// };

const LabelledSelectField = forwardRef((props, ref) => {
  const {
    label,
    size,
    placeholder,
    options,
    multiple,
    required,
    disableCloseOnSelect,
    openOnFocus,
    disabled,
    blurOnSelect,
    error,
    helperText,
    labelOverFlow,
    ...restOfSelectFieldProps
  } = props;

  return (
    <div className="flex flex-col">
      <Autocomplete
        disableCloseOnSelect={multiple && disableCloseOnSelect}
        fullWidth
        sx={{ whiteSpace: 'pre-wrap', p: 0, m: 0, width: '100%' }}
        multiple={multiple}
        size={size}
        options={options}
        openOnFocus={openOnFocus}
        disabled={disabled}
        blurOnSelect={blurOnSelect}
        renderInput={(params) => (
          <CustomTextField
            required={required}
            label={label}
            {...params}
            labelOverFlow={labelOverFlow}
            placeholder={placeholder}
            error={error}
            helperText={helperText}
            {...{ ref }}
          />
        )}
        {...restOfSelectFieldProps}
      />
    </div>
  );
});

LabelledSelectField.defaultProps = {
  label: '',
  ref: null,
  placeholder: '',
  options: [],
  size: 'medium',
  required: false,
  multiple: false,
  disableCloseOnSelect: true,
  openOnFocus: false,
  blurOnSelect: true,
  error: false,
  helperText: '',
};

export default LabelledSelectField;

LabelledSelectField.displayName = 'Labelled-Select-field';
