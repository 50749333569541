import { Button } from "@mui/material";
import React from "react";

export default function DailyStatsTab({
  contain,
  label,
  value,
  clickHandler = () => {},
}) {
  return (
    <Button
      onClick={() => clickHandler()}
      variant={contain ? "contained" : "outlined"}
      className={`capitalize font-semibold flex items-center whitespace-nowrap gap-1 px-3 ${
        contain && "hover:bg-[#3D3E78]"
      }  max-[1100px]:justify-start text-[14px]`}
    >
      <span className="font-[dm-sans] text-xl font-[700]">{value}</span>
      {label}
    </Button>
  );
}
