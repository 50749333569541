import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import React, { forwardRef, useState } from 'react';

const LabelledTextFieldWithAdornments = forwardRef((props, ref) => {
  const {
    label,
    variant,
    type,
    startAdornment,
    endAdornment,
    error,
    helperText,
    className,
    inputRef,
    required,
    fullWidth,
    size,
    ...restOfTextFieldProps
  } = props;

  const [showPassword, setShowPassword] = useState(false);

  const handleToggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <FormControl
      className={`${className} ${fullWidth ? 'w-full' : ''} mui-textfield`}
    >
      <TextField
        label={label}
        className="text-[#333B7A]"
        type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
        variant={variant}
        InputProps={{
          endAdornment: (
            <>
              {type === 'password' ? (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleToggleShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    size="large"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ) : endAdornment ? (
                endAdornment
              ) : null}
            </>
          ),
          startAdornment: startAdornment ? startAdornment : null,
        }}
        fullWidth={fullWidth}
        {...restOfTextFieldProps}
        inputRef={inputRef}
        size={size}
        error={Boolean(error)}
      />
      <FormHelperText error={Boolean(error)} margin="dense" variant={variant}>
        {helperText}
      </FormHelperText>
    </FormControl>
  );
});

LabelledTextFieldWithAdornments.defaultProps = {
  label: '',
  variant: 'outlined',
  ref: null,
  startAdornment: null,
  endAdornment: null,
  type: 'text',
  error: false,
  helperText: '',
  className: null,
  inputRef: null,
  required: false,
  fullWidth: true,
  size: 'medium',
};

export default LabelledTextFieldWithAdornments;
