import { useEffect, useState } from "react";
import Values from "values.js";

export default function useTintGernator(color) {
  const [shades, setShades] = useState([]);
  useEffect(() => {
    function fetchShades() {
      let shades = new Values(color).tints();
      setShades(shades.map((item) => `#${item.hex}`));
    }
    fetchShades();
  }, [color]);
  return shades;
}
