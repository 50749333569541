import React, { useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import customVariable from "constants/customVariable";
import { useQueryClient } from "@tanstack/react-query";
import {
  useProvideAccess,
  useUpdateWaves,
} from "pages/ManageEmployees/manageEmployeeQueries";

import { Grid, Select, MenuItem } from "@mui/material";

const ActivateWaveModal = ({ handleCloseDialog }) => {
  const queryClient = useQueryClient();
  const wavesData = queryClient.getQueryData(["waves-list"]);

  const { mutate } = useUpdateWaves();

  const handleChange = (wave) => {
    mutate({ id: wave.id, state: !wave.status });
  };

  const handleCancelClick = () => {
    handleCloseDialog();
  };

  return (
    <div className="w-full h-full">
      <Grid item>
        <FormControl>
          <FormLabel
            id="demo-radio-buttons-group-label"
            className="font-semibold text-[18px]"
          >
            Activate Current Waves:
          </FormLabel>
        </FormControl>
      </Grid>
      <Grid container>
        <FormControl component="fieldset" variant="standard">
          <FormGroup>
            {wavesData.data
              .sort((a, b) => a.id - b.id)
              .map((wave) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={wave.status}
                      onChange={() => handleChange(wave)}
                      name={wave.id}
                    />
                  }
                  label={wave.name}
                />
              ))}
          </FormGroup>
        </FormControl>
      </Grid>

      <div className="flex justify-between py-2 float-right">
        {/* <Button
          variant="outlined"
          className=""
          //disabled={!(waveValue && selectedEnumerator)}
          onClick={() => {
            handleDoneClick(waveValue);
          }}
        >
          Done
        </Button> */}
        <Button variant="outlined" onClick={() => handleCancelClick()}>
          DONE
        </Button>
      </div>
    </div>
  );
};

export default ActivateWaveModal;
