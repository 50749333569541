import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import paramsKey from "constants/paramsKey";
import { format } from "date-fns";
import useControlParams from "hooks/useControlParams";
import {
  convertMiliToDate,
  dailyMiliSecond,
  dateFormator,
  getDaysInMonth,
  getMiliSeconds,
  weekMiliSecond,
} from "utils/dateFunctions";

export default function CustomMonthDayWeekSelector() {
  const { getParamValue, navigateWithMultipleParams } = useControlParams();
  const dateObj = {
    startDate: getParamValue(paramsKey.startDateKey),
    endDate: getParamValue(paramsKey.endDateKey),
  };
  const currentMonth = Number(getParamValue(paramsKey.currentMonth));
  const totalNoOfDays = getDaysInMonth(currentMonth, new Date().getFullYear());
  const filterParamValue = getParamValue(paramsKey.filterKey);
  function handleDateFilter() {
    return `${dateFormator(dateObj.startDate, "dd MMM,yy")} - ${dateFormator(
      dateObj.endDate,
      "dd MMM,yy"
    )}`;
  }

  function returnMiliSecAccToFilter() {
    if (filterParamValue === "Weekly") {
      return weekMiliSecond;
    }
    if (filterParamValue === "Monthly") {
      return totalNoOfDays * dailyMiliSecond;
    }
    if (filterParamValue === "Daily") {
      return dailyMiliSecond;
    }
  }
  function getStartAndEndDateOfMonth(month) {
    const startDate = new Date(new Date().getFullYear(), month - 1, 1);
    const endDate = new Date(new Date().getFullYear(), month, 0);

    return {
      startDate: startDate,
      endDate: endDate,
    };
  }

  function handleDateLeftAndRight(leftOrRightKey) {
    const startDateMilli = getMiliSeconds(dateObj.startDate);
    const endDateMili = getMiliSeconds(dateObj.endDate);
    const params = {};
    if (filterParamValue !== "Monthly") {
      params.startDate = format(
        convertMiliToDate(
          leftOrRightKey === "left"
            ? startDateMilli - returnMiliSecAccToFilter()
            : startDateMilli + returnMiliSecAccToFilter()
        ),
        "yyyy-MM-dd"
      );
      params.endDate = format(
        convertMiliToDate(
          leftOrRightKey === "left"
            ? endDateMili - returnMiliSecAccToFilter()
            : endDateMili + returnMiliSecAccToFilter()
        ),
        "yyyy-MM-dd"
      );
    }

    params.current_month =
      leftOrRightKey === "left" ? currentMonth - 1 : currentMonth + 1;

    if (filterParamValue === "Monthly") {
      const curentMonthStartAndEndDate = getStartAndEndDateOfMonth(
        (leftOrRightKey === "left" ? currentMonth - 1 : currentMonth + 1) + 1
      );
      params.startDate = format(
        curentMonthStartAndEndDate?.startDate,
        "yyyy-MM-dd"
      );
      params.endDate = format(
        curentMonthStartAndEndDate?.endDate,
        "yyyy-MM-dd"
      );
    }
    navigateWithMultipleParams(params);
  }

  function handleLeftButton() {
    handleDateLeftAndRight("left");
  }
  function handleRightButton() {
    handleDateLeftAndRight("right");
  }

  return (
    <>
      {getParamValue(paramsKey.filterKey) !== "All" && (
        <div className="bg-[#E8Ebfa] border border-solid border-black/25 rounded flex items-stretch">
          <div
            tabIndex={0}
            onClick={handleLeftButton}
            className="flex items-center justify-center cursor-pointer px-1"
          >
            <ArrowLeft />
          </div>
          <div className="flex items-center justify-center whitespace-nowrap">
            {handleDateFilter()}
          </div>
          <div
            tabIndex={0}
            onClick={handleRightButton}
            className="flex items-center justify-center cursor-pointer px-1"
          >
            <ArrowRight />
          </div>
        </div>
      )}
    </>
  );
}
