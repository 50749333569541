import { ArrowDropDown } from "@mui/icons-material";
// #E8EBFA

export default function CustomDropdown({
  heading,
  options = [],
  handleSelect,
  value,
}) {
  return (
    <div className="relative">
      <ArrowDropDown className="absolute top-1/2 right-2 -translate-y-1/2" />
      <select
        onChange={(e) => {
          handleSelect(e.target.value);
        }}
        className=" bg-[#e8ebfa] border border-[#00000050] rounded-md px-2 py-3 text-md pr-10 h-full appearance-none min-w-[121px]"
        value={value}
      >
        <option value="null">{heading}</option>
        {options?.map((value, index) => (
          <option value={value} key={index}>
            {value}
          </option>
        ))}
      </select>
    </div>
  );
}
