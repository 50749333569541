import { useState } from "react";
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

export default function AlertModal({
  open,
  setOpen,
  handleClickOpen,
  singleOrAllText,
  handleClose,
}) {
  const [alertMessage, setAlertMessage] = useState("");

  React.useEffect(() => {
    if (singleOrAllText == "All") {
      setAlertMessage("All Workers removed successfully!");
    } else {
      setAlertMessage("Workers removed successfully!");
    }
  }, [singleOrAllText]);
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p className="text-[red] text-lg">{alertMessage}</p>
            Check the box again to undo the action.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
