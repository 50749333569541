import { number } from "yup";
import { string } from "yup/lib/locale";

const successfullLogin = [
  {
    employeeId: "E001",
    name: "John Doe",
    factoryCode: "F123",
    totalEntries: 42,
    lastSessionTime: "2023-09-25 14:30:00",
  },
  {
    employeeId: "E002",
    name: "Alice Smith",
    factoryCode: "F456",
    totalEntries: 78,
    lastSessionTime: "2023-09-25 10:15:00",
  },
  {
    employeeId: "E003",
    name: "Bob Johnson",
    factoryCode: "F789",
    totalEntries: 65,
    lastSessionTime: "2023-09-25 16:45:00",
  },
  {
    employeeId: "E004",
    name: "Sarah Davis",
    factoryCode: "F234",
    totalEntries: 95,
    lastSessionTime: "2023-09-25 12:20:00",
  },
  {
    employeeId: "E005",
    name: "Michael Wilson",
    factoryCode: "F567",
    totalEntries: 53,
    lastSessionTime: "2023-09-25 09:30:00",
  },
  {
    employeeId: "E006",
    name: "Emily Brown",
    factoryCode: "F890",
    totalEntries: 71,
    lastSessionTime: "2023-09-25 15:10:00",
  },
  {
    employeeId: "E007",
    name: "David Lee",
    factoryCode: "F345",
    totalEntries: 88,
    lastSessionTime: "2023-09-25 11:55:00",
  },
  {
    employeeId: "E008",
    name: "Olivia Jones",
    factoryCode: "F678",
    totalEntries: 37,
    lastSessionTime: "2023-09-25 13:40:00",
  },
  {
    employeeId: "E009",
    name: "William Miller",
    factoryCode: "F123",
    totalEntries: 59,
    lastSessionTime: "2023-09-25 17:20:00",
  },
  {
    employeeId: "E010",
    name: "Ava Wilson",
    factoryCode: "F890",
    totalEntries: 44,
    lastSessionTime: "2023-09-25 08:45:00",
  },
  {
    employeeId: "E010",
    name: "Ava Wilson",
    factoryCode: "F890",
    totalEntries: 44,
    lastSessionTime: "2023-09-25 08:45:00",
  },
  {
    employeeId: "E009",
    name: "William Miller",
    factoryCode: "F123",
    totalEntries: 59,
    lastSessionTime: "2023-09-25 17:20:00",
  },
  {
    employeeId: "E010",
    name: "Ava Wilson",
    factoryCode: "F890",
    totalEntries: 44,
    lastSessionTime: "2023-09-25 08:45:00",
  },
  {
    employeeId: "E009",
    name: "William Miller",
    factoryCode: "F123",
    totalEntries: 59,
    lastSessionTime: "2023-09-25 17:20:00",
  },
  {
    employeeId: "E010",
    name: "Ava Wilson",
    factoryCode: "F890",
    totalEntries: 44,
    lastSessionTime: "2023-09-25 08:45:00",
  },
  {
    employeeId: "E009",
    name: "William Miller",
    factoryCode: "F123",
    totalEntries: 59,
    lastSessionTime: "2023-09-25 17:20:00",
  },
  {
    employeeId: "E010",
    name: "Ava Wilson",
    factoryCode: "F890",
    totalEntries: 44,
    lastSessionTime: "2023-09-25 08:45:00",
  },
  {
    employeeId: "E009",
    name: "William Miller",
    factoryCode: "F123",
    totalEntries: 59,
    lastSessionTime: "2023-09-25 17:20:00",
  },
  {
    employeeId: "E010",
    name: "Ava Wilson",
    factoryCode: "F890",
    totalEntries: 44,
    lastSessionTime: "2023-09-25 08:45:00",
  },
  {
    employeeId: "E009",
    name: "William Miller",
    factoryCode: "F123",
    totalEntries: 59,
    lastSessionTime: "2023-09-25 17:20:00",
  },
  {
    employeeId: "E010",
    name: "Ava Wilson",
    factoryCode: "F890",
    totalEntries: 44,
    lastSessionTime: "2023-09-25 08:45:00",
  },
];

const UnsuccessfulEntries = [
  {
    employeeId: "E010",
    name: "Ava Wilson",
    factoryCode: "F890",
    totalEntries: 44,
    lastSessionTime: "2023-09-25 08:45:00",
  },
  {
    employeeId: "E009",
    name: "William Miller",
    factoryCode: "F123",
    totalEntries: 59,
    lastSessionTime: "2023-09-25 17:20:00",
  },
  {
    employeeId: "E010",
    name: "Ava Wilson",
    factoryCode: "F890",
    totalEntries: 44,
    lastSessionTime: "2023-09-25 08:45:00",
  },
];

//   {
//     emp_id: "E009",
//     name: "William Miller",
//     factory_code: "F123",
//     total_entries: 59,
//     last_session_time: "2023-09-25 17:20:00",
//   },
//   {
//     emp_id: "E010",
//     name: "Ava Wilson",
//     factory_code: "F890",
//     total_entries: 44,
//     last_session_time: "2023-09-25 08:45:00",
//   },
//   {
//     emp_id: "E009",
//     name: "William Miller",
//     factory_code: "F123",
//     total_entries: 59,
//     last_session_time: "2023-09-25 17:20:00",
//   },
// ];
const notLogInUsers = [
  {
    employeeId: "E009",
    name: "William Miller",
    factoryCode: "F123",
    totalEntries: 59,
    lastSessionTime: "2023-09-25 17:20:00",
  },
  {
    employeeId: "E010",
    name: "Ava Wilson",
    factoryCode: "F890",
    totalEntries: 44,
    lastSessionTime: "2023-09-25 08:45:00",
  },
  {
    employeeId: "E009",
    name: "William Miller",
    factoryCode: "F123",
    totalEntries: 59,
    lastSessionTime: "2023-09-25 17:20:00",
  },
];


const totalTranscation = [
  {
    emp_id: "E010",
    name: "Ava Wilson",
    factory_code: "F890",
    total_entries: 44,
    last_session_time: "2023-09-25 08:45:00",
  },
  {
    emp_id: "E009",
    name: "William Miller",
    factory_code: "F123",
    total_entries: 59,
    last_session_time: "2023-09-25 17:20:00",
  },
  {
    emp_id: "E010",
    name: "Ava Wilson",
    factory_code: "F890",
    total_entries: 44,
    last_session_time: "2023-09-25 08:45:00",
  },
  {
    emp_id: "E009",
    name: "William Miller",
    factory_code: "F123",
    total_entries: 59,
    last_session_time: "2023-09-25 17:20:00",
  },
  {
    emp_id: "E010",
    name: "Ava Wilson",
    factory_code: "F890",
    total_entries: 44,
    last_session_time: "2023-09-25 08:45:00",
  },
  {
    emp_id: "E009",
    name: "William Miller",
    factory_code: "F123",
    total_entries: 59,
    last_session_time: "2023-09-25 17:20:00",
  },
  {
    emp_id: "E010",
    name: "Ava Wilson",
    factory_code: "F890",
    total_entries: 44,
    last_session_time: "2023-09-25 08:45:00",
  },
  {
    emp_id: "E009",
    name: "William Miller",
    factory_code: "F123",
    total_entries: 59,
    last_session_time: "2023-09-25 17:20:00",
  },
  {
    emp_id: "E010",
    name: "Ava Wilson",
    factory_code: "F890",
    total_entries: 44,
    last_session_time: "2023-09-25 08:45:00",
  },
  {
    emp_id: "E009",
    name: "William Miller",
    factory_code: "F123",
    total_entries: 59,
    last_session_time: "2023-09-25 17:20:00",
  },
];

const totalNoTranscation = [
  {
    emp_id: "E009",
    name: "William Miller",
    factory_code: "F123",
    total_entries: 59,
    last_session_time: "2023-09-25 17:20:00",
  },
  {
    emp_id: "E010",
    name: "Ava Wilson",
    factory_code: "F890",
    total_entries: 44,
    last_session_time: "2023-09-25 08:45:00",
  },
  {
    emp_id: "E009",
    name: "William Miller",
    factory_code: "F123",
    total_entries: 59,
    last_session_time: "2023-09-25 17:20:00",
  },
  {
    emp_id: "E010",
    name: "Ava Wilson",
    factory_code: "F890",
    total_entries: 44,
    last_session_time: "2023-09-25 08:45:00",
  },
  {
    emp_id: "E009",
    name: "William Miller",
    factory_code: "F123",
    total_entries: 59,
    last_session_time: "2023-09-25 17:20:00",
  },
];

const noEntries = [
  {
    emp_id: "E010",
    name: "Ava Wilson",
    factory_code: "F890",
    total_entries: 44,
    last_session_time: "2023-09-25 08:45:00",
  },
  {
    emp_id: "E009",
    name: "William Miller",
    factory_code: "F123",
    total_entries: 59,
    last_session_time: "2023-09-25 17:20:00",
  },
];

const userData = [
  {
    emp_id: 101,
    shahi_attendance: "present",
    successful_logins: 35,
    login_failures: 2,
    total_entries: 125,
    inflows: 75,
    outflows: 100,
    no_transactions: 10,
    no_entry_added: 5,
    last_entry: "2023-10-01 09:30",
    isOverflow: true,
  },
  {
    emp_id: 102,
    shahi_attendance: "Present",
    successful_logins: 28,
    login_failures: 3,
    total_entries: 100,
    inflows: 50,
    outflows: 100,
    no_transactions: 12,
    no_entry_added: 7,
    last_entry: "2023-09-30 04:15",
    isOverflow: true,
  },
  {
    emp_id: 103,
    shahi_attendance: "Present",
    successful_logins: 42,
    login_failures: 1,
    total_entries: 150,
    inflows: 80,
    outflows: 70,
    no_transactions: 8,
    no_entry_added: 3,
    last_entry: "2023-10-01 10:45",
    isOverflow: false,
  },
  {
    emp_id: 104,
    shahi_attendance: "Present",
    successful_logins: 30,
    login_failures: 0,
    total_entries: 120,
    inflows: 70,
    outflows: 50,
    no_transactions: 15,
    no_entry_added: 6,
    last_entry: "2023-10-01 11:20",
    isOverflow: false,
  },
  {
    emp_id: 105,
    shahi_attendance: "Absent",
    successful_logins: 25,
    login_failures: 5,
    total_entries: 95,
    inflows: 60,
    outflows: 35,
    no_transactions: 20,
    no_entry_added: 10,
    last_entry: "2023-09-29 02:00",
    isOverflow: false,
  },
  {
    emp_id: 101,
    shahi_attendance: "Present",
    successful_logins: 35,
    login_failures: 2,
    total_entries: 125,
    inflows: 75,
    outflows: 50,
    no_transactions: 10,
    no_entry_added: 5,
    last_entry: "2023-10-01 09:30",
    isOverflow: false,
  },
  {
    emp_id: 102,
    shahi_attendance: "Absent",
    successful_logins: 28,
    login_failures: 3,
    total_entries: 100,
    inflows: 50,
    outflows: 50,
    no_transactions: 12,
    no_entry_added: 7,
    last_entry: "2023-09-30 04:15",
    isOverflow: false,
  },
  {
    emp_id: 103,
    shahi_attendance: "Present",
    successful_logins: 42,
    login_failures: 1,
    total_entries: 150,
    inflows: 80,
    outflows: 70,
    no_transactions: 8,
    no_entry_added: 3,
    last_entry: "2023-10-01 10:45",
    isOverflow: false,
  },
  {
    emp_id: 104,
    shahi_attendance: "Present",
    successful_logins: 30,
    login_failures: 0,
    total_entries: 120,
    inflows: 70,
    outflows: 50,
    no_transactions: 15,
    no_entry_added: 6,
    last_entry: "2023-10-01 11:20",
    isOverflow: false,
  },
  {
    emp_id: 105,
    shahi_attendance: "Absent",
    successful_logins: 25,
    login_failures: 5,
    total_entries: 95,
    inflows: 60,
    outflows: 35,
    no_transactions: 20,
    no_entry_added: 10,
    last_entry: "2023-09-29 02:00",
    isOverflow: false,
  },

  {
    emp_id: 101,
    shahi_attendance: "Present",
    successful_logins: 35,
    login_failures: 2,
    total_entries: 125,
    inflows: 75,
    outflows: 50,
    no_transactions: 10,
    no_entry_added: 5,
    last_entry: "2023-10-01 09:30",
    isOverflow: false,
  },
  {
    emp_id: 102,
    shahi_attendance: "Absent",
    successful_logins: 28,
    login_failures: 3,
    total_entries: 100,
    inflows: 50,
    outflows: 50,
    no_transactions: 12,
    no_entry_added: 7,
    last_entry: "2023-09-30 04:15",
    isOverflow: false,
  },
  {
    emp_id: 103,
    shahi_attendance: "Present",
    successful_logins: 42,
    login_failures: 1,
    total_entries: 150,
    inflows: 80,
    outflows: 70,
    no_transactions: 8,
    no_entry_added: 3,
    last_entry: "2023-10-01 10:45",
    isOverflow: false,
  },
  {
    emp_id: 104,
    shahi_attendance: "Present",
    successful_logins: 30,
    login_failures: 0,
    total_entries: 120,
    inflows: 70,
    outflows: 50,
    no_transactions: 15,
    no_entry_added: 6,
    last_entry: "2023-10-01 11:20",
    isOverflow: false,
  },
  {
    emp_id: 105,
    shahi_attendance: "Absent",
    successful_logins: 25,
    login_failures: 5,
    total_entries: 95,
    inflows: 60,
    outflows: 35,
    no_transactions: 20,
    no_entry_added: 10,
    last_entry: "2023-09-29 02:00",
    isOverflow: false,
  },
];

const transactionDetailsData = [
  {
    transactionId: "TRN12345",
    entryTimestamp: "2023-10-01 09:30",
    employeeId: "EMP101",
    transactionDate: "2023-09-30",
    subCategory: "Expense",
    source: "Online Store",
    modeOfPayment: "Credit Card",
    amount: 250.5,
    entryType: "inflow",
  },
  {
    transactionId: "TRN12346",
    entryTimestamp: "2023-10-01 10:15",
    employeeId: "EMP102",
    transactionDate: "2023-10-01",
    subCategory: "Income",
    source: "Client Payment",
    modeOfPayment: "Bank Transfer",
    amount: 500.75,
    entryType: "inflow",
    isEdited: true,
    isDubbleStar: true,
  },
  {
    transactionId: "TRN12347",
    entryTimestamp: "2023-10-01 11:00",
    employeeId: "EMP103",
    transactionDate: "2023-10-01",
    subCategory: "Expense",
    source: "Office Supplies",
    modeOfPayment: "Cash",
    amount: 75.0,
    entryType: "outflow",
  },
  {
    transactionId: "TRN12345",
    entryTimestamp: "2023-10-01 09:30",
    employeeId: "EMP101",
    transactionDate: "2023-09-30",
    subCategory: "Expense",
    source: "Online Store",
    modeOfPayment: "Credit Card",
    amount: 250.5,
    entryType: "outflow",
    isEdited: true,
    isDubbleStar: true,
  },
  {
    transactionId: "TRN12346",
    entryTimestamp: "2023-10-01 10:15",
    employeeId: "EMP102",
    transactionDate: "2023-10-01",
    subCategory: "Income",
    source: "Client Payment",
    modeOfPayment: "Bank Transfer",
    amount: 500.75,
    entryType: "inflow",
  },
  {
    transactionId: "TRN12347",
    entryTimestamp: "2023-10-01 11:00",
    employeeId: "EMP103",
    transactionDate: "2023-10-01",
    subCategory: "Expense",
    source: "Office Supplies",
    modeOfPayment: "Cash",
    amount: 75.0,
    entryType: "noTransaction",
    isDubbleStar: true,
  },
  {
    transactionId: "TRN12345",
    entryTimestamp: "2023-10-01 09:30",
    employeeId: "EMP101",
    transactionDate: "2023-09-30",
    subCategory: "Expense",
    source: "Online Store",
    modeOfPayment: "Credit Card",
    amount: 250.5,
    entryType: "noTransaction",
  },
  {
    transactionId: "TRN12346",
    entryTimestamp: "2023-10-01 10:15",
    employeeId: "EMP102",
    transactionDate: "2023-10-01",
    subCategory: "Income",
    source: "Client Payment",
    modeOfPayment: "Bank Transfer",
    amount: 500.75,
    entryType: "inflow",
  },
  {
    transactionId: "TRN12347",
    entryTimestamp: "2023-10-01 11:00",
    employeeId: "EMP103",
    transactionDate: "2023-10-01",
    subCategory: "Expense",
    source: "Office Supplies",
    modeOfPayment: "Cash",
    amount: 75.0,
    entryType: "edited",
  },
];

const currentUsers = [
  {
    empId: 1,
    name: "John Doe",
    factory: "F001",
    enumeratorCode: "EN-123",
    mobile: "555-555-5555",
    username: "johndoe",
    password: "********",
  },
  {
    empId: 2,
    name: "Jane Smith",
    factory: "F002",
    enumeratorCode: "EN-456",
    mobile: "555-555-5556",
    username: "janesmith",
    password: "********",
  },
  {
    empId: 3,
    name: "John Doe",
    factory: "F001",
    enumeratorCode: "EN-123",
    mobile: "555-555-5555",
    username: "johndoe",
    password: "********",
  },
  {
    empId: 4,
    name: "Jane Smith",
    factory: "F002",
    enumeratorCode: "EN-456",
    mobile: "555-555-5556",
    username: "janesmith",
    password: "********",
  },
  {
    empId: 5,
    name: "John Doe",
    factory: "F001",
    enumeratorCode: "EN-123",
    mobile: "555-555-5555",
    username: "johndoe",
    password: "********",
  },
  {
    empId: 6,
    name: "Jane Smith",
    factory: "F002",
    enumeratorCode: "EN-456",
    mobile: "555-555-5556",
    username: "janesmith",
    password: "********",
  },
  {
    empId: 7,
    name: "John Doe",
    factory: "F001",
    enumeratorCode: "EN-123",
    mobile: "555-555-5555",
    username: "johndoe",
    password: "********",
  },
  {
    empId: 8,
    name: "Jane Smith",
    factory: "F002",
    enumeratorCode: "EN-456",
    mobile: "555-555-5556",
    username: "janesmith",
    password: "********",
  },
  {
    empId: 6,
    name: "Jane Smith",
    factory: "F002",
    enumeratorCode: "EN-456",
    mobile: "555-555-5556",
    username: "janesmith",
    password: "********",
  },
  {
    empId: 7,
    name: "John Doe",
    factory: "F001",
    enumeratorCode: "EN-123",
    mobile: "555-555-5555",
    username: "johndoe",
    password: "********",
  },
  {
    empId: 8,
    name: "Jane Smith",
    factory: "F002",
    enumeratorCode: "EN-456",
    mobile: "555-555-5556",
    username: "janesmith",
    password: "********",
  },
  {
    empId: 6,
    name: "Jane Smith",
    factory: "F002",
    enumeratorCode: "EN-456",
    mobile: "555-555-5556",
    username: "janesmith",
    password: "********",
  },
  {
    empId: 7,
    name: "John Doe",
    factory: "F001",
    enumeratorCode: "EN-123",
    mobile: "555-555-5555",
    username: "johndoe",
    password: "********",
  },
  {
    empId: 8,
    name: "Jane Smith",
    factory: "F002",
    enumeratorCode: "EN-456",
    mobile: "555-555-5556",
    username: "janesmith",
    password: "********",
  },
];

const studyUsers = [
  {
    empId: 2,
    name: "Jane Smith",
    factory: "F002",
    enumeratorCode: "EN-456",
    mobile: "555-555-5556",
    username: "janesmith",
    password: "********",
  },
  {
    empId: 3,
    name: "John Doe",
    factory: "F001",
    enumeratorCode: "EN-123",
    mobile: "555-555-5555",
    username: "johndoe",
    password: "********",
  },
  {
    empId: 4,
    name: "Jane Smith",
    factory: "F002",
    enumeratorCode: "EN-456",
    mobile: "555-555-5556",
    username: "janesmith",
    password: "********",
  },
  {
    empId: 5,
    name: "John Doe",
    factory: "F001",
    enumeratorCode: "EN-123",
    mobile: "555-555-5555",
    username: "johndoe",
    password: "********",
  },
  {
    empId: 6,
    name: "Jane Smith",
    factory: "F002",
    enumeratorCode: "EN-456",
    mobile: "555-555-5556",
    username: "janesmith",
    password: "********",
  },
  {
    empId: 7,
    name: "John Doe",
    factory: "F001",
    enumeratorCode: "EN-123",
    mobile: "555-555-5555",
    username: "johndoe",
    password: "********",
  },
  {
    empId: 8,
    name: "Jane Smith",
    factory: "F002",
    enumeratorCode: "EN-456",
    mobile: "555-555-5556",
    username: "janesmith",
    password: "********",
  },
];

const allUsers = [
  {
    empId: 1,
    name: "John Doe",
    factory: "F001",
    enumeratorCode: "EN-123",
    mobile: "555-555-5555",
    username: "johndoe",
    password: "********",
  },
  {
    empId: 2,
    name: "Jane Smith",
    factory: "F002",
    enumeratorCode: "EN-456",
    mobile: "555-555-5556",
    username: "janesmith",
    password: "********",
  },
  {
    empId: 3,
    name: "John Doe",
    factory: "F001",
    enumeratorCode: "EN-123",
    mobile: "555-555-5555",
    username: "johndoe",
    password: "********",
  },
  {
    empId: 4,
    name: "Jane Smith",
    factory: "F002",
    enumeratorCode: "EN-456",
    mobile: "555-555-5556",
    username: "janesmith",
    password: "********",
  },
  {
    empId: 5,
    name: "John Doe",
    factory: "F001",
    enumeratorCode: "EN-123",
    mobile: "555-555-5555",
    username: "johndoe",
    password: "********",
  },
  {
    empId: 6,
    name: "Jane Smith",
    factory: "F002",
    enumeratorCode: "EN-456",
    mobile: "555-555-5556",
    username: "janesmith",
    password: "********",
  },
  {
    empId: 7,
    name: "John Doe",
    factory: "F001",
    enumeratorCode: "EN-123",
    mobile: "555-555-5555",
    username: "johndoe",
    password: "********",
  },
  {
    empId: 8,
    name: "Jane Smith",
    factory: "F002",
    enumeratorCode: "EN-456",
    mobile: "555-555-5556",
    username: "janesmith",
    password: "********",
  },
  {
    empId: 6,
    name: "Jane Smith",
    factory: "F002",
    enumeratorCode: "EN-456",
    mobile: "555-555-5556",
    username: "janesmith",
    password: "********",
  },
  {
    empId: 7,
    name: "John Doe",
    factory: "F001",
    enumeratorCode: "EN-123",
    mobile: "555-555-5555",
    username: "johndoe",
    password: "********",
  },
  {
    empId: 1,
    name: "John Doe",
    factory: "F001",
    enumeratorCode: "EN-123",
    mobile: "555-555-5555",
    username: "johndoe",
    password: "********",
  },
  {
    empId: 2,
    name: "Jane Smith",
    factory: "F002",
    enumeratorCode: "EN-456",
    mobile: "555-555-5556",
    username: "janesmith",
    password: "********",
  },
  {
    empId: 3,
    name: "John Doe",
    factory: "F001",
    enumeratorCode: "EN-123",
    mobile: "555-555-5555",
    username: "johndoe",
    password: "********",
  },
  {
    empId: 4,
    name: "Jane Smith",
    factory: "F002",
    enumeratorCode: "EN-456",
    mobile: "555-555-5556",
    username: "janesmith",
    password: "********",
  },
  {
    empId: 5,
    name: "John Doe",
    factory: "F001",
    enumeratorCode: "EN-123",
    mobile: "555-555-5555",
    username: "johndoe",
    password: "********",
  },
  {
    empId: 6,
    name: "Jane Smith",
    factory: "F002",
    enumeratorCode: "EN-456",
    mobile: "555-555-5556",
    username: "janesmith",
    password: "********",
  },
  {
    empId: 7,
    name: "John Doe",
    factory: "F001",
    enumeratorCode: "EN-123",
    mobile: "555-555-5555",
    username: "johndoe",
    password: "********",
  },
  {
    empId: 8,
    name: "Jane Smith",
    factory: "F002",
    enumeratorCode: "EN-456",
    mobile: "555-555-5556",
    username: "janesmith",
    password: "********",
  },
  {
    empId: 6,
    name: "Jane Smith",
    factory: "F002",
    enumeratorCode: "EN-456",
    mobile: "555-555-5556",
    username: "janesmith",
    password: "********",
  },
  {
    empId: 7,
    name: "John Doe",
    factory: "F001",
    enumeratorCode: "EN-123",
    mobile: "555-555-5555",
    username: "johndoe",
    password: "********",
  },
];

const activeUsersDetail = [
  { id: 1, label: "Successful Logins", value: 15 },
  { id: 2, label: "Unsuccessful Entries", value: Number("03") },
  { id: 3, label: "Did Not Login", value: 4 },
];

const successfulLoginDetails = [
  { id: 1, label: "Transactions", value: 10 },
  { id: 2, label: "No Transaction", value: Number("5") },
  { id: 3, label: "No Entry Added", value: 2 },
];

const enumratorsDetails = [
  {
    enumId: 1,
    name: "John Doe",
    factory: "ABC",
    workerEmpCodes: "W123",
    mobile: "123-456-7890",
    username: "johndoe",
    password: "********",
  },
  {
    enumId: 2,
    name: "Alice Smith",
    factory: "DEF",
    workerEmpCodes: "W456",
    mobile: "987-654-3210",
    username: "alicesmith",
    password: "********",
  },
  {
    enumId: 1,
    name: "John Doe",
    factory: "ABC",
    workerEmpCodes: "W123",
    mobile: "123-456-7890",
    username: "johndoe",
    password: "********",
  },
  {
    enumId: 2,
    name: "Alice Smith",
    factory: "DEF",
    workerEmpCodes: "W456",
    mobile: "987-654-3210",
    username: "alicesmith",
    password: "********",
  },
  {
    enumId: 1,
    name: "John Doe",
    factory: "ABC",
    workerEmpCodes: "W123",
    mobile: "123-456-7890",
    username: "johndoe",
    password: "********",
  },
  {
    enumId: 2,
    name: "Alice Smith",
    factory: "DEF",
    workerEmpCodes: "W456",
    mobile: "987-654-3210",
    username: "alicesmith",
    password: "********",
  },
  {
    enumId: 1,
    name: "John Doe",
    factory: "ABC",
    workerEmpCodes: "W123",
    mobile: "123-456-7890",
    username: "johndoe",
    password: "********",
  },
  {
    enumId: 2,
    name: "Alice Smith",
    factory: "DEF",
    workerEmpCodes: "W456",
    mobile: "987-654-3210",
    username: "alicesmith",
    password: "********",
  },
  {
    enumId: 1,
    name: "John Doe",
    factory: "ABC",
    workerEmpCodes: "W123",
    mobile: "123-456-7890",
    username: "johndoe",
    password: "********",
  },
  {
    enumId: 2,
    name: "Alice Smith",
    factory: "DEF",
    workerEmpCodes: "W456",
    mobile: "987-654-3210",
    username: "alicesmith",
    password: "********",
  },
  {
    enumId: 1,
    name: "John Doe",
    factory: "ABC",
    workerEmpCodes: "W123",
    mobile: "123-456-7890",
    username: "johndoe",
    password: "********",
  },
  {
    enumId: 2,
    name: "Alice Smith",
    factory: "DEF",
    workerEmpCodes: "W456",
    mobile: "987-654-3210",
    username: "alicesmith",
    password: "********",
  },
  {
    enumId: 1,
    name: "John Doe",
    factory: "ABC",
    workerEmpCodes: "W123",
    mobile: "123-456-7890",
    username: "johndoe",
    password: "********",
  },
  {
    enumId: 2,
    name: "Alice Smith",
    factory: "DEF",
    workerEmpCodes: "W456",
    mobile: "987-654-3210",
    username: "alicesmith",
    password: "********",
  },
];

const userTransactionDetails = [
  {
    id: 1,
    label: "Name",
    value: "Kavitha Mehta",
  },
  {
    id: 2,
    label: "Designation",
    value: "Tailor",
  },
  {
    id: 3,
    label: "Department",
    value: "Sewing",
  },
  {
    id: 4,
    label: "Cash Inflows",
    value: "Rs 12000",
  },
  {
    id: 5,
    label: "Cash Outflows:",
    value: "Rs 10000",
  },
  {
    id: 6,
    label: "Income",
    value: "Rs 9000",
  },
  {
    id: 7,
    label: "Expenses",
    value: "Rs 5000",
  },
  {
    id: 8,
    label: "Loans",
    value: "Rs 3000",
  },
  {
    id: 9,
    label: "Insurance",
    value: "Rs 1000",
  },
  {
    id: 10,
    label: "Savings",
    value: "Rs 2000",
  },
  {
    id: 11,
    label: "Investments",
    value: "Rs 0",
  },
];

const waves = [
  { id: 1, label: "Wave 1", value: "wave1", isActive: true },
  { id: 2, label: "Wave 2", value: "wave2", isActive: true },
  { id: 3, label: "Wave 3", value: "wave3", isActive: true },
  { id: 4, label: "Wave 4", value: "wave4", isActive: false },
];

const enumeratorList = [
  { id: 1, label: "Enumerator 1", value: "enum1" },
  { id: 2, label: "Enumerator 2", value: "enum2" },
  { id: 3, label: "Enumerator 3", value: "enum3" },
  { id: 4, label: "Enumerator 4", value: "enum4" },
  { id: 5, label: "Enumerator 5", value: "enum5" },
];

export default {
  successfullLogin: successfullLogin,
  UnsuccessfulEntries: UnsuccessfulEntries,
  notLogInUsers: notLogInUsers,
  totalTranscation: totalTranscation,
  totalNoTranscation: totalNoTranscation,
  noEntries: noEntries,
  userData: userData,
  transactionDetailsData: transactionDetailsData,
  currentUsers: currentUsers,
  studyUsers: studyUsers,
  allUsers: allUsers,
  activeUsersDetail: activeUsersDetail,
  successfulLoginDetails: successfulLoginDetails,
  enumratorsDetails: enumratorsDetails,
  userTransactionDetails: userTransactionDetails,
  waves: waves,
  enumeratorList: enumeratorList,
};
